import { cloneDeep } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import PickerList from "shared/components/controls/picker/list/PickerList";
import Modal from "shared/components/layout/modal/Modal";
import { IPickerItem } from "shared/types/pickerTypes";
import { convertToPickerItem } from "shared/utilities/pickerUtiilities";
import { saveTemplate, toggleTemplateMandatorySelection } from "store/audit-templates/AuditTemplatesSlice";
import { useAppSelector } from "store/store";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

const AuditTemplateChildSelectionModal: React.FC = () => {
  const template = useAppSelector((store) => store.auditTemplates.template);
  const templateName = useAppSelector((store) => store.auditTemplates.originalTemplate?.name);
  const templates = useAppSelector((store) => store.auditTemplates.selectedTemplates);
  const topics = useAppSelector((store) => store.auditTemplates.selectedTopics);

  const onClose = () => dispatch(toggleTemplateMandatorySelection({ toogleOp: false }));

  const dispatch = useDispatch();

  let header: string;

  if (template?.id) {
    header = `${templateName ?? 'Edit Template'}`;
  } else {
    header = `New Template - ${template?.name}`;
  }

  let modalButtons = [
    {
      key: "Cancel",
      text: "Cancel",
      className: "secondary",
      onClick: onClose,
    },
    {
      key: "Save",
      text: "Save",
      className: "primary",
      onClick: () => {
        saveRequiredTemplates();
      },
      disabled: false,
    }
  ];

  let allTemplates: (string | number)[] = [];
  templates.forEach(x => {
    addRecursively(x, allTemplates);
  });

  const saveRequiredTemplates = () => {
    let mandatoryTemplates = cloneDeep(templates);

    localSelectedItems.forEach(x => {
      setRequired(x.key, mandatoryTemplates);
    });

    dispatch(saveTemplate({
      template,
      selectedTemplates: mandatoryTemplates,
      selectedTopics: topics
    }));
  };

  let requiredTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[] = [];
  getRequired(templates, requiredTemplates);

  const [localSelectedItems, setLocalSelectedItems] = useState<(IDetailedTemplate | IDetailedTemplateChildren)[]>(requiredTemplates);

  const toPickerItem =
    (item: IDetailedTemplate | IDetailedTemplateChildren, includeChildren?: boolean, allItems?: (IDetailedTemplate | IDetailedTemplateChildren)[])
      : IPickerItem<IDetailedTemplate | IDetailedTemplateChildren> => {
      return convertToPickerItem(item, allTemplates, item => item.id + item.name, (item) => item.children || [], includeChildren, allItems);
    };

  return (
    <Modal
      isOpen={true}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      header={header}
      buttons={modalButtons}
    >
      <div>
        Please make a selection on the Templates that should  be <strong>MANDATORY:</strong>

        <br></br>
        <br></br>

        <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
          items={templates.map(x => toPickerItem(x, true, templates))}
          renderItem={(item) => item.name}
          allowMultiSelect={true}
          noItemsMessage={"No suggestions available."}
          selectedItems={localSelectedItems.map(x => toPickerItem(x))}
          displayMode={"tree"}
          notShowPlusMinusSign={true}
          onItemSelected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
            if (item.item) {
              setLocalSelectedItems(localSelectedItems.concat(item.item));
            }
          }}
          onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
            if (item.item) {
              setLocalSelectedItems(localSelectedItems.filter(x => x.id !== item.item?.id));
            }
          }}
          onItemExpanded={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {

          }}
          onItemCollapsed={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {

          }}
          isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
            const fathersId = templates.map(x => x.id);
            const child = (item as IDetailedTemplateChildren);
            if ((child !== undefined && child.masterDataType === MetaDataTypes.AuditTopic) || !fathersId.includes(child.id)) {
              return true;
            }

            return false;
          }}
        />
      </div>
    </Modal>
  );
}

export default AuditTemplateChildSelectionModal;

function addRecursively(item: IDetailedTemplate | IDetailedTemplateChildren, allItems: (string | number)[]) {
  allItems.push(item.id + item.name);

  item.children.forEach(c => {
    addRecursively(c, allItems);
  })
}

function setRequired(key: string | number, templates: (IDetailedTemplate | IDetailedTemplateChildren)[]) {
  templates.forEach(x => setRecursively(key, x));
}

function setRecursively(key: string | number, template: IDetailedTemplate | IDetailedTemplateChildren) {
  if (template.key === key) {
    template.isRequired = true;
  } else {
    template.children.forEach(x => setRecursively(key, x));
  }
}

function getRequired(templates: (IDetailedTemplate | IDetailedTemplateChildren)[], requiredItems: (IDetailedTemplate | IDetailedTemplateChildren)[]): (IDetailedTemplate | IDetailedTemplateChildren)[] {
  templates.forEach(x => {
    const child = (x as IDetailedTemplateChildren);
    if (x.isRequired || (child !== undefined && child.masterDataType === MetaDataTypes.AuditTopic)) {
      requiredItems?.push(x);
    }
    getRequired(x.children, requiredItems);
  });

  return requiredItems;
}
