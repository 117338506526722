import { IAzureADUser } from "shared/types/userProfileTypes";
import { IIdNameObject } from "./commonTypes";

export interface IActionItem {
  id: number,
  status: ActionItemStatuses,
  causalFactor?: IIdNameObject,
  type?: IActionItemType,
  priority?: IActionItemPriority,
  assignedTo?: IAzureADUser,
  dueByTimestamp: number,
  actionRequired: string,
  description: string,
  links: IActionItemLink[],
  editors: IActionItemEditor[],
  createdBy: IAzureADUser,
  modifiedBy: IAzureADUser,
  createdOnTimestamp: number,
  modifiedOnTimestamp: number,
  closureTimestamp?: number,
  history: IActionItemHistoryItem[],
  evidences: IActionItemEvidence[],
  isValidated: boolean,
  isLocked: boolean,
  defaultSendNotification?: boolean
}

export interface IActionItemHistoryItem {
  userInfo: IAzureADUser,
  text: string,
  timestamp: number,
  event?: ActionItemHistoryEvents | string,
}

export enum ActionItemHistoryEvents {
  Created = "Created",
  Comment = "Comment",
  Closed = "Closed",
  Reopened = "Reopened",
  Unlinked = "Unlinked",
  Deleted = "Deleted",
  Validated = "Validated",
}

export interface IActionItemLink {
  display?: string,
  url?: string,
  type: string | ActionItemLinkTypes,
  linkId: number | string,
}

export interface IActionItemEditor {
  name: string,
  email: string,
}

export enum ActionItemLinkTypes {
  Audit = "Audit",
  AuditQuestion = "AuditQuestion",
  AuditFinding = "AuditFinding",
}

export interface IActionItemType {
  id: number,
  name: string,
  allowCausalFactor: boolean,
  isDeleted: boolean,
}

export interface IActionItemPriority {
  id: number,
  name: string,
  sortOrder: number,
  addsDeadlineDays: number,
  isDeleted: boolean,
}

export enum ActionItemStatuses {
  Open = "Open",
  Closed = "Closed",
}

export interface IActionItemEvidence {
  id: number,
  actionItemId: number,
  filename?: string,
  notes: string,
  link?: string,
  type: "Evidence" | "Link",
}

export interface IActionItemClosure {
  actionFollowUp: string,
  closureTimestamp: number,
}

export interface IActionItemUnlinkedEvent {
  type: "Unlinked",
  unlinkedFrom: IActionItemLink[],
  newHistoryItem: IActionItemHistoryItem,
}

export interface IActionItemDeletedEvent {
  type: "Deleted",
}

export interface IActionItemUpdatedEvent {
  type: "Updated",
}

export interface IActionItemCreatedEvent {
  type: "Created",
}

export interface IActionItemCommentAddedEvent {
  type: "CommentAdded",
  newHistoryItem: IActionItemHistoryItem,
}

export interface IActionItemClosedEvent {
  type: "Closed",
  newHistoryItem: IActionItemHistoryItem,
}

export interface IActionItemReopenedEvent {
  type: "Reopened",
  newHistoryItem: IActionItemHistoryItem,
}

export interface IActionItemValidatedEvent {
  type: "Validated",
  newHistoryItem: IActionItemHistoryItem,
}

export interface IActionItemEvidenceAddedEvent {
  type: "EvidenceAdded",
  evidence: IActionItemEvidence,
}

export interface IActionItemEvidenceDeletedEvent {
  type: "EvidenceDeleted",
  evidence: IActionItemEvidence,
}

export interface IActionItemEvidenceUpdatedEvent {
  type: "EvidenceUpdated",
  evidence: IActionItemEvidence,
}

export type ActionItemChangeEvent = {
  actionItem: IActionItem,
} & (IActionItemDeletedEvent
  | IActionItemUnlinkedEvent
  | IActionItemUpdatedEvent
  | IActionItemCreatedEvent
  | IActionItemCommentAddedEvent
  | IActionItemClosedEvent
  | IActionItemReopenedEvent
  | IActionItemValidatedEvent
  | IActionItemEvidenceAddedEvent
  | IActionItemEvidenceDeletedEvent
  | IActionItemEvidenceUpdatedEvent);

export enum ActionItemRelations {
  All = "All",
  AssignedToMe = "AssignedToMe",
  CreatedByMe = "CreatedByMe",
  RelatedToMyAudits = "RelatedToMyAudits",
}

export enum ActionItemStatusFilters {
  Open = "Open",
  OpenAndClosed = "OpenAndClosed",
}

export interface IMyAction {
  parentId?: number | string,
  parentType?: string,
  parentName?: string,
  id: number,
  type: string,
  actionRequired: string,
  assignedToName: string,
  createdByName: string,
  priority: string,
  status: ActionItemStatuses,
  isValidated: boolean,
  dueByTimestamp: number,
  closureTimestamp?: number,
  createdOnTimestamp: number,
  defaultSendNotification: boolean
}