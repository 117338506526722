import { formatIdNameObject } from "api/auditing/formatters/auditFormatters";
import { formatActionItemPriority, formatActionItemType } from "api/masterdata/formatters/masterDataFormatters";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { parseStringToDate } from "shared/utilities/dateFormatters";
import { isNotUndefined } from "shared/utilities/typeCheck";
import {
  ActionItemHistoryEvents,
  ActionItemStatuses,
  IActionItem,
  IActionItemEditor,
  IActionItemEvidence,
  IActionItemHistoryItem,
  IActionItemLink,
  IMyAction,
} from "types/actionItemTypes";
import { IPagedResult } from "types/pageResultsTypes";
import { formatAzureADUser } from "../../users/formatters/userFormatter";
import { IUnlinkActionItemResult } from "../ActionItemsApi";

export function formatCreateUpdateActionItemRequest(actionItem: IActionItem, sendNotification: boolean) {
  return {
    actionItem: {
      Id: actionItem.id,
      TypeId: actionItem.type?.id,
      PriorityId: actionItem.priority?.id,
      AssignedToName: actionItem.assignedTo?.name,
      AssignedToEmail: actionItem.assignedTo?.email,
      DueDate: new Date(actionItem.dueByTimestamp),
      ActionRequired: actionItem.actionRequired,
      Description: actionItem.description,
      CausalFactorId: actionItem.causalFactor?.id,
      Links: actionItem.links.map(x => formatActionItemLinkRequest(x)),
      Editors: actionItem.editors.map(x => formatActionItemEditorRequest(x))
    }
    , sendNotification: sendNotification
  };
}

export function formatActionItemLinkRequest(actionItemLink: IActionItemLink): any {
  return {
    Display: actionItemLink.display,
    Url: actionItemLink.url,
    LinkId: actionItemLink.linkId,
    Type: actionItemLink.type,
  };
}

export function formatActionItemEditorRequest(editor: IActionItemEditor): any {
  return {
    EditorName: editor.name,
    EditorEmail: editor.email,
  };
}

export function formatActionItemStatus(stat: any): ActionItemStatuses {
  if (stat === "Open") {
    return ActionItemStatuses.Open;
  } else if (stat === "Closed") {
    return ActionItemStatuses.Closed;
  } else {
    throw new Error(`Unknown action item status encountered: '${stat}'.`);
  }
}

export function formatActionItem(obj: any): IActionItem {

  const actionItemEvidenceItems = Array.isArray(obj.evidenceItems)
    ? obj.evidenceItems.map((evidence: any): IActionItemEvidence => formatActionItemEvidenceItem(evidence))
    : [];

  const actionItemEvidenceLinks = Array.isArray(obj.evidenceLinks)
    ? obj.evidenceLinks.map((evidenceLink: any): IActionItemEvidence => formatActionItemEvidenceLink(evidenceLink))
    : [];

  return {
    id: Number(obj.id),
    status: formatActionItemStatus(obj.status),
    assignedTo: {
      name: obj.assignedToName,
      email: obj.assignedToEmail,
    },
    dueByTimestamp: parseStringToDate(obj.dueDate).getTime(),
    actionRequired: obj.actionRequired,
    description: obj.description,
    createdBy: {
      name: obj.createdByName,
      email: obj.createdByEmail,
    },
    modifiedBy: {
      name: obj.modifiedByName,
      email: obj.modifiedByEmail,
    },
    createdOnTimestamp: parseStringToDate(obj.createdOn).getTime(),
    modifiedOnTimestamp: parseStringToDate(obj.modifiedOn).getTime(),
    closureTimestamp: obj.closureDate
      ? parseStringToDate(obj.closureDate).getTime()
      : undefined,
    causalFactor: obj.causalFactor
      ? formatIdNameObject(obj.causalFactor)
      : undefined,
    isValidated: Boolean(obj.isValidated),
    isLocked: Boolean(obj.isLocked),
    type: formatActionItemType(obj.type),
    priority: formatActionItemPriority(obj.priority),
    history: Array.isArray(obj.history)
      ? obj.history.map((item: any) => formatActionItemHistoryItem(item))
      : [],
    links: Array.isArray(obj.links)
      ? obj.links.map((link: any): IActionItemLink => formatActionItemLink(link))
      : [],
    editors: Array.isArray(obj.editors)
      ? obj.editors.map((editor: any): IActionItemEditor => formatActionItemEditor(editor))
      : [],
    evidences: [...actionItemEvidenceItems, ...actionItemEvidenceLinks],
    defaultSendNotification: obj.defaultSendNotification
  };
}

export function formatActionItemLink(obj: any): IActionItemLink {
  return {
    display: obj.display,
    linkId: obj.type === "Audit"
      || obj.type === "AuditQuestion"
      ? Number(obj.linkId)
      : obj.linkId,
    type: obj.type,
    url: obj.url,
  };
}

export function formatActionItemEditor(obj: any): IActionItemEditor {
  return {
    name: obj.editorName,
    email: obj.editorEmail,
  };
}

export function formatActionItemHistoryItem(obj: any): IActionItemHistoryItem {
  let user: IAzureADUser;

  if (obj.user) {
    user = formatAzureADUser(obj.user);
  } else if (obj.createdByName) {
    user = {
      name: obj.createdByName,
      email: obj.createdByEmail,
    };
  } else {
    user = {
      name: "",
      email: "",
    };
  }

  return {
    text: obj.text,
    timestamp: parseStringToDate(obj.timestamp).getTime(),
    userInfo: user,
    event: formatActionItemHistoryEvent(obj.event)
      || obj.event?.toString(),
  };
}

export function formatActionItemHistoryEvent(event: any): ActionItemHistoryEvents {
  return ActionItemHistoryEvents[event as keyof typeof ActionItemHistoryEvents];
}

export function formatUnlinkActionItemResponse(obj: any): IUnlinkActionItemResult {
  return {
    historyItem: formatActionItemHistoryItem(obj.createdHistoryItem),
    remainingLinkCount: Number(obj.remainingLinkCount),
  };
}

export function formatActionItemEvidenceItem(obj: any): IActionItemEvidence {
  return {
    id: Number(obj.id),
    actionItemId: Number(obj.actionItemId),
    filename: obj.filename,
    notes: obj.notes,
    type: "Evidence"
  };
}

export function formatGetMyActionsResponse(obj: any): IPagedResult<IMyAction> {
  return {
    currentPage: Number(obj.currentPage),
    pageSize: Number(obj.pageSize),
    results: Array.isArray(obj.results)
      ? obj
        .results
        .map((x: any) => formatMyAction(x))
        .filter(isNotUndefined)
      : [],
  };
}

export function formatMyAction(obj: any): IMyAction {
  return {
    actionRequired: obj.actionRequired || "--",
    assignedToName: obj.assignedToName || "--",
    createdByName: obj.createdByName || "--",
    createdOnTimestamp: parseStringToDate(obj.createdOn).getTime(),
    dueByTimestamp: parseStringToDate(obj.dueDate).getTime(),
    id: Number(obj.id),
    parentId: obj.parentId !== null
      && obj.parentId !== undefined
      ? Number(obj.parentId)
      : undefined,
    parentName: obj.parentName,
    parentType: obj.parentType,
    priority: obj.priority || "--",
    status: formatActionItemStatus(obj.status),
    isValidated: obj.isValidated === true,
    type: obj.type || "--",
    closureTimestamp: obj.closedOn
      ? parseStringToDate(obj.closedOn).getTime()
      : undefined,
    defaultSendNotification: obj.defaultSendNotification
  }
}

export function formatActionItemEvidenceLink(obj: any): IActionItemEvidence {
  return {
    id: Number(obj.id),
    actionItemId: Number(obj.actionItemId),
    link: obj.link,
    notes: obj.notes,
    type: "Link"
  };
}

export function formatActionItemEvidences(obj: any): IActionItemEvidence[] {

  const evidenceItems = Array.isArray(obj?.links)
    ? obj?.links.map((x: any) => formatActionItemEvidenceItem(x))
    : [];

  const evidenceLinks = Array.isArray(obj?.links)
    ? obj?.links.map((x: any) => formatActionItemEvidenceItem(x))
    : [];
  return [...evidenceItems, ...evidenceLinks];
}

