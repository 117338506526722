import ComplianceResult from "components/audits/common/compliance-result/ComplianceResult";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import { IColumnItem } from "shared/types/columnTypes";
import { IScoreTableItem, ITopicChildRow } from "./auditResultsTypes";
import "./sharedTableColumns.scoped.scss";

export function getAuditResultsTableColumns(expandedRowKeys: string[],
  onRowToggle?: (item: (IScoreTableItem | ITopicChildRow)) => void,
  requirementName?: string): IColumnItem<(IScoreTableItem | ITopicChildRow),
    keyof (IScoreTableItem | ITopicChildRow),
    void>[] {
  return [
    {
      key: "toggle",
      header: "",
      width: 0,
      customRender: onRowToggle
        ? ((item) =>
          !item.isChildRow
            && item.hasChildItems
            ? (
              <CollapseSwitch
                className="header-collapse-switch"
                isOpen={expandedRowKeys.some(x => x === item.requirementKey)}
                onClick={() => onRowToggle(item)}
              />
            ) : null
        ) : undefined,
    },
    {
      key: "requirement",
      header: requirementName || "Requirement",
      width: 250,
      maxHeight: "4em",
      customRender: item => !item.isChildRow ? item.requirementName : undefined,
    },
    {
      key: "totalQuestions",
      header: "Total Questions",
      width: 100,
      customRender: item => !item.isChildRow ? item.totalQuestions : undefined,
    },
    {
      key: "ltoQuestions",
      header: "LTO Questions",
      width: 100,
      customRender: item => !item.isChildRow ? item.numLTOQuestions : undefined,
    },
    {
      key: "nonCompQuestions",
      header: "Non-Compliance Questions",
      width: 150,
      customRender: item => !item.isChildRow ? item.nonCompliantQuestions : undefined
    },
    {
      key: "ltoNonCompQuestions",
      header: "Non-Compliance LTO Questions",
      width: 100,
      customRender: item => !item.isChildRow ? item.numLTONonCompliantQuestions : undefined,
    },
    {
      key: "complianceScore",
      header: "Compliance Score",
      width: 100,
      customRender: item => !item.isChildRow
        ? (item.complianceScore === undefined
          ? "-"
          : Math.floor(item.complianceScore * 100) + "%")
        : undefined,
    },
    {
      key: "complianceResult",
      header: "Compliance",
      width: 150,
      customRender: item => !item.isChildRow
        ? (
          <ComplianceResult
            complianceResult={item.complianceResult}
          />
        ) : undefined,
    },
    {
      key: "cat1",
      header: "CAT 1",
      width: 75,
      customRender: item => !item.isChildRow
        ? (item.cat1Score === undefined
          ? "-"
          : Math.floor(item.cat1Score * 100) + "%"
        ) : undefined,
    },
    {
      key: "cat2",
      header: "CAT 2",
      width: 75,
      customRender: item => !item.isChildRow
        ? (item.cat2Score === undefined
          ? "-"
          : Math.floor(item.cat2Score * 100) + "%"
        ) : undefined,
    },
    {
      key: "lto",
      header: "LTO",
      width: 75,
      customRender: item => !item.isChildRow
        ? (item.ltoCompliance === undefined
          ? "-"
          : Math.floor(item.ltoCompliance * 100) + "%"
        ) : undefined,
    },
    {
      key: "numActionItems",
      header: "# Action Items",
      width: 100,
      customRender: item => {
        if (item.isChildRow) {
          return undefined;
        }

        const isFullyCompliant = item.complianceScore === 1;
        const hasActionItems = item.numActionItems > 0;

        return (
          <span
            className={`action-item-bubble ${hasActionItems
                || isFullyCompliant
                ? "greater-than-zero-count-circle"
                : "zero-count-circle"
              }`}
          >
            {item.numActionItems}
          </span>
        );
      },
    },
  ];
}