import { calcComplianceScore } from "components/audits/common/auditScoreCalculator";
import { groupBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import TabSelection, { ITabItem } from "shared/components/layout/tab-selection/TabSelection";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { NonConformanceTabs, selectComplianceScore, setExpandedRowKeys } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { IAuditScoreResult } from "types/audit-scores/auditScoreTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import TopicAssociationsCard from "./TopicAssociationsCard";
import { IScoreTableItem, ITopicChildRow } from "./auditResultsTypes";
import { getAuditResultsTableColumns } from "./sharedTableColumns";
import "./AuditResultCard.scoped.scss";

const completionText = "Completed";
const currentText = "Current";
const closureText = "Closed";
export const hseOnlyColumns = ["ltoQuestions", "ltoNonCompQuestions", "lto",];

const AuditResultCard: React.FC = () => {
  const selectedComplianceScore = useAppSelector(store => store.auditNonConformance.selectedComplianceTab);
  const expandedRowKeys = useAppSelector(store => store.auditNonConformance.expandedRowKeys);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const questions = useAppSelector(store => store.audit.questions);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const auditComplianceScores = useAppSelector(store => store.audit.scores);
  const isLoadingScores = useAppSelector(store => store.audit.loadScoresOp?.isWorking);
  const auditInfo = useAppSelector(store => store.audit.audit);

  const scoringSystem = questions[0]?.scoringSystem;

  const nonConformantAnswerCodes = allAnswers.filter(x => x.canBeUpdated).map(x => x.code);

  let visibleComplianceScores: IAuditScoreResult[] | undefined = undefined;

  if (selectedComplianceScore === NonConformanceTabs.current) {
    visibleComplianceScores = auditComplianceScores?.currentScores?.metaScores;
  } else if (selectedComplianceScore === NonConformanceTabs.complete) {
    visibleComplianceScores = auditComplianceScores?.completedScores?.metaScores;
  } else if (selectedComplianceScore === NonConformanceTabs.closed) {
    visibleComplianceScores = auditComplianceScores?.closedScores?.metaScores;
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auditStatus === AuditStatuses.Closed) {
      dispatch(selectComplianceScore(NonConformanceTabs.closed));
    } else {
      dispatch(selectComplianceScore(NonConformanceTabs.current));
    }
  }, [auditStatus, dispatch]);

  let tabs: ITabItem[] = getTabs(selectedComplianceScore, auditStatus);

  const rollupScores: (IScoreTableItem | ITopicChildRow)[] = useMemo(() => {
    return auditStatus === undefined
      ? []
      : getTopicScores(questions,
        nonConformantAnswerCodes,
        actionItems,
        selectedComplianceScore,
        auditStatus,
        visibleComplianceScores,
        auditInfo);
  }, [questions,
    nonConformantAnswerCodes,
    actionItems,
    selectedComplianceScore,
    auditStatus,
    visibleComplianceScores,
    auditInfo]);

  const onRowToggle = (item: IScoreTableItem | ITopicChildRow) => dispatch(
    setExpandedRowKeys(expandedRowKeys.some(x => x === item.requirementKey)
      ? expandedRowKeys.filter(x => x !== item.requirementKey)
      : expandedRowKeys.concat(item.requirementKey)));

  const requirementName = getRequirementColumnName(scoringSystem);

  let tableColumns = getAuditResultsTableColumns(expandedRowKeys, onRowToggle, requirementName);

  // Certain scoring systems do not show specific columns in the table.
  if (scoringSystem === AuditScoringSystems.QMS) {
    tableColumns = tableColumns.filter(x => x.key !== "toggle"
      && !hseOnlyColumns.includes(x.key));
  } else if (scoringSystem === AuditScoringSystems.CLM) {
    tableColumns = tableColumns.filter(x => !hseOnlyColumns.includes(x.key));
  }

  const renderCustomRow = (item: IScoreTableItem | ITopicChildRow) => {
    if (item.isChildRow) {
      if (expandedRowKeys.some(x => x === item.requirementKey)) {
        // The parent item is expanded so the child should be shown.
        // Need to return a `tr` for this child item.
        let childScores = visibleComplianceScores
          ?.filter(x => `${x.scoreByType}_${x.scoreById}` === item.requirementKey
            && x.includedQuestions === "ChildDimensionOnly")
          ?? [];

        // Find all subtopics that belong to this topic and add their scores.
        const subTopicKeys = auditInfo
          ?.auditTopics
          .filter(x => x.parentId === item.parentTopicId)
          .map(x => `${MetaDataTypes.AuditTopic}_${x.id}`);

        // Since a subtopic score is generate for the "global" dimension (with no child)
        // as well as every child dimension in the audit, and all subtopic scores are
        // identical because they include ALL subtopic questions regardless, just grab
        // the global one since we don't want to show duplicates.
        const subtopicScores = visibleComplianceScores
          ?.filter(x => subTopicKeys?.some(z => z === `${x.scoreByType}_${x.scoreById}`)
            && x.includedQuestions === "AllSubtopicQuestions"
            && x.childDimensionId === undefined
            && x.childDimensionType === undefined)
          ?? [];

        // Also find the Global Only score for this topic and include it.
        let scoresToShow = childScores
          .concat(subtopicScores)
          .concat(visibleComplianceScores
            ?.filter(x => `${x.scoreByType}_${x.scoreById}` === item.requirementKey
              && x.childDimensionId === undefined
              && x.childDimensionType === undefined
              && x.includedQuestions === "GlobalOnly")
            ?? []);

        // Need to filter out duplicates, keeping only the first parent's set of scores.
        if (scoresToShow.length) {
          scoresToShow = scoresToShow
            .filter(x => x.parentDimensionId === scoresToShow[0].parentDimensionId
              && x.parentDimensionType === scoresToShow[0].parentDimensionType);
        }

        return (
          <tr key={`children_of_${item.requirementKey}`}>
            <td>&nbsp;</td>
            <td colSpan={tableColumns.length}>
              <TopicAssociationsCard
                topicScores={scoresToShow}
                scoringSystem={scoringSystem}
              />
            </td>
          </tr>
        );
      }
      return null;
    } else {
      return undefined;
    }
  };

  return (
    <Card
      showHeader={true}
      headerElement={(
        <div className="audit-result-labels">
          <div className="audit-result-display">
            <LabeledControl
              label=""
              isLight={true}
            >
              <b>
                Audit Results
              </b>
            </LabeledControl>
          </div>
          <div className="audit-result-display">
            <TabSelection
              tabs={tabs}
              onTabClicked={key => dispatch(selectComplianceScore(key as NonConformanceTabs))}
              isDisabled={isLoadingScores}
            />
          </div>
        </div>
      )}
    >
      {isLoadingScores
        ? <Spinner />
        : (
          <Table<(IScoreTableItem | ITopicChildRow), keyof (IScoreTableItem | ITopicChildRow), void>
            data={rollupScores}
            columns={scoringSystem === AuditScoringSystems.QMS
              ? [...tableColumns.filter(x => !["cat1", "cat2"].includes(x.key))]
              : tableColumns}
            keyProp="requirementKey"
            theme={TableThemes.compact}
            customRowRender={renderCustomRow}
          />
        )}
    </Card>
  );
};

export default AuditResultCard;

function getTabs(selectedComplianceScore: NonConformanceTabs, auditStatus: AuditStatuses | undefined) {
  let tabs: ITabItem[] = [];

  const currentTab = {
    key: NonConformanceTabs.current,
    node: currentText,
    isLink: false,
    isSelected: selectedComplianceScore === NonConformanceTabs.current,
  };
  const completeTab = {
    key: NonConformanceTabs.complete,
    node: completionText,
    isLink: false,
    isSelected: selectedComplianceScore === NonConformanceTabs.complete,
  };
  const closedTab = {
    key: NonConformanceTabs.closed,
    node: closureText,
    isLink: false,
    isSelected: selectedComplianceScore === NonConformanceTabs.closed,
  };

  if (auditStatus === AuditStatuses.InProgress) {
    tabs = [currentTab];
  } else if (auditStatus === AuditStatuses.Completed) {
    tabs = [
      completeTab,
      currentTab,
    ];
  } else if (auditStatus === AuditStatuses.Closed) {
    tabs = [
      completeTab,
      closedTab
    ];
  }

  return tabs;
}

function getTopicScores(questions: IAuditQuestion[],
  nonConformantAnswerCodes: string[],
  actionItems: IActionItem[],
  selectedComplianceScore: NonConformanceTabs,
  auditStatus: AuditStatuses,
  visibleComplianceScores: IAuditScoreResult[] | undefined,
  auditInfo: IAuditInfo | undefined
): (IScoreTableItem | ITopicChildRow)[] {
  const complianceScores: (IScoreTableItem | ITopicChildRow)[] = [];

  // Get the question responses with which the topic scores will be calculated.
  // Then group them by their topic id.
  const topicGroupsToCalculate = groupBy(questions
    .flatMap(x => ({
      scoringSystem: x.scoringSystem,
      category: x.category,
      topicId: x.topicId,
      topicName: x.topicName,
      auditQuestionId: x.auditQuestionId,
      answer: x.responses.find(z =>
        (selectedComplianceScore === NonConformanceTabs.current
          && z.auditStatus === auditStatus)
        || (selectedComplianceScore === NonConformanceTabs.complete
          && z.auditStatus === AuditStatuses.InProgress)
        || (selectedComplianceScore === NonConformanceTabs.closed
          && z.auditStatus === AuditStatuses.Completed)
      )?.answer,
      isLto: x.licenseToOperate,
    })), x => x.topicId);

  const topicDataToCalculate = Object.entries(topicGroupsToCalculate).map(data => ({
    topicId: data[0],
    responses: data[1],
    requirementName: data[1][0].topicName,
  })).sort((a, b) => a.requirementName < b.requirementName ? -1 : 1);

  for (let i = 0; i < topicDataToCalculate.length; i++) {
    const topicId = topicDataToCalculate[i].topicId;
    const responses = topicDataToCalculate[i].responses;

    // Note: We need to continue calculating the ROLLUP scores on the client since they are not calculated in the backend.
    const score = calcComplianceScore(responses, responses[0].scoringSystem as AuditScoringSystems);

    if (!score) {
      // No score. Skip this row.
      continue;
    }

    const actionItemsInThisTopic = findActionItemsByParentItems(actionItems,
      responses.map(x => ({
        linkType: ActionItemLinkTypes.AuditQuestion,
        linkId: x.auditQuestionId,
      })));

    const childScores = visibleComplianceScores?.filter(x => x.scoreById === Number(topicId)
      && x.scoreByType === MetaDataTypes.AuditTopic
      && x.childDimensionType !== undefined
      && x.childDimensionId !== undefined) ?? [];

    // Find all subtopics that belong to this topic and add their scores.
    const subTopicIds = auditInfo
      ?.auditTopics
      .filter(x => x.parentId === Number(topicId))
      .map(x => x.id);

    // Since a subtopic score is generate for the "global" dimension (with no child)
    // as well as every child dimension in the audit, and all subtopic scores are
    // identical because they include ALL subtopic questions regardless, just grab
    // the global one since we don't want to show duplicates.
    const subtopicScores = visibleComplianceScores
      ?.filter(x => subTopicIds?.some(z => z === x.scoreById)
        && x.scoreByType === MetaDataTypes.AuditTopic
        && x.includedQuestions === "AllSubtopicQuestions"
        && x.childDimensionId === undefined
        && x.childDimensionType === undefined)
      ?? [];

    complianceScores.push({
      numActionItems: actionItemsInThisTopic.length,
      complianceResult: score.overallCompliance,
      complianceScore: score.overallPercentage || 0,
      cat1Score: score.cat1Percentage,
      cat2Score: score.cat2Percentage,
      nonCompliantQuestions: responses.filter(x => nonConformantAnswerCodes.some(nonConf => nonConf === x.answer)).length,
      requirementId: Number(topicId),
      requirementType: MetaDataTypes.AuditTopic,
      requirementKey: `AuditTopic_${topicId}`,
      requirementName: responses[0].topicName,
      totalQuestions: responses.length,
      hasChildItems: childScores.length > 0
        || subtopicScores.length > 0,
      isChildRow: false,
      numLTONonCompliantQuestions: score.numLTONonCompliantQuestions,
      numLTOQuestions: score.numLTOQuestions,
      ltoCompliance: score.ltoCompliance,
    });

    if (childScores.length > 0
      || subtopicScores.length > 0) {
      // Now push a custom row item that represents the children of this row.
      // This item will not be visible in the grid unless the user expands the parent
      // and it will use the table's custom render to gather the appropriate data.
      const childRow: ITopicChildRow = {
        requirementKey: `AuditTopic_${topicId}`,
        requirementName: "",
        isChildRow: true,
        parentTopicId: Number(topicId),
      };

      complianceScores.push(childRow);
    }
  }

  if (questions[0]?.scoringSystem === AuditScoringSystems.QMS) {
    // Now get the API Q2 score
    const q2Questions = questions
      .filter(x => x.apis.some(a => a.spec === "Q2"));

    if (q2Questions.length) {
      const q2Responses = q2Questions
        .map(x => ({
          response: x.responses.find(z =>
            (selectedComplianceScore === NonConformanceTabs.current
              && z.auditStatus === auditStatus)
            || (selectedComplianceScore === NonConformanceTabs.complete
              && z.auditStatus === AuditStatuses.InProgress)
            || (selectedComplianceScore === NonConformanceTabs.closed
              && z.auditStatus === AuditStatuses.Completed)),
          isLto: x.licenseToOperate,
        }))
        .filter(isNotUndefined);

      const score = calcComplianceScore(q2Responses.map(x => ({
        category: "",
        answer: x.response?.answer,
        isLto: x.isLto,
      })), questions[0].scoringSystem);

      const actionItemsInQ2 = findActionItemsByParentItems(actionItems,
        q2Questions.map(x => ({
          linkId: x.auditQuestionId,
          linkType: ActionItemLinkTypes.AuditQuestion,
        })));

      complianceScores.push({
        numActionItems: actionItemsInQ2.length,
        complianceResult: score?.overallCompliance,
        complianceScore: score?.overallPercentage || 0,
        cat1Score: undefined,
        cat2Score: undefined,
        nonCompliantQuestions: q2Responses
          .filter(x => nonConformantAnswerCodes.some(nonConf => nonConf === x?.response?.answer))
          .length,
        requirementId: 0,
        requirementType: MetaDataTypes.API,
        requirementKey: `API_0`,
        requirementName: "API Q2",
        totalQuestions: q2Questions.length,
        hasChildItems: false,
        isChildRow: false,
        numLTONonCompliantQuestions: score?.numLTONonCompliantQuestions,
        numLTOQuestions: score?.numLTOQuestions,
        ltoCompliance: score?.ltoCompliance,
      });
    }
  }

  return complianceScores;
}

function getRequirementColumnName(scoringSystem: string) {
  switch (scoringSystem) {
    case AuditScoringSystems.CLM: return "Audit Topic";
    case AuditScoringSystems.HSE: return "Audit Topic";
    case AuditScoringSystems.QMS: return "Quality Management System";
    default: return "Requirement";
  }
}
