import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getResponseFromQuestion, sortQuestionsByTopicThenSubTopicThenNumber } from "components/audits/common/auditUtilities";
import React, { useEffect } from "react";
import DetailText from "shared/components/common/detail-text/DetailText";
import Divider from "shared/components/common/divider/Divider";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { IColumnItem } from "shared/types/columnTypes";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { AuditExecutionPickerKeys, applyFilters, closeFilters, closePicker, expandPickerItem, goToQuestion, openPicker, setFilterQuestionNumText, setFilterQuestionText, setPickerItems, setPickerState, setSelectedPickerItems, updateAnswerFilterOptions } from "store/audit-execution/AuditExecutionSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAnswer, IAuditTopic } from "types/auditMasterDataTypes";
import { AuditStatuses, IAuditQuestion, IAuditQuestionResponse } from "types/auditingTypes";
import AnswerDisplay from "./AnswerDisplay";
import "./FilterModal.scoped.scss";

const FilterModal: React.FC = () => {
  const {
    pickerData,
    filterModal: {
      isOpen,
      questionNumberFilter,
      questionTextFilter,
    },
  } = useAppSelector(store => store.auditExecution);

  const {
    answerFilter,
    topicFilter,
    auditeeFilter,
    auditorFilter,
  } = pickerData;

  // Get the list of questions from the main store.
  const questions = useAppSelector(store => store.audit.questions);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const allAnswers = useAppSelector(store => store.audit.answers);

  const dispatch = useAppDispatch();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    dispatch(updateAnswerFilterOptions({
      questionsInAudit: questions,
      auditStatus,
      allAnswers,
    }));
  }, [dispatch, allAnswers, questions, auditStatus]);

  useEffect(() => {
    if (isOpen) {
      appInsights.trackPageView({
        name: `Audit Questions - Filter Questions`,
      });
    }
  }, [isOpen, appInsights]);

  useEffect(() => {
    if (!auditStatus) {
      return;
    }

    // Build the available picker values from the questions.
    const filterVals = buildAvailableFilterValues(questions, auditStatus, allAnswers);

    // Update the state's picker settings.
    dispatch(setPickerState(filterVals.answerFilter));
    dispatch(setPickerState(filterVals.auditeeFilter));
    dispatch(setPickerState(filterVals.auditorFilter));
    dispatch(setPickerState(filterVals.topicFilter));
  }, [questions, auditStatus, allAnswers, dispatch]);

  const filteredQuestions = auditStatus
    ? filterQuestions(questions,
      auditStatus,
      questionTextFilter,
      questionNumberFilter,
      pickerData)
    : [];

  return (
    <Modal
      header={
        <FlexCol>
          <FlexRow>
            <span
              className="header"
            >
              Filter Questions
            </span>
            <LabeledControl
              label="Showing"
              className="q-counts"
            >
              {filteredQuestions.length}/{questions.length} questions
            </LabeledControl>
          </FlexRow>

          <Divider />

          <FlexRow
            className="control-row"
          >
            <LabeledControl
              label="Question #"
              className="number-filter"
            >
              <input
                type="text"
                className="shrink-question-number"
                value={questionNumberFilter}
                onChange={e => dispatch(setFilterQuestionNumText(e.currentTarget.value))}
              />
            </LabeledControl>

            <LabeledControl
              label="Question Text"
              className="text-filter"
            >
              <input
                type="text"
                value={questionTextFilter}
                onChange={e => dispatch(setFilterQuestionText(e.currentTarget.value))}
              />
            </LabeledControl>

            <LabeledControl
              label="Topic/Sub-Topic"
              className="topic-filter"
            >
              <Picker<IAuditTopic>
                pickerState={topicFilter}
                title="Topic/Sub-Topic"
                searchOptions={{
                  filterItem: (item, search) => item.item?.name
                    .toLowerCase()
                    .includes(search.toLowerCase()) === true
                }}
                displayMode="tree"
                preserveItems={true}
                renderListItem={(item) => item.name}
                renderSelectedItem={(item) => item.name}
                maxSelectedItemsVisible={1}
                openAction={openPicker}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                expandItemsAction={expandPickerItem}
                allowMultiSelect={true}
                itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
              />
            </LabeledControl>

            <LabeledControl
              label="Answer"
              className="answer-filter"
            >
              <Picker<IAnswer>
                pickerState={answerFilter}
                title="Answer Types"
                searchOptions={{
                  filterItem: (item, search) => (item.text ?? item.item?.name ?? "")
                    .toLowerCase()
                    .includes(search.toLowerCase()) === true
                }}
                preserveItems={true}
                renderListItem={item => item.name}
                renderSelectedItem={item => item.name}
                openAction={openPicker}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                allowMultiSelect={true}
                itemSorter={(a, b) => (a.text || "").localeCompare(b.text || "")}
              />
            </LabeledControl>

            <LabeledControl
              label="Auditor"
              className="auditor-filter"
            >
              <Picker<IAzureADUser>
                pickerState={auditorFilter}
                title="Auditor"
                searchOptions={{
                  filterItem: (item, search) => userToString(item.item).toLowerCase().includes(search.toLowerCase())
                }}
                preserveItems={true}
                renderListItem={(item) => userToString(item)}
                renderSelectedItem={(item) => userToString(item)}
                maxSelectedItemsVisible={1}
                openAction={openPicker}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                allowMultiSelect={false}
                itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
              />
            </LabeledControl>

            <LabeledControl
              label="People Interviewed"
              className="auditee-filter"
            >
              <Picker<IAzureADUser>
                pickerState={auditeeFilter}
                title="People Interviewed"
                searchOptions={{
                  filterItem: (item, search) => userToString(item.item).toLowerCase().includes(search.toLowerCase())
                }}
                preserveItems={true}
                renderListItem={(item) => userToString(item)}
                renderSelectedItem={(item) => userToString(item)}
                maxSelectedItemsVisible={1}
                openAction={openPicker}
                closeAction={closePicker}
                setItemsAction={setPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                allowMultiSelect={false}
                itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
              />
            </LabeledControl>
          </FlexRow>
        </FlexCol>
      }
      isOpen={isOpen}
      buttons={[{
        text: "Cancel",
        key: "CANCEL",
        className: "secondary",
        onClick: () => dispatch(closeFilters()),
      }, {
        text: "Apply",
        key: "APPLY",
        className: "primary",
        disabled: filteredQuestions.length === 0,
        onClick: () => dispatch(applyFilters(filteredQuestions.map(x => x.auditQuestionId))),
      }]}
    >
      <div
        className="modal-body"
      >
        {auditStatus && (
          <Table
            className="filter-table"
            data={filteredQuestions}
            columns={getFilterTableColumns(dispatch)}
            getBeforeRenderRowData={question => {
              const compData: IRowComputedData = {
                currResponse: getResponseFromQuestion(question, auditStatus),
                filteredQuestions,
                currAnswer: undefined,
              };

              compData.currAnswer = compData.currResponse?.answer
                ? allAnswers.find(x => x.code === compData.currResponse?.answer)
                : undefined;

              return compData;
            }}
            keyProp="auditQuestionId"
            theme={TableThemes.compact}
          />
        )}
      </div>
    </Modal>
  );
};

export default FilterModal;

interface IRowComputedData {
  currResponse: IAuditQuestionResponse | undefined,
  currAnswer: IAnswer | undefined,
  filteredQuestions: IAuditQuestion[],
}

function getFilterTableColumns(dispatch: Dispatch<AnyAction>): IColumnItem<IAuditQuestion, keyof IAuditQuestion, IRowComputedData>[] {
  return [
    {
      key: "id",
      header: "ID",
      width: 75,
      customRender: (item, compData) => (
        <button
          className="link"
          onClick={() => dispatch(goToQuestion({
            auditQuestionId: item.auditQuestionId,
            aqIdQueue: compData?.filteredQuestions.map(x => x.auditQuestionId) || [],
          }))}
        >
          {item.questionNumber}
        </button>
      ),
    },
    {
      key: "topic",
      header: "Topic/Sub-Topic",
      width: 175,
      maxHeight: "4em",
      customRender: item => (
        <DetailText
          showModalOnClick={true}
          modalData={{
            content: (
              <ul>
                {[
                  item.topicName,
                  ...item.subTopics.map(x => x.name),
                ].map(name => (
                  <li
                    key={name}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            ),
            header: "Topic / Sub-Topic"
          }}
          text={item.subTopics.length > 0
            ? [
              item.topicName,
              ...item.subTopics.map(x => x.name),
            ].join(', ')
            : item.topicName}
          maxTextLength={47}
        />
      ),
    },
    {
      key: "question",
      header: "Question",
      customRender: item => item.question,
    },
    {
      key: "answer",
      header: "Answer",
      width: 65,
      customRender: (_, rowData) => (
        <AnswerDisplay
          answerCode={rowData?.currResponse?.answer}
          answer={rowData?.currAnswer}
        />
      ),
    },
  ];
}

interface ICompiledFilters {
  answerFilter: IPickerState<IAnswer | undefined>,
  topicFilter: IPickerState<IAuditTopic>,
  auditorFilter: IPickerState<IAzureADUser>,
  auditeeFilter: IPickerState<IAzureADUser>,
}

function buildAvailableFilterValues(questions: IAuditQuestion[],
  auditStatus: AuditStatuses,
  allAnswers: IAnswer[]): ICompiledFilters {
  // Rebuild the list of available topic/subtopics for the picker.
  const pickerData: ICompiledFilters = {
    answerFilter: {
      isOpen: false,
      items: [],
      key: AuditExecutionPickerKeys.answerFilter,
      selectedItems: [],
    },
    topicFilter: {
      isOpen: false,
      items: [],
      key: AuditExecutionPickerKeys.topicFilter,
      selectedItems: [],
    },
    auditorFilter: {
      isOpen: false,
      items: [],
      key: AuditExecutionPickerKeys.auditorFilter,
      selectedItems: [],
    },
    auditeeFilter: {
      isOpen: false,
      items: [],
      key: AuditExecutionPickerKeys.auditeeFilter,
      selectedItems: [],
    },
  };

  pickerData.topicFilter.selectedItems = [];
  pickerData.topicFilter.items = [];

  questions.forEach(q => {
    const existingTopic = pickerData.topicFilter.items.find(x => x.key === q.topicId);

    if (!existingTopic) {
      const newTopicItem: IPickerItem<IAuditTopic> = {
        key: q.topicId,
        disabled: false,
        item: {
          auditGroup: "",
          auditGroupId: 0,
          id: q.topicId,
          isDeleted: false,
          isPlannable: false,
          level: 1,
          name: q.topicName,
          isSelectable: true,
          groupName: "",
          ownerGroup: "",
          sortOrder: 1,
          scoringSystem: "",
        },
      };

      const subTopics: IPickerItem<IAuditTopic>[] = q.subTopics.map((subT): IPickerItem<IAuditTopic> => ({
        key: subT.id,
        disabled: !subT.isSelectable,
        item: {
          auditGroup: "",
          auditGroupId: 0,
          id: subT.id,
          isDeleted: false,
          isPlannable: false,
          level: 2,
          name: subT.name,
          groupName: subT.groupName,
          ownerGroup: subT.ownerGroup,
          isSelectable: subT.isSelectable,
          sortOrder: 1,
          scoringSystem: "",
        },
      }));

      newTopicItem.children = subTopics;

      // Add this topic with its subtopics.
      pickerData.topicFilter.items.push(newTopicItem);
    } else {
      // Check that the subtopics exist inside the topic.
      const subTopics: IPickerItem<IAuditTopic>[] = q.subTopics.map((subT): IPickerItem<IAuditTopic> => ({
        key: subT.id,
        disabled: false,
        item: {
          auditGroup: "",
          auditGroupId: 0,
          id: subT.id,
          isDeleted: false,
          isPlannable: false,
          level: 2,
          name: subT.name,
          groupName: "",
          ownerGroup: subT.ownerGroup,
          isSelectable: true,
          sortOrder: 1,
          scoringSystem: "",
        },
      }));

      subTopics.forEach(st => {
        if (!existingTopic.children?.find(z => z.key === st.key)) {
          existingTopic.children?.push(st);
        }
      });
    }
  });

  // Sort all the topic/subtopics.
  pickerData.topicFilter.items.sort((a, b) =>
    (a.item?.name || a.text || "") < (b.item?.name || b.text || "")
      ? -1
      : 1);

  pickerData.topicFilter.items.forEach(t => {
    t.children?.sort((a, b) =>
      (a.item?.name || a.text || "") < (b.item?.name || b.text || "")
        ? -1
        : 1);
  });

  // Rebuild the list of available question answers for the picker.
  pickerData.answerFilter.items = [];

  questions.forEach(q => {
    let currResponse = getResponseFromQuestion(q, auditStatus);

    if ((!currResponse
      || !currResponse.answer)
      && !pickerData.answerFilter.items.some(x => x.key === "-")) {
      pickerData.answerFilter.items.push({
        key: "-",
        text: "Unanswered",
        disabled: false,
        item: undefined,
      });
    } else if (currResponse
      && currResponse.answer) {
      if (!pickerData.answerFilter.items.some(x => x.key === currResponse?.answer)) {
        pickerData.answerFilter.items.push({
          key: currResponse.answer,
          disabled: false,
          item: allAnswers.find(x => x.code === currResponse?.answer),
        });
      }
    }
  });

  // Rebuild the list of available interviewees for the picker.
  pickerData.auditeeFilter.items = [];

  questions.forEach(q => {
    const interviewees = q.interviewees || [];

    if (!interviewees.length) {
      return;
    }

    interviewees.forEach(a => {
      if (!pickerData.auditeeFilter.items.some(x => x.key === a.email)) {
        pickerData.auditeeFilter.items.push({
          key: a.email,
          disabled: false,
          item: a,
        });
      }
    });
  });

  pickerData.auditeeFilter.items.sort((a, b) => {
    let nameA = a.item?.name || a.text || "";
    let nameB = b.item?.name || b.text || "";

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }

    return a.key < b.key
      ? -1
      : 1;
  });

  // Filter out the selected answers that aren't in the item list anymore.
  pickerData.answerFilter.selectedItems =
    pickerData.answerFilter.selectedItems
      .filter(x => !pickerData.answerFilter.items.some(z => z.key === x.key));

  // Rebuild the list of available auditors for the picker.
  pickerData.auditorFilter.selectedItems = [];
  pickerData.auditorFilter.items = [];

  questions.forEach(q => {
    const auditor: IAzureADUser | undefined = q.auditorEmail
      ? {
        email: q.auditorEmail,
        name: q.auditorName || "",
      } : undefined;

    if (!auditor) {
      return;
    }

    if (!pickerData.auditorFilter.items.some(x => x.key === auditor.email)) {
      pickerData.auditorFilter.items.push({
        key: auditor.email,
        disabled: false,
        item: auditor,
      });
    }
  });

  return pickerData;
}

function filterQuestions(questions: IAuditQuestion[],
  auditStatus: AuditStatuses,
  questionTextFilter: string,
  questionNumberFilter: string,
  pickerData: {
    auditees: IPickerState<IAzureADUser>,
    answerFilter: IPickerState<IAnswer>,
    topicFilter: IPickerState<IAuditTopic>,
    auditorFilter: IPickerState<IAzureADUser>,
    auditeeFilter: IPickerState<IAzureADUser>,
  }) {
  const {
    answerFilter,
    topicFilter,
    auditorFilter,
    auditeeFilter,
  } = pickerData;

  return questions
    .filter(q => {
      if (questionTextFilter
        && q.question.toLowerCase().indexOf(questionTextFilter.toLowerCase()) === -1) {
        return false;
      }

      if (questionNumberFilter
        && q.questionNumber.toString().toLowerCase().indexOf(questionNumberFilter.toLowerCase()) === -1) {
        return false;
      }

      if (answerFilter.selectedItems.length) {
        const response = getResponseFromQuestion(q, auditStatus);

        if (!response?.answer
          && !answerFilter.selectedItems.some(selItem => selItem.key === "-")) {
          return false;
        } else if (response?.answer
          && !answerFilter.selectedItems.some(selItem => selItem.key === response?.answer)) {
          return false;
        }
      }

      if (topicFilter.selectedItems.length) {
        const selTopics = topicFilter.selectedItems
          .filter(x => x.item?.level === 1).map(x => x.item) || [];
        const selSubTopics = topicFilter.selectedItems
          .filter(x => x.item?.level === 2).map(x => x.item) || [];

        if (selTopics.length
          && !selTopics.some(x => x?.id === q.topicId)) {
          return false;
        }

        if (selSubTopics.length
          && !selSubTopics.some(x => q.subTopics.some(stx => stx.id === x?.id))) {
          return false;
        }
      }

      if (auditorFilter.selectedItems.length
        && !auditorFilter.selectedItems.some(f =>
          f.key.toString().toLowerCase() === q.auditorEmail?.toLowerCase())) {
        return false;
      }

      if (auditeeFilter.selectedItems.length
        && !auditeeFilter.selectedItems.some(f =>
          q.interviewees.some(a => f.key.toString().toLowerCase() === a.email.toLowerCase()))) {
        return false;
      }

      return true;
    }).sort((a, b) => sortQuestionsByTopicThenSubTopicThenNumber(a, b, []));
}
