import { uniqBy } from "lodash";
import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import TabSelection, { ITabItem } from "shared/components/layout/tab-selection/TabSelection";
import { ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";
import "./CLMTopicTabs.scoped.scss";

interface ICLMTopicTabsProps {
  requirements: ICLMPartialRequirementItemDto[],
  selectedTopicId: number,
  onTopicChange(topicId: number): void,
  onTopicOptInChanged(topicId: number, isOptedIn: boolean): void,
}

const CLMTopicTabs: React.FC<ICLMTopicTabsProps> = ({
  requirements,
  selectedTopicId,
  onTopicChange,
  onTopicOptInChanged,
}: ICLMTopicTabsProps) => {
  const topicTabs = Object.entries(
    uniqBy(requirements.map(x => ({
      topicId: x.topicId,
      topicName: x.topicName,
    })), x => x.topicId)
  ).map((entry): ITabItem => ({
    key: entry[1].topicId,
    node: entry[1].topicName,
    isSelected: entry[1].topicId === selectedTopicId,
  }));

  const currTopicReqs = requirements.filter(x => x.topicId === selectedTopicId);

  return (
    <FlexCol>
      <FlexRow>
        <TabSelection
          tabs={topicTabs}
          onTabClicked={key => onTopicChange(Number(key))}
          showDividers={false}
          className="topic-tabs"
        />
      </FlexRow>

      <FlexRow>
        <span
          className="blank-spacer"
        ></span>
        <FlexRow>
          <input
            type="checkbox"
            checked={!currTopicReqs
              .some(x => !x.isInScope
                && !x.isOptedIn)}
            disabled={!currTopicReqs
              .some(x => !x.isInScope)}
            id="edit_plan_clm_partial_checkall"
            onChange={e => onTopicOptInChanged(selectedTopicId, e.currentTarget.checked)}
          />

          <FlexCol>
            <label
              htmlFor="edit_plan_clm_partial_checkall"
            >
              Include in Audit
            </label>
            <span
              className="note"
            >
              Note: Procedures which are selected & disabled are non-compliant ones from last audit.
            </span>
          </FlexCol>
        </FlexRow>
      </FlexRow>
    </FlexCol>
  );
};

export default CLMTopicTabs;