import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IQuestion } from "types/questionTypes";
import "./QuestionIdDisplay.scoped.scss";

interface IQuestionIdDisplayProps {
  /** The question data to render. */
  question: Partial<IQuestion>,
  /** Optional. If true, the element will take up space but not appear. This is useful for
   * ensuring each card has the same header height without rendering the id data multiple times. */
  invisible?: boolean,
}

const QuestionIdDisplay: React.FC<IQuestionIdDisplayProps> = ({
  question,
  invisible,
}) => {
  return (
    <>
      {question.id && (
        <FlexRow
          className={`gray-bg ${invisible ? "invisible" : ""}`}
        >
          <LabeledControl
            label="QI ID"
          >
            {question.id}
          </LabeledControl>

          {!!question.legacyQId && (
            <LabeledControl
              label="Legacy ID"
            >
              {question.legacyQId}
            </LabeledControl>
          )}

          <LabeledControl
            label="Version"
          >
            {question.version}
          </LabeledControl>
        </FlexRow>
      )}
    </>
  );
};

export default QuestionIdDisplay;