import { ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";

export function formatCLMPartialRequirementItemDto(obj: any): ICLMPartialRequirementItemDto {
  return {
    topicId: Number(obj.topicId),
    topicName: obj.topicName?.toString() ?? "",
    subTopicId: Number(obj.subTopicId),
    subTopicName: obj.subTopicName?.toString() ?? "",
    isInScope: Boolean(obj.isInScope),
    isOptedIn: Boolean(obj.isOptedIn),
  };
}