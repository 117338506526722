import { useContext, useEffect } from "react";
import QISchedulerContext from "../QISchedulerContext";
import { IQISchedulerItemResizeData, qiSchedulerBaseColWidth } from "../qiSchedulerTypes";
import QISchedulerItem from "../scheduler-item/QISchedulerItem";
import QISchedulerCalendarRow from "./QISchedulerCalendarRow";

interface IQISchedulerCalendarAreaProps {
  areaDiv: HTMLDivElement | null,
}

const QISchedulerCalendarArea = ({
  areaDiv,
}: IQISchedulerCalendarAreaProps) => {
  const ctx = useContext(QISchedulerContext);
  const items = ctx?.items;
  const onUpdateItem = ctx?.onUpdateItem;
  const dates = ctx?.dates;

  useEffect(() => {
    if (!areaDiv) {
      return;
    }

    let resizeData: IQISchedulerItemResizeData | undefined;

    const onMouseMove = (e: any) => {
      if (!resizeData) {
        return;
      }

      const itemDiv = resizeData.itemDiv;
      const mouseDownPosition = resizeData.mouseDownPosition;
      const initialItemSize = resizeData.initialItemSize;
      const side = resizeData.side;

      if (side === "right") {
        const newWidth = snapToNearestIncrement(initialItemSize.width + -(mouseDownPosition.x - e.pageX), qiSchedulerBaseColWidth);
        itemDiv.style.width = newWidth + "px";
      } else if (side === "left") {
        const newWidth = snapToNearestIncrement(initialItemSize.width + (mouseDownPosition.x - e.pageX), qiSchedulerBaseColWidth);
        const newLeft = initialItemSize.left - (newWidth - initialItemSize.width);

        itemDiv.style.width = newWidth + "px";
        itemDiv.style.left = newLeft + "px";
      } else if (side === "center") {
        itemDiv.style.left = snapToNearestIncrement(initialItemSize.left - (mouseDownPosition.x - e.pageX), qiSchedulerBaseColWidth) + "px";
      }

      // Update the start and end dates of the modified item.
      const startDateIndex = itemDiv.offsetLeft / qiSchedulerBaseColWidth;
      const newStartDate = dates?.find(x => x.index === startDateIndex);

      if (newStartDate) {
        resizeData.startDate = newStartDate.date;
      }

      const newRightEdgeX = Number(itemDiv.style.left.replace("px", "")) + Number(itemDiv.style.width.replace("px", ""));

      const endDateIndex = newRightEdgeX / qiSchedulerBaseColWidth;
      const newEndDate = dates?.find(x => x.index === endDateIndex);

      if (newEndDate) {
        resizeData.endDate = newEndDate.date;
      }
    };

    const onMouseUp = () => {
      if (!resizeData) {
        return;
      }

      resizeData.itemDiv.style.left = resizeData.initialItemSize.left + "px";
      resizeData.itemDiv.style.width = resizeData.initialItemSize.width + "px";
      resizeData.itemDiv.style.pointerEvents = "";

      if (resizeData.item.startDate.getTime() === resizeData.startDate.getTime()
        && resizeData.item.endDate.getTime() === resizeData.endDate.getTime()) {
        return;
      } else {
        // Fire the event to tell the outside world this item has been updated.
        onUpdateItem?.({
          item: resizeData.item,
          startDate: resizeData.startDate,
          endDate: resizeData.endDate,
        });
      }

      resizeData = undefined;
    };

    const onMouseDown = (e: any) => {
      if (e.buttons !== 1) {
        // Must be left mouse button only.
        return;
      }

      let isDraggable = e.target.classList.contains("draggable");
      let side: "left" | "right" | "center" | undefined;

      if (e.target.classList.contains("grip-left")) {
        side = "left";
      } else if (e.target.classList.contains("grip-right")) {
        side = "right";
      } else if (e.target.classList.contains("grip-top")) {
        side = "center";
      }

      if (!items
        || !side
        || !isDraggable) {
        return;
      }

      const dataItemId = e.target.getAttribute("data-scheduler-item-id");
      const item = items.find(x => x.id.toString() === dataItemId);
      const itemDiv = e.target.parentElement;

      if (!dataItemId
        || !item
        || !itemDiv) {
        return;
      }

      itemDiv.style.pointerEvents = "none";

      resizeData = {
        mouseDownPosition: {
          x: e.pageX,
          y: e.pageY,
        },
        initialItemSize: {
          width: itemDiv.clientWidth,
          left: itemDiv.offsetLeft,
        },
        itemDiv: itemDiv as HTMLDivElement,
        side: side,
        startDate: item.startDate,
        endDate: item.endDate,
        item,
      };
    };

    areaDiv.addEventListener("mousedown", onMouseDown);
    areaDiv.addEventListener("mouseup", onMouseUp);
    areaDiv.addEventListener("mousemove", onMouseMove);

    return () => {
      areaDiv.removeEventListener("mousedown", onMouseDown);
      areaDiv.removeEventListener("mouseup", onMouseUp);
      areaDiv.removeEventListener("mousemove", onMouseMove);
    };
  }, [areaDiv, items, onUpdateItem, dates]);

  if (!ctx) {
    return null;
  }

  const hasAnySelectedItems = ctx.items.some(x => x.isSelected);

  return (
    <>
      <table>
        <tbody>
          {ctx.rows.map(row =>
            <QISchedulerCalendarRow
              key={row.id}
              row={row}
            />
          )}
        </tbody>
      </table>

      {ctx.items.map(item =>
        <QISchedulerItem
          key={item.id}
          item={item}
          isTransparent={hasAnySelectedItems
            && !item.isSelected
          }
        />
      )}
    </>
  );
};

export default QISchedulerCalendarArea;

function snapToNearestIncrement(number: number, increment: number) {
  const snappedValue = Math.round(number / increment) * increment;

  return snappedValue < increment
    ? increment
    : snappedValue;
}