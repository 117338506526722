import { ReactNode, useEffect, useRef } from "react";
import { IColumnItem } from "shared/types/columnTypes";
import "./Table.scoped.scss";
import TableHeader from "./TableHeader";
import TableRows, { ITableRowProps } from "./TableRows";

interface ITableProps<T, K extends keyof T, BeforeRenderOutput = void> {
  /** The data to show in the table body. */
  data: Array<T>,
  /** The name of the property that can uniquely identify an object that will be rendered. */
  keyProp: keyof T,
  /** The column information for the table. */
  columns: Array<IColumnItem<T, K, BeforeRenderOutput>>,
  /** If provided, this function will be called for each table cell that does not have a customRender method. The function must return a <td> element. */
  columnRender?: (columnKey: string, boundObj: T, objProperty: string, beforeRenderData?: BeforeRenderOutput) => JSX.Element,
  /** A function to call when the user scrolls to the bottom of the table. */
  fetchMoreData?: () => void,
  /** Determines if there is more data than what is being shown in the table. If true, fetchMoreData will be called appropriately. */
  hasMore?: boolean,
  /** Defines the theme of the table. Default = 'spacious'. */
  theme?: TableThemes,
  /** Style applied to the wrapper div. */
  style?: React.CSSProperties,
  /** Determines whether the header is visible or not. Default = true. */
  showHeader?: boolean,
  /** Specifies a function to call before a row is rendered. Whatever this returns is then passed to all customRenders for the columns of that row. */
  getBeforeRenderRowData?: (item: T) => BeforeRenderOutput,
  /** A custom className to apply to the <table/> element. */
  className?: string,
  /** A custom props for <tr/> element. */
  rowProps?: ITableRowProps[],
  /** Optional. A function to render a table row in a completely custom way.
   * If this function returns `undefined`, the default row rendering will be used instead.
   * If it returns `null` then no row will be rendered.
   * Otherwise, it must return a <tr key="xxx"></tr> with a unique key. */
  customRowRender?: (item: T) => ReactNode,
}

export enum TableThemes {
  spacious = "spacious",
  compact = "compact",
  ultraCompact = "ultraCompact",
}

const Table = <T, K extends keyof T, BeforeRenderOutput>({
  data,
  keyProp,
  columns,
  columnRender,
  fetchMoreData,
  hasMore,
  theme = TableThemes.spacious,
  style,
  showHeader,
  getBeforeRenderRowData,
  className,
  rowProps,
  customRowRender,
}: ITableProps<T, K, BeforeRenderOutput>): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  function handleScroll() {
    // Some browser have a tiny bug wherein the scrollTop+offsetHeight is off by a 
    // SINGLE pixel which causes the "scroll to bottom to load next page" functionality
    // to fail (by ONE PIXEL!!!). As such, this scrollCheckBonus is added to the 
    // calculation to ensure that all browsers are pushed over the edge when
    // doing the comparison.
    const scrollCheckBonus = 5;

    if (fetchMoreData
      && tableRef?.current
      && wrapperRef?.current
      && (wrapperRef.current.offsetHeight + wrapperRef.current.scrollTop + scrollCheckBonus) >= tableRef?.current.offsetHeight
      && hasMore
      && fetchMoreData
    ) {
      fetchMoreData();
    }
  }

  // Effect to check if the table is shorter than the wrapper
  // and it should load more data.
  useEffect(() => {
    const wrapperOffsetHeight = wrapperRef?.current?.offsetHeight;
    const tableOffsetHeight = tableRef?.current?.offsetHeight;

    if (hasMore
      && fetchMoreData
      && tableOffsetHeight
      && wrapperOffsetHeight
      && tableOffsetHeight < wrapperOffsetHeight) {
      // The table wasn't even tall enough to fill the wrapper.
      // Go get some data.
      fetchMoreData();
    }
  }, [wrapperRef, tableRef, hasMore, fetchMoreData]);

  // Effect to handle window resize which might make the table smaller than the wrapper.
  useEffect(() => {
    function handleResize() {
      const wrapperOffsetHeight = wrapperRef?.current?.offsetHeight;
      const tableOffsetHeight = tableRef?.current?.offsetHeight;

      if (hasMore
        && fetchMoreData
        && tableOffsetHeight
        && wrapperOffsetHeight
        && tableOffsetHeight < wrapperOffsetHeight) {
        // The table wasn't tall enough to fill the wrapper.
        // Go get some data.
        fetchMoreData();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [wrapperRef, tableRef, hasMore, fetchMoreData]);

  return (
    <div
      className="table-wrapper"
      onScroll={handleScroll}
      style={style}
      ref={wrapperRef}
    >
      <table
        className={`table ${theme} ${className}`}
        ref={tableRef}
      >
        {showHeader !== false && (
          <TableHeader
            columns={columns}
          />
        )}
        <TableRows
          data={data}
          keyProp={keyProp}
          columns={columns}
          columnRender={columnRender}
          getBeforeRenderRowData={getBeforeRenderRowData}
          rowProps={rowProps}
          customRowRender={customRowRender}
        />
      </table>
    </div>
  );
};

export default Table;