import { history } from "App";
import AuditsApi from "api/auditing/AuditsApi";
import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import AuditTopicApi from "api/masterdata/AuditTopicApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import SearchAuditApi from "api/search/audit/SearchAuditApi";
import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { isNumber } from "shared/utilities/typeCheck";
import { mapBusinessFunctionToPickerItem, mapBusinessTeamToPickerItem, mapBusinessViewToPickerItem } from "store/common/sagas/pickerSagas";
import { RootState } from "store/store";
import { IAuditGroup, IAuditTopic, IAuditType } from "types/auditMasterDataTypes";
import { AuditorSearchTypes, IAudit, IOwnerGroup } from "types/auditingTypes";
import { ICustomSearchFilterMetaDataType } from "types/customSearchTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, MetaDataTypes } from "types/masterDataTypes";
import { ICustomAuditSearch, ISaveCustomSearchCreateRequest } from "types/searchAuditTypes";
import { addCustomSearchItem, removeCustomSearchItem, setFiltersName } from "../CustomSearchSlice";
import {
  ISearchAuditsState,
  SearchAuditsPickerKeys,
  clearFilters,
  deleteFilters,
  finishDeleteFilters,
  finishLoadAuditFilters,
  finishLoadingAudit,
  finishSavingFilters,
  finishUpdateFilters,
  loadAudit,
  loadAuditFilters, loadPickerItems, saveFilters,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
  updateFilters
} from "./SearchAuditsSlice";

export default function* searchAuditsSagas() {
  yield all([
    loadPickerItemsAsync(),
    loadAuditAsync(),
    watchSaveFilters(),
    loadAuditFilterAsync(),
    watchUpdateFilterAsync(),
    watchDeleteFilterAsync(),
  ]);
}

function* loadAuditAsync() {
  yield takeLatest(loadAudit, function* (action: Action) {
    if (!loadAudit.match(action)) {
      return;
    }

    try {
      // Call the audit API to see if this audit id exists.
      const audit: IAudit = yield call(AuditsApi.getAudit, action.payload);

      yield put(finishLoadingAudit({
        isWorking: false,
      }));

      history.push(formatRoute(UrlRoutes.AuditSummary, { auditId: audit.id.toString() }));
    } catch (err) {
      let msg = getResponseErrorMessage(err);

      if (msg === "The specified Id was not found.") {
        msg = "No Results.";
      }

      yield put(finishLoadingAudit({
        isWorking: false,
        errorMessage: msg,
      }));
    }
  });
}

function* loadPickerItemsAsync() {
  yield takeEvery(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const {
      pickerKey,
      searchValue,
    } = action.payload;

    try {
      switch (pickerKey) {
        case SearchAuditsPickerKeys.auditTypes:
          yield retrieveAndPutPickerData(MasterDataApi.getAuditTypes,
            (item): IPickerItem<IAuditType> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey);
          break;
        case SearchAuditsPickerKeys.auditTopics:
          yield retrieveAndPutTopics();
          break;
        case SearchAuditsPickerKeys.auditGroups:
          yield retrieveAndPutPickerData(() => MasterDataApi.getAuditGroups(),
            (item): IPickerItem<IAuditGroup> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey);
          break;
        case SearchAuditsPickerKeys.ownerGroups:
          yield retrieveAndPutPickerData(OwnerGroupsApi.getOwnerGroups,
            (item): IPickerItem<IOwnerGroup> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            searchValue);
          break;
        case SearchAuditsPickerKeys.countries:
          yield retrieveAndPutPickerData((searchTerm) => MasterDataApi.getCountries(searchTerm),
            (item): IPickerItem<ICountry> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            searchValue);
          break;
        case SearchAuditsPickerKeys.businessViews:
          yield retrieveAndPutPickerData(MasterDataApi.getBusinessViews,
            (item): IPickerItem<IBusinessView> => mapBusinessViewToPickerItem(item),
            pickerKey,
            searchValue);
          break;
        case SearchAuditsPickerKeys.businessTeams:
          // Auto expand global ops.
          const globalOpsId = 84;

          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessTeams(),
            (item): IPickerItem<IBusinessTeam> => {
              const pickerItem = mapBusinessTeamToPickerItem(item);
              pickerItem.isExpanded = item.id === globalOpsId;
              return pickerItem;
            },
            pickerKey,
            searchValue);
          break;
        case SearchAuditsPickerKeys.businessFunctions:
          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessFunctions(),
            (item): IPickerItem<IBusinessFunction> => mapBusinessFunctionToPickerItem(item),
            pickerKey,
            searchValue);
          break;
        case SearchAuditsPickerKeys.leadAuditors:
          yield retrieveAuditors(pickerKey, searchValue || "", AuditorSearchTypes.LeadAuditor);
          break;
        case SearchAuditsPickerKeys.auditors:
          yield retrieveAuditors(pickerKey, searchValue || "", AuditorSearchTypes.Auditor);
          break;
        default:
          throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
      }
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* watchSaveFilters() {
  yield takeLatest(saveFilters, function* (action: Action) {
    if (!saveFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchAuditsState = yield select((store: RootState) => store.searchAudits);

      const metaDataList: ICustomSearchFilterMetaDataType[] = createMetaDataType(state);

      const newId: number = yield call(SearchAuditApi.saveAuditFilters,
        action.payload.filtersName,
        action.payload.searchType,
        createFilter(state, metaDataList));

      yield put(finishSavingFilters({
        isWorking: false,
        data: newId
      }));

      yield put(addCustomSearchItem({
        type: "audit",
        savedFilters: {
          id: newId,
          name: action.payload.filtersName,
        },
      }));

      yield put(setFiltersName({
        name: action.payload.filtersName,
        type: "audit"
      }));

      yield put(showSuccessToast("Filter saved successfully ."));
    } catch (err) {
      let msg = getResponseErrorMessage(err);

      yield put(showErrorToast(msg));

      yield put(finishSavingFilters({
        isWorking: false,
        errorMessage: msg,
      }));
    }
  });
}

function* loadAuditFilterAsync() {
  yield takeLatest(loadAuditFilters, function* (action: Action) {
    if (!loadAuditFilters.match(action)) {
      return;
    }

    try {
      const filters: ICustomAuditSearch = yield call(SearchAuditApi.getAuditFilters, action.payload);

      yield put(clearFilters());

      yield loadAuditType(filters.auditTypes);
      yield loadAuditTopics(filters.auditTopics);
      yield loadContries(filters.countries);
      yield loadBusinessViews(filters.businessViews);
      yield loadBusinessTeams(filters.businessTeams);
      yield loadLeadAuditor(filters.leadAuditors);
      yield loadBusinessFunctions(filters.businessFunctions);
      yield loadOwnerGroups(filters.ownerGroups);
      yield loadAuditGroup(filters.auditGroups);
      yield loadAuditor(filters.auditors);

      yield put(finishLoadAuditFilters({
        isWorking: false,
        data: filters
      }));

      yield put(showSuccessToast("Filter loaded successfully ."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishLoadAuditFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* watchUpdateFilterAsync() {
  yield takeLatest(updateFilters, function* (action: Action) {
    if (!updateFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchAuditsState = yield select((store: RootState) => store.searchAudits);

      const metaDataList: ICustomSearchFilterMetaDataType[] = createMetaDataType(state);

      yield call(SearchAuditApi.updateAuditFilters,
        state.id!,
        createFilter(state, metaDataList));

      yield put(finishUpdateFilters({
        isWorking: false
      }));

      yield put(showSuccessToast("Filter saved successfully ."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishUpdateFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* watchDeleteFilterAsync() {
  yield takeLatest(deleteFilters, function* (action: Action) {
    if (!deleteFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchAuditsState = yield select((store: RootState) => store.searchAudits);

      yield call(SearchAuditApi.deleteAuditFilters,
        state.id!);

      yield put(removeCustomSearchItem({
        type: "audit",
        id: state.id!,
      }));

      yield put(finishDeleteFilters({
        isWorking: false
      }));

      yield put(showSuccessToast("Filter deleted successfully ."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishDeleteFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  pickerKey: string,
  searchValue?: string) {
  const rawItems: T[] = yield call(apiMethod, searchValue);
  const items = rawItems.map(itemMapper);

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* retrieveAndPutTopics() {
  let items: IAuditTopic[] = yield call(AuditTopicApi.searchAuditTopics);

  // Build a tree out of the items.
  let pickerItems = items
    .filter(x => !x.parentId
      && !x.isDeleted)
    .sort((a, b) => a.name < b.name ? -1 : 1)
    .map((topic): IPickerItem<IAuditTopic> => ({
      item: topic,
      key: topic.id,
      disabled: !topic.isSelectable,
      isSelectable: topic.isSelectable,
      children: [],
    }));

  // Map all the children.
  pickerItems.forEach(topicParentLevel => {
    const topicChildren = items
      .filter(x => x.parentId === topicParentLevel.key
        && !x.isDeleted)
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .map((topic): IPickerItem<IAuditTopic> => ({
        item: topic,
        key: topic.id,
        disabled: false,
        children: [],
      }));


    if (!topicParentLevel.isSelectable) {
      const auditTopicGroupNameList = new Map();
      topicChildren.forEach((item: IPickerItem<IAuditTopic>) => {
        auditTopicGroupNameList.set(item.item?.groupName, {
          key: item.item?.parentId,
          groupName: item.item?.groupName,
          auditGroup: item.item?.auditGroup,
        })
      });

      let countKey = 1000;
      auditTopicGroupNameList.forEach(auditTopicGroupName => {
        const parentTopic: IPickerItem<IAuditTopic> | undefined = auditTopicGroupName.groupName !== "" ? {
          item: {
            id: countKey + auditTopicGroupName.key,
            auditGroupId: 2,
            auditGroup: auditTopicGroupName.auditGroup,
            level: 2,
            parentId: countKey,
            name: auditTopicGroupName.groupName,
            groupName: "",
            isSelectable: false,
            sortOrder: countKey,
            isDeleted: false,
            isPlannable: true,
            ownerGroupId: 1,
            ownerGroup: "_",
            scoringSystem: "",
          },
          key: countKey + auditTopicGroupName.key,
          disabled: true,
          children: topicChildren.filter(child => child.item?.groupName === auditTopicGroupName.groupName),
        } : undefined;

        if (parentTopic) {
          topicParentLevel.children?.push(parentTopic);
        } else if (topicParentLevel.children) {
          const items = topicChildren.filter(child => child.item?.groupName === auditTopicGroupName.groupName)
          topicParentLevel.children.unshift(...items)
        }
        else {
          topicParentLevel.children = topicChildren;
        }
        countKey++;
      });

      topicParentLevel?.children?.sort((a, b) => (!a.item || !b.item)
        ? 0 : a.item.name > b.item.name
          ? 1 : (b.item.name > a.item.name
            ? -1
            : 0))
    } else {
      topicParentLevel.children = topicChildren
    }
  });

  yield put(setPickerItems({
    items: pickerItems,
    pickerKey: SearchAuditsPickerKeys.auditTopics,
  }));
}

function* retrieveAuditors(pickerKey: string, searchValue: string, searchType: AuditorSearchTypes) {
  const auditTypes: IPickerState<IAuditType> = yield select((store: RootState) =>
    store.searchAudits.pickerData.auditTypes);

  let auditTypeId = 1;

  if (auditTypes.selectedItems.length) {
    auditTypeId = Number(auditTypes.selectedItems[0].key);
  }

  const rawItems: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
    searchValue,
    auditTypeId,
    searchType);

  const items = rawItems.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* loadAuditType(auditTypes: IAuditType[]) {

  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.auditTypes,
    selectedItems: auditTypes
      .map((auditType): IPickerItem<IAuditGroup> => ({
        key: auditType.id,
        disabled: false,
        text: auditType.name,
      }))
  }));
}

function* loadAuditTopics(auditTopics: IAuditTopic[]) {
  // Build a tree out of the items.
  let pickerItems = auditTopics
    .filter(x => !x.parentId
      && !x.isDeleted)
    .sort((a, b) => a.name < b.name ? -1 : 1)
    .map((topic): IPickerItem<IAuditTopic> => ({
      item: topic,
      key: topic.id,
      disabled: false,
      children: [],
    }));

  // Map all the children.
  pickerItems.forEach(topic => {
    topic.children = auditTopics
      .filter(x => x.parentId === topic.key
        && !x.isDeleted)
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .map((topic): IPickerItem<IAuditTopic> => ({
        item: topic,
        key: topic.id,
        disabled: false,
        children: [],
      }));
  });

  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.auditTopics,
    selectedItems: pickerItems
  }));
};

function* loadContries(countries: ICountry[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.countries,
    selectedItems: countries
      .map((country): IPickerItem<ICountry> => ({
        key: country.id,
        disabled: false,
        text: country.name,
      }))
  }));
}

function* loadBusinessViews(businessViews: IBusinessView[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.businessViews,
    selectedItems: businessViews
      .map((businessView): IPickerItem<IBusinessView> => ({
        key: businessView.id,
        disabled: false,
        item: businessView,
        children: businessView.children.map(child => mapBusinessViewToPickerItem(child)),
      }))
  }));
}

function* loadBusinessTeams(businessTeams: IBusinessTeam[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.businessTeams,
    selectedItems: businessTeams
      .map((businessTeam): IPickerItem<IBusinessTeam> => ({
        key: businessTeam.id,
        disabled: false,
        text: businessTeam.name,
        item: businessTeam,
      }))
  }));
}

function* loadBusinessFunctions(businessFunctions: IBusinessFunction[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.businessFunctions,
    selectedItems: businessFunctions
      .map((func): IPickerItem<IBusinessFunction> => ({
        key: func.id,
        disabled: false,
        item: func,
        children: func.children.map(child => mapBusinessFunctionToPickerItem(child)),
      }))
  }));
}

function* loadAuditGroup(auditGroups: IAuditGroup[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.auditGroups,
    selectedItems: auditGroups
      .map((auditGroup): IPickerItem<IAuditGroup> => ({
        key: auditGroup.id,
        disabled: false,
        text: auditGroup.name,
      }))
  }));
}

function* loadOwnerGroups(ownerGroups: IOwnerGroup[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.ownerGroups,
    selectedItems: ownerGroups
      .map((ownerGroup): IPickerItem<IOwnerGroup> => ({
        key: ownerGroup.id,
        disabled: false,
        text: ownerGroup.name,
      }))
  }));
}

function* loadLeadAuditor(leadAuditors: IAzureADUser[]) {
  const items = leadAuditors.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.leadAuditors,
    selectedItems: items
  }));
}

function* loadAuditor(auditors: IAzureADUser[]) {
  const items = auditors.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setSelectedPickerItems({
    pickerKey: SearchAuditsPickerKeys.auditors,
    selectedItems: items
  }));
}

function createMetaDataType(state: ISearchAuditsState): ICustomSearchFilterMetaDataType[] {
  let metaDataList: ICustomSearchFilterMetaDataType[] = [];

  metaDataList
    .push(
      ...state.pickerData.auditTopics.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.AuditTopic }))
    );
  metaDataList
    .push(
      ...state.facilities
        .map(x => ({ id: x.id, metaDataType: MetaDataTypes.Facility }))
    );
  metaDataList
    .push(
      ...state.pickerData.countries.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.Country }))
    );

  metaDataList
    .push(
      ...state.pickerData.businessViews.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessView }))
    );
  metaDataList
    .push(
      ...state.pickerData.businessTeams.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessTeam }))
    );
  metaDataList
    .push(
      ...state.pickerData.businessFunctions.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessFunction }))
    );
  metaDataList
    .push(
      ...state.mobileSites
        .map(x => x.id)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.MobileSite }))
    );
  metaDataList
    .push(
      ...state.pickerData.auditGroups.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.AuditGroup }))
    );
  metaDataList
    .push(
      ...state.pickerData.ownerGroups.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.OwnerGroup }))
    );

  return metaDataList
}

function createFilter(state: ISearchAuditsState, metaDataList: ICustomSearchFilterMetaDataType[]): ISaveCustomSearchCreateRequest {
  return {
    completionDateStart: state.completionDateStart !== undefined
      ? new Date(state.completionDateStart)
      : undefined,
    completionDateEnd: state.completionDateEnd !== undefined
      ? new Date(state.completionDateEnd)
      : undefined,
    auditTypeId: Number(state.pickerData.auditTypes.selectedItems[0]?.key),
    status: state.status,
    leadAuditorsEmail: state.pickerData.leadAuditors.selectedItems
      .map(x => x.key.toString()),
    auditorsEmail: state.pickerData.auditors.selectedItems
      .map(x => x.key.toString()),
    auditName: state.auditName,
    auditIdentifier: state.auditIdentifier,
    auditPlanYear: state.auditPlanYear,
    startDateStart: state.startDateStart !== undefined
      ? new Date(state.startDateStart)
      : undefined,
    startDateEnd: state.startDateEnd !== undefined
      ? new Date(state.startDateEnd)
      : undefined,
    endDateStart: state.endDateStart !== undefined
      ? new Date(state.endDateStart)
      : undefined,
    endDateEnd: state.endDateEnd !== undefined
      ? new Date(state.endDateEnd)
      : undefined,
    closureDateStart: state.closureDateStart !== undefined
      ? new Date(state.closureDateStart)
      : undefined,
    closureDateEnd: state.closureDateEnd !== undefined
      ? new Date(state.closureDateEnd)
      : undefined,
    complianceAtCompletion: state.complianceAtCompletion,
    complianceAtClosure: state.complianceAtClosure,
    allActionsClosed: state.allActionsClosed,
    metaDataList: metaDataList
  }
}
