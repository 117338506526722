import React from "react";
import Hint from "shared/components/common/hint/Hint";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import FacilityPicker from "shared/components/controls/pickers/FacilityPicker";
import IPMProjectPicker from "shared/components/controls/pickers/IPMProjectPicker";
import MobileSitePicker from "shared/components/controls/pickers/MobileSitePicker";
import SupplierPicker from "shared/components/controls/pickers/SupplierPicker";
import Card from "shared/components/layout/card/Card";
import { closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, loadSuggestedPickerItems, openPicker, setFacilities, setPickerItems, setSelectedIPMProjects, setSelectedMobileSites, setSelectedPickerItems, setSelectedSuppliers } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry } from "types/masterDataTypes";
import "../ManageAuditPage.scoped.scss";
import "./AssociationsCard.scoped.scss";

const GLOBAL_OPS_BUSINESSTEAM_ID = 84;

interface IAssociationsCardProps {
  showStatus: boolean,
  allowEditOnlyLeadAuditor: boolean,
}

const AssociationsCard: React.FC<IAssociationsCardProps> = ({
  showStatus,
  allowEditOnlyLeadAuditor,
}) => {
  const auditGroups = useAppSelector(store => store.manageAudit.pickerData.auditGroups);
  const auditTopics = useAppSelector(store => store.manageAudit.pickerData.auditTopics);
  const businessViews = useAppSelector(store => store.manageAudit.pickerData.businessViews);
  const businessFunctions = useAppSelector(store => store.manageAudit.pickerData.businessFunctions);
  const businessTeams = useAppSelector(store => store.manageAudit.pickerData.businessTeams);
  const countries = useAppSelector(store => store.manageAudit.pickerData.countries);
  const facilities = useAppSelector(store => store.manageAudit.audit.facilities);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const selectedMobileSites = useAppSelector(store => store.manageAudit.audit.mobileSites);
  const selectedIPMProjects = useAppSelector(store => store.manageAudit.audit.ipmProjects);
  const selectedSuppliers = useAppSelector(store => store.manageAudit.audit.suppliers);

  const dispatch = useAppDispatch();

  const hasEditPermission = editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false;

  let boldedBusinessTeamIds: number[] = [];

  let globalOps = businessTeams.items.map(x => x.item).find(x => x?.id === GLOBAL_OPS_BUSINESSTEAM_ID);
  if (globalOps) {
    boldedBusinessTeamIds.push(globalOps.id);
    boldedBusinessTeamIds.push(...globalOps.children.flatMap(child => [
      child.id,
      ...child.children.flatMap(gc => [gc.id, ...gc.children.flatMap(ggc => ggc.id)])
    ]));
  }

  const isTechcatAuditGroupSelected = auditGroups.selectedItems.some(item => item.text?.toLowerCase() === "techcat");
  const isTechcatIntegrated = isTechcatAuditGroupSelected
    && auditTopics.selectedItems.some(item => item.item?.ownerGroup.toLowerCase() === "integrated");

  return (
    <Card
      title="Audit Associations"
      headerElement={showStatus && <>
        <span style={{
          height: "2.4em",
        }}></span></>}
    >
      <div
        className="card-row"
      >
        <LabeledControl
          label="Facility"
          isRequired={!isTechcatIntegrated}
        >
          <FacilityPicker
            onSelectionChange={items => dispatch(setFacilities(items))}
            selectedItems={facilities}
            countryIdsFilter={countries.selectedItems.map(x => Number(x.key))}
            businessTeamIdsFilter={businessTeams.selectedItems.map(x => Number(x.key))}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor || isTechcatIntegrated}
          />
        </LabeledControl>

        <LabeledControl
          label="Country"
        >
          <Picker<ICountry>
            pickerState={countries}
            title="Country"
            openAction={openPicker}
            loadAction={loadPickerItems}
            closeAction={closePicker}
            setItemsAction={setPickerItems}
            setSelectedItemsAction={setSelectedPickerItems}
            preserveItems={true}
            allowMultiSelect={true}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
            showSuggestedItems
            loadSuggestionsAction={loadSuggestedPickerItems}
          />
        </LabeledControl>

        <LabeledControl
          label="Mobile Site"
          isRequired
        >
          <MobileSitePicker
            onSelectionChange={items => dispatch(setSelectedMobileSites(items))}
            selectedItems={selectedMobileSites}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            selectionMode="Single"
            showSuggestions={true}
          />
        </LabeledControl>
      </div>
      <div
        className="card-row"
      >
        <LabeledControl
          label="Business Team (Basin Hierarchy)"
        >
          <Picker<IBusinessTeam>
            pickerState={businessTeams}
            title="Business Team (Basin Hierarchy)"
            renderListItem={(item) => (
              <span className={boldedBusinessTeamIds.indexOf(item.id) > -1 ? "highlighted-business-team" : ""}>
                {item.code} - {item.name}
              </span>
            )}
            renderSelectedItem={(item) => item.code}
            openAction={openPicker}
            loadAction={loadPickerItems}
            closeAction={closePicker}
            setItemsAction={setPickerItems}
            setSelectedItemsAction={setSelectedPickerItems}
            searchOptions={{
              filterItem: (item, searchTerm) => {
                if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
                  return true;
                }

                return false;
              },
            }}
            preserveItems={true}
            displayMode="tree"
            allowMultiSelect={true}
            expandItemsAction={expandPickerItem}
            collapseItemsAction={collapsePickerItem}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
            showSuggestedItems
            loadSuggestionsAction={loadSuggestedPickerItems}
          />
        </LabeledControl>

        <LabeledControl
          label="Business View (Division)"
        >
          <Picker<IBusinessView>
            pickerState={businessViews}
            title="Business View (Division)"
            renderListItem={(item) => `${item.code} - ${item.name}`}
            renderSelectedItem={(item) => item.code}
            openAction={openPicker}
            loadAction={loadPickerItems}
            closeAction={closePicker}
            setItemsAction={setPickerItems}
            setSelectedItemsAction={setSelectedPickerItems}
            searchOptions={{
              filterItem: (item, searchTerm) => {
                if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
                  return true;
                }

                return false;
              },
            }}
            preserveItems={true}
            displayMode="tree"
            allowMultiSelect={true}
            expandItemsAction={expandPickerItem}
            collapseItemsAction={collapsePickerItem}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            itemSorter={(a, b) => (a.item?.code || a.text || "").localeCompare(b.item?.code || b.text || "")}
            showSuggestedItems
            loadSuggestionsAction={loadSuggestedPickerItems}
          />
        </LabeledControl>
        <LabeledControl
          label="Business Function (Function)"
        >
          <Picker<IBusinessFunction>
            pickerState={businessFunctions}
            title="Business Function (Function)"
            renderListItem={(item) => `${item.code} - ${item.name}`}
            renderSelectedItem={(item) => item.code}
            openAction={openPicker}
            loadAction={loadPickerItems}
            closeAction={closePicker}
            setItemsAction={setPickerItems}
            setSelectedItemsAction={setSelectedPickerItems}
            searchOptions={{
              filterItem: (item, searchTerm) => {
                if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
                  return true;
                }

                return false;
              },
            }}
            preserveItems={true}
            displayMode="tree"
            allowMultiSelect={true}
            expandItemsAction={expandPickerItem}
            collapseItemsAction={collapsePickerItem}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
            showSuggestedItems
            loadSuggestionsAction={loadSuggestedPickerItems}
          />
        </LabeledControl>

      </div>

      <div
        className="card-row"
      >
        <LabeledControl
          label="IPM Asset"
          isRequired
        >
          <IPMProjectPicker
            onSelectionChange={items => dispatch(setSelectedIPMProjects(
              // Keep the selected items that are not assets, then add whichever assets were
              // selected in the picker.
              selectedIPMProjects.filter(x => x.type !== "IPMAsset").concat(items)
            ))}
            selectedItems={selectedIPMProjects.filter(x => x.type === "IPMAsset")}
            ipmProjectType="IPMAsset"
            selectMode="Multiple"
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
          />
        </LabeledControl>

        <LabeledControl
          label="IPM Project"
          isRequired
        >
          <IPMProjectPicker
            onSelectionChange={items => dispatch(setSelectedIPMProjects(
              // Keep the selected items that are not projects, then add whichever projects
              // were selected in the picker.
              selectedIPMProjects.filter(x => x.type !== "IPMProject").concat(items)
            ))}
            selectedItems={selectedIPMProjects.filter(x => x.type === "IPMProject")}
            ipmProjectType="IPMProject"
            selectMode="Multiple"
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
          />
        </LabeledControl>

        <LabeledControl
          label="Supplier"
        >
          <SupplierPicker
            onSelectionChange={suppliers => dispatch(setSelectedSuppliers(suppliers))}
            selectedItems={selectedSuppliers}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
          />
        </LabeledControl>
      </div>

      <div
        className="card-row"
      >
        <Hint className="span-3 info">
          * At least one of the following is required: Facility, IPM Asset, IPM Project, Mobile Site.
        </Hint>
      </div>
    </Card>
  );
};

export default AssociationsCard;
