import { uniqBy } from "lodash";
import { IAuditSchedulerItemData, IQISchedulerItem, IQISchedulerRow } from "shared/components/controls/scheduler/qiSchedulerTypes";
import { userToString } from "shared/utilities/userUtilities";
import IAuditPlanCalendarBasinYAxisData from "types/audit-plan-calendar/IAuditPlanCalendarBasinYAxisData";
import IAuditPlanCalendarLeadAuditor from "types/audit-plan-calendar/IAuditPlanCalendarLeadAuditor";
import { EditRestriction } from "types/auditPageAuthTypes";

export function convertBasinYAxisToSchedulerRows(yAxisData: IAuditPlanCalendarBasinYAxisData,
  collapsedRowIds: (string | number)[]): IQISchedulerRow[] {
  const rows: IQISchedulerRow[] = [];

  yAxisData.basins.forEach(basin => {
    const basinRow = yAxisItemToRow(basin, collapsedRowIds, "businessTeam");
    rows.push(basinRow);

    yAxisData.geoUnits.filter(gu => gu.basinId === basin.id).forEach(gu => {
      const guRow = yAxisItemToRow(gu, collapsedRowIds, "businessTeam");
      basinRow.children?.push(guRow);

      yAxisData.subGeoUnits.filter(sgu => sgu.geoUnitId === gu.id).forEach(sgu => {
        const sguRow = yAxisItemToRow(sgu, collapsedRowIds, "businessTeam");
        guRow.children?.push(sguRow);

        yAxisData.parentDimensions.filter(par => par.subGeoUnitId === sgu.id).forEach(par => {
          const parRow = yAxisItemToRow(par, collapsedRowIds, par.type, false);
          sguRow.children?.push(parRow);

          yAxisData.childDimensions.filter(chi => chi.parentDimensionId === par.id && chi.parentDimensionType === par.type).forEach(chi => {
            const chiRow = yAxisItemToRow(chi, collapsedRowIds, chi.type, false, parRow);
            parRow.children?.push(chiRow);
          });
        });
      });
    });
  });

  return rows;
}

export function convertLeadAuditorYAxisToSchedulerRows(leaderData: IAuditPlanCalendarLeadAuditor[],
  collapsedRowIds: (string | number)[]): IQISchedulerRow[] {
  return leaderData.map((item): IQISchedulerRow => ({
    id: `LeadAuditor_${item.leadAuditorEmail === ""
      && item.leadAuditorName === "Unassigned"
      ? "Unassigned"
      : item.leadAuditorEmail
      }`,
    text: userToString({
      email: item.leadAuditorEmail,
      name: item.leadAuditorName,
    }),
    isExpanded: !collapsedRowIds.includes(`LeadAuditor_${item.leadAuditorEmail}`),
  }));
}

function yAxisItemToRow(item: { id: string | number, name: string, },
  collapsedRowIds: (string | number)[],
  type: string,
  isDisabled?: boolean,
  parentRow?: IQISchedulerRow,): IQISchedulerRow {
  return {
    id: parentRow
      ? `${parentRow.id}_${type}_${item.id}`
      : `${type}_${item.id}`,
    text: item.name,
    isDisabled: isDisabled,
    children: [],
    isExpanded: !collapsedRowIds.includes(`${type}_${item.id}`),
  };
}

export function convertAuditPlansToSchedulerItems(plans: IAuditSchedulerItemData[],
  selectedPlanIds: number[],
  calendarMode: "basin" | "leader",
  editRestriction: EditRestriction,
  currentUserEmail: string): IQISchedulerItem<IAuditSchedulerItemData>[] {
  const items = plans
    .map((x): IQISchedulerItem<IAuditSchedulerItemData> => {
      return ({
        id: x.auditId
          ? `Audit_${x.auditId}`
          : `AuditPlan_${x.planId}`,
        startDate: new Date(x.startTime),
        endDate: new Date(x.endTime),
        rowId: calendarMode === "basin"
          ? (
            x.childDimensionId
              ? `${x.parentDimensionType}_${x.parentDimensionId}_${x.childDimensionType}_${x.childDimensionId}`
              : `${x.parentDimensionType}_${x.parentDimensionId}`
          )
          : `LeadAuditor_${x.leadAuditorEmail ?? "Unassigned"}`,
        isDraggable: false,
        isResizable: false,
        itemData: x,
        isSelectable: isItemSelectable(x, plans, selectedPlanIds),
        isSelected: selectedPlanIds.includes(x.planId),
      });
    });

  return uniqBy(items, x => x.id);
}

export function isItemSelectable(item: IAuditSchedulerItemData,
  items: IAuditSchedulerItemData[],
  selectedPlanIds: number[]) {
  if (item.type === "Audit") {
    // Audits are not selectable.
    return false;
  }

  if (!selectedPlanIds.length) {
    // If nothing else is selected, then this item is selectable.
    return true;
  }

  const firstSelectedItem = items.find(x => selectedPlanIds.includes(x.planId));

  if (!firstSelectedItem) {
    // Something's wrong. There is a selected plan but it's not found in the list.
    return false;
  }

  if (firstSelectedItem.planYear !== item.planYear) {
    return false;
  }

  if (firstSelectedItem.auditTypeId !== item.auditTypeId) {
    return false;
  }

  return true;
};