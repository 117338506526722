import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import { IActiveUserProfile, IAzureADUser } from "shared/types/userProfileTypes";
import { ActionItemLinkTypes, ActionItemStatuses, IActionItem } from "types/actionItemTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import { Roles } from "types/authTypes";
import { compareInsensitive } from "./stringUtilities";

interface IActionItemPermissionProps {
  isValidated: boolean,
  isLocked: boolean,
  assignedTo?: {
    name: string,
    email: string,
  },
  editors: {
    name: string,
    email: string,
  }[],
  createdBy: {
    name: string,
    email: string,
  },
}

interface IActionItemUnlinkDeletePermissionProps {
  status: ActionItemStatuses,
  isLocked: boolean,
  assignedTo?: {
    name: string,
    email: string,
  },
  editors: {
    name: string,
    email: string,
  }[],
  createdBy: {
    name: string,
    email: string,
  },
}

export function canUnlinkDeleteActionItem(actionItem: IActionItemUnlinkDeletePermissionProps,
  currUserEmail: string): boolean {
  // As long as the action item is not yet closed,
  // any of the people in the editors list as well as the
  // creator of the action item can unlink/delete it.
  return actionItem.status === ActionItemStatuses.Open
    && !actionItem.isLocked
    && (compareInsensitive(currUserEmail, actionItem.createdBy.email)
      || actionItem.editors.some(editor => compareInsensitive(currUserEmail, editor.email)));
}

/** Checks the action item to see if it is in an editable state. If it is, the current user
 * should also be checked to ensure they have permissions to edit it.
 */
export function isActionInEditableState(actionItem: IActionItemPermissionProps) {
  return !actionItem.isValidated
    && !actionItem.isLocked;
}

export function canEditActionItem(actionItem: IActionItemPermissionProps,
  currentUser: IAzureADUser,
  activeUserProfile: IActiveUserProfile): boolean {
  // As long as the action item is not yet validated,
  // any of the people in the editors list as well as the
  // creator of the action item as well as System Admins can edit it.
  return isActionInEditableState(actionItem)
    && (activeUserProfile.roleName === Roles.SystemAdmin
      || compareInsensitive(currentUser.email, actionItem.createdBy.email)
      || actionItem.editors.some(editor => compareInsensitive(currentUser.email, editor.email)));
}

/** Checks if the current user can add a comment to the action item. */
export function canAddComment(actionItem: IActionItemPermissionProps,
  currentUser: IAzureADUser,
  activeUserProfile: IActiveUserProfile): boolean {
  // If the user can edit the action item, they can also add a comment.
  // Or, if they are the assignee, they can also add a comment.
  return isActionInEditableState(actionItem)
    && (canEditActionItem(actionItem, currentUser, activeUserProfile)
      || compareInsensitive(currentUser.email, actionItem.assignedTo?.email));
}

/** Checks if the current user can close and manage evidence of the action item. */
export function canCloseAndHandleEvidenceActionItem(actionItem: IActionItemPermissionProps,
  currentUser: IAzureADUser,
  activeUserProfile: IActiveUserProfile): boolean {
  // As long as the action item is not yet validated,
  // any of the people allowed to edit can close the action item as well as
  // the person who it is assigned to and System Admins.
  return isActionInEditableState(actionItem)
    && (activeUserProfile.roleName === Roles.SystemAdmin
      || canEditActionItem(actionItem, currentUser, activeUserProfile)
      || compareInsensitive(currentUser.email, actionItem?.assignedTo?.email));
}

export function findActionItemsByParentItems(actionItems: IActionItem[],
  parentItems: {
    linkId: number,
    linkType: ActionItemLinkTypes,
  }[]): IActionItem[] {
  return actionItems
    .filter(a => a.links
      .some(l =>
        parentItems.some(p => p.linkId.toString() === l.linkId.toString()
          && p.linkType === l.type)));
}

/**
 * If a question has a NO response
 * @param question The question to check for.
 * @param auditStatus The audit's current status.
 * @param actionItems The action items list to search.
 */
export function questionHasRequiredActionItem(question: IAuditQuestion,
  auditStatus: AuditStatuses,
  actionItems: IActionItem[],
  answerCodesThatNeedRWP: string[]): boolean | undefined {
  if (!answerCodesThatNeedRWP.length) {
    // There are no answer codes that require RWP so consider this question
    // as not missing its RWP.
    return true;
  }

  if (auditStatus === AuditStatuses.InProgress) {
    // The user is attempting to Complete the audit.
    if (question.scoringSystem === AuditScoringSystems.QMS) {
      // Some QMS answers are marked "requiresRWP" but they are only required
      // during Closing.
      return true;
    }
  }

  const currResponse = getResponseFromQuestion(question, auditStatus);

  // Question is not answered no. Action item is not required.
  if (!answerCodesThatNeedRWP.some(x => x === currResponse?.answer)) {
    return undefined;
  }

  // Search the action items for the items linked to this question.
  // And check to ensure a Corrective or Preventative item exists.
  const questionActionItems = findActionItemsByParentItems(actionItems, [{
    linkId: question.auditQuestionId,
    linkType: ActionItemLinkTypes.AuditQuestion,
  }]);
  return questionActionItems.some(x => x.type?.name === "Corrective"
    || x.type?.name === "Preventative");
}

