import React from "react";
import Divider from "shared/components/common/divider/Divider";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";
import "./CLMSubTopicRequirements.scoped.scss";

interface ICLMSubTopicRequirementsProps {
  requirements: ICLMPartialRequirementItemDto[],
  selectedTopicId: number,
  onSubTopicOptInChanged(subTopicId: number, isOptedIn: boolean): void,
}

const CLMSubTopicRequirements: React.FC<ICLMSubTopicRequirementsProps> = ({
  requirements,
  selectedTopicId,
  onSubTopicOptInChanged: onOptInChanged,
}: ICLMSubTopicRequirementsProps) => {
  const subTopics = requirements
    .filter(x => x.topicId === selectedTopicId);

  return (
    <>
      {subTopics.map(subTopicData => (
        <div
          key={subTopicData.subTopicId}
          className="subtopic-row"
        >
          <FlexCol>
            <Divider />

            <FlexRow>
              <span
                className="name"
              >
                {subTopicData.subTopicName}
              </span>
              <label
                className="entry"
              >
                <input
                  type="checkbox"
                  checked={subTopicData.isInScope
                    || subTopicData.isOptedIn}
                  disabled={subTopicData.isInScope}
                  onChange={e => onOptInChanged(subTopicData.subTopicId, e.currentTarget.checked)}
                />
              </label>
            </FlexRow>
          </FlexCol>
        </div>
      ))}
    </>
  );
};

export default CLMSubTopicRequirements;