import { IOperation } from "../shared/types/operationTypes";

export enum PageAuthRequestType {
  audit = "Audit",
  nonAudit = "NonAudit",
}

export interface IAuditPageAuthRequest {
  auditId: number,
  pageName: AuditPages,
  pageVisibleWhileLoading: boolean,
  type: PageAuthRequestType,
  objectType?: 'Question',
  objectId?: number,
}

export interface INonAuditPageAuthRequest {
  pageName: AuditPages,
  pageVisibleWhileLoading: boolean,
  objectType?: 'Question',
  objectId?: number,
}

export interface IAuditPageAuth {
  auditId: number,
  pageName: AuditPages,
  editRestriction: EditRestriction,
  viewRestriction: ViewRestriction,
}

export interface IAuditPageAuthState {
  auditPageAuth: IAuditPageAuth,
  loadOperation?: IOperation<IAuditPageAuth>,
  /** If false, the load operation unloads any current page and waits until the request finishes to then mount the page again. Otherwise, a modal spinner is shown instead. */
  pageVisibleWhileLoading: boolean,
}

export interface IMetaData {
  metaDataId?: number,
  metaDataType?: string,
  metaDataCode?: string,
  metaDataName?: string
}

export enum AuditPages {
  None = "",
  AuditDetailsPage = "AuditDetailsPage",
  AuditEvidencesPage = "AuditEvidencesPage",
  AuditQuestionsPage = "AuditQuestionsPage",
  AuditReportsPage = "AuditReportsPage",
  AuditFindingsPage = "AuditFindingsPage",
  AuditRWPPage = "AuditRWPPage",
  EditAuditPage = "EditAuditPage",
  ResultsNonConformancesPage = "ResultsNonConformancesPage",
  CreateAuditPage = "CreateAuditPage",
  SearchForAudits = "SearchForAudits",
  AdminFacilityAttribute = "AdminFacilityAttribute",
  AdminPlanApproval = "AdminPlanApproval",
  FacilityAttributes = "FacilityAttributes",
  AuditCalendarPage = "AuditCalendarPage",
  QuestionLibrary = "QuestionLibrary",
  PlanApprovalPage = "PlanApprovalPage",
  EditQuestion = "EditQuestion",
  NewQuestion = "NewQuestion",
  BulkUploadQuestions = "BulkUploadQuestions",
  UserManagement = "UserManagement",
  AuditTemplates = "AuditTemplates",
  AuditTopicRearrange = "AuditTopicRearrange",
  RevertAuditStatus = "RevertAuditStatus",
  EditQuestionAdditionalAssociation = "EditQuestionAdditionalAssociation",
}

export enum EditRestriction {
  EditAll = "EditAll",
  EditOwn = "EditOwn",
  EditNone = "EditNone",
  EditAllRestrict = "EditAllRestrict",
}

export enum ViewRestriction {
  ViewAll = "ViewAll",
  ViewOwn = "ViewOwn",
  ViewNone = "ViewNone",
}
