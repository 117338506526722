import React from "react";
import Card from "shared/components/layout/card/Card";
import { setIncludeAPIQ2 } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import "../ManageAuditPage.scoped.scss";

interface IIncludeAPICardProps {
  allowEditOnlyLeadAuditor: boolean,
}

const IncludeAPICard: React.FC<IIncludeAPICardProps> = ({
  allowEditOnlyLeadAuditor,
}) => {
  const includeAPIQ2 = useAppSelector(store => store.manageAudit.audit.includeAllAPIQ2Questions);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false;
    
  const dispatch = useAppDispatch();

  return (
    <div 
     className="menuDisable">
      <Card>
        <div>
          <label>
            <input
              type="checkbox"
              checked={includeAPIQ2}
              onChange={(e) => dispatch(setIncludeAPIQ2(e.target.checked))}
              disabled={!hasEditPermission || allowEditOnlyLeadAuditor}
            />

            Include all API Q2 Questions
          </label>
        </div>
      </Card>
    </div>
  );
}

export default IncludeAPICard;