import { useMsal } from "@azure/msal-react";
import StorageApi from "api/storage/StorageApi";
import { msalPublicClient } from "auth/auth-config";
import config from "config";
import React, { useEffect } from "react";
import Login from "shared/components/auth/Login";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import UserSentimentSurvey from "shared/components/user-sentiment-survey/UserSentimentSurvey";
import { loadUserProfile, setCurrentUser } from "shared/store/auth/AuthSlice";
import { restoreCardStatesFromCache } from "store/card-states/CardStatesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "shared/components/GlobalStyles.scss";

const AppStartup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authLoadOperation = useAppSelector(store => store.auth.loadOperation);
  const userProfile = useAppSelector(store => store.auth.userProfile);
  const currentUser = useAppSelector(store => store.auth.currentUser);

  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const user = accounts[0];
  const username = user.username || "";
  const name = (user as any).name || "";

  useEffect(() => {
    dispatch(setCurrentUser({
      email: username,
      name: name,
    }));
  }, [dispatch, username, name])

  useEffect(() => {
    dispatch(loadUserProfile({}));
    dispatch(restoreCardStatesFromCache());
    StorageApi.clearExpiredItems();
  }, [dispatch]);

  if (authLoadOperation?.isWorking) {
    return (
      <Login
        message={(
          <>
            Retrieving user profile information...
            <Spinner />
          </>
        )}
      />
    );
  } else if (authLoadOperation?.errorMessage) {
    return (
      <Login
        error
        message={(
          <Banner
            type={BannerType.error}
          >
            We failed to load your user profile.<br />
            <br />
            {authLoadOperation.errorMessage}
          </Banner>
        )}
      />
    );
  } else if (userProfile.profiles.length === 0) {
    return (
      <Login
        error
        message={(
          <Banner
            type={BannerType.error}
          >
            You have no user profiles assigned to you. Please contact an administrator if you believe this to be in error.
          </Banner>
        )}
      />
    );
  }

  return (
    <>
      {children}
      <UserSentimentSurvey
        userAlias={currentUser.email.substring(0, currentUser.email.indexOf("@"))}
        slbTenantId={config.authConfig.tenantId}
        msalPublicClient={msalPublicClient}
        checkForFeedbackUrl={config.userSentimentSurvey.checkFeedbackUrl}
        surveyScope={config.userSentimentSurvey.scope}
        surveySecretKey={config.userSentimentSurvey.secretKey}
      />
    </>
  );
};

export default AppStartup;