import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { userToString } from "shared/utilities/userUtilities";
import { ActionItemLinkTypes, ActionItemStatuses, IActionItem } from "types/actionItemTypes";
import { AuditStatuses, AuditStepBuckets, AuditSteps, AuditStepStage, IAuditInfo, IAuditor, IAuditQuestion, IAuditStep } from "types/auditingTypes";
import { IAnswer, IAuditTopic } from "types/auditMasterDataTypes";

interface ISortableQuestion {
  subTopics: IAuditTopic[],
  questionNumber: number,
  topicId: number,
}

interface ISortableQuestionWithAuditTopics {
  questionNumber: number,
  auditTopics: IAuditTopic[],
}

export const sortQuestionsBySTThenNumber = (a: ISortableQuestion | ISortableQuestionWithAuditTopics,
  b: ISortableQuestion | ISortableQuestionWithAuditTopics) => {

  let aST = "";
  let bST = "";

  if ("auditTopics" in a) {
    aST = a.auditTopics.find(x => x.level === 2)?.name || "";
  } else if ("subTopics" in a) {
    aST = a.subTopics?.[0]?.name;
  }

  if ("auditTopics" in b) {
    bST = b.auditTopics.find(x => x.level === 2)?.name || "";
  } else if ("subTopics" in b) {
    bST = b.subTopics?.[0]?.name;
  }

  if (aST < bST) {
    return -1;
  } else if (aST > bST) {
    return 1;
  }

  // Subtopics are the same, sort by question id.
  return a.questionNumber < b.questionNumber
    ? -1
    : 1;
};

export const sortQuestionsByTopicThenSubTopicThenNumber = (a: ISortableQuestion | ISortableQuestionWithAuditTopics,
  b: ISortableQuestion | ISortableQuestionWithAuditTopics,
  auditTopics: IAuditTopic[]) => {
  let aT = "";
  let bT = "";

  if ("auditTopics" in a) {
    aT = a.auditTopics.find(x => x.level === 1)?.name || "";
  } else {
    aT = auditTopics.find(x => x.id === a.topicId)?.name || "";
  }

  if ("auditTopics" in b) {
    bT = b.auditTopics.find(x => x.level === 1)?.name || "";
  } else {
    bT = auditTopics.find(x => x.id === b.topicId)?.name || "";
  }

  if (aT < bT) {
    return -1;
  } else if (aT > bT) {
    return 1;
  }

  let aST = "";
  let bST = "";

  if ("auditTopics" in a) {
    aST = a.auditTopics.find(x => x.level === 2)?.name || "";
  } else if ("subTopics" in a) {
    aST = a.subTopics?.[0]?.name;
  }

  if ("auditTopics" in b) {
    bST = b.auditTopics.find(x => x.level === 2)?.name || "";
  } else if ("subTopics" in b) {
    bST = b.subTopics?.[0]?.name;
  }

  if (aST < bST) {
    return -1;
  } else if (aST > bST) {
    return 1;
  }

  // Subtopics are the same, sort by question id.
  return a.questionNumber < b.questionNumber
    ? -1
    : 1;
};

export function countAuditors(topicAuditors: (IAuditor | undefined)[]): {
  comboText: string,
  hasUnassigned: boolean,
} {
  let countUndefined = 0;
  let auditorList: IAuditor[] = [];

  topicAuditors.forEach(x => {
    if (x === undefined) {
      countUndefined++;
    } else if (!auditorList.find(y => y.email === x.email)) {
      auditorList.push(x);
    }
  });

  if (topicAuditors.length === countUndefined) {
    return {
      comboText: "Unassigned",
      hasUnassigned: true,
    };
  } else if (auditorList?.length > 0) {

    if (auditorList.length === 1) {
      return {
        comboText: userToString(auditorList[0]),
        hasUnassigned: countUndefined > 0,
      };
    }

    return {
      comboText: `${auditorList.length} auditors${countUndefined > 0
        ? ` +${countUndefined} unassg.`
        : ""}`,
      hasUnassigned: countUndefined > 0,
    };
  } else {
    return {
      comboText: "",
      hasUnassigned: false,
    };
  }
}

/**
 * Checks if the question is missing an answer for the current audit status or if that answer is undefined.
 * @param question The question whose responses to check.
 * @param auditStatus The current status of the audit.
 */
export function isQuestionMissingAnswer(question: IAuditQuestion, auditStatus: AuditStatuses) {
  // The question does not have an answer for this status.
  // Or the answer is undefined.
  return !question.responses.some(x => x.auditStatus === auditStatus)
    || question.responses.some(x => x.auditStatus === auditStatus && x.answer === undefined);
}

/**
 * Searches a question's responses list to find the one whose status matches the supplied audit status.
 * @param question The question whose responses to search.
 * @param auditStatus The audit status whose response to find.
 * @returns The AuditQuestionResponse object of the question.
 */
export function getResponseFromQuestion(question: IAuditQuestion, auditStatus: AuditStatuses) {
  return question.responses.find(x => x.auditStatus === auditStatus);
}

export function getAuditSteps(audit: IAuditInfo,
  questions: IAuditQuestion[],
  actionItems: IActionItem[],
  answers: IAnswer[]): { steps: IAuditStep[], currentStep: IAuditStep | "DONE"; } {
  const nonConformantAnswerCodes = answers
    .filter(x => x.canBeUpdated)
    .map(x => x.code);

  const questionsAnsweredNo = questions
    .filter(x => nonConformantAnswerCodes.some(code => code === getResponseFromQuestion(x, AuditStatuses.InProgress)?.answer));

  // Set up an array of all the steps as "ToDo".
  const steps: IAuditStep[] = [
    // EXECUTION PLANNING
    {
      bucket: AuditStepBuckets.ExecutionPlanning,
      number: 1,
      text: AuditSteps.VerifyAuditDetailsAndSchedule,
      // The audit has been started or it's been updated.
      stage: audit.status !== AuditStatuses.Planned
        || audit.createdOnTime !== audit.modifiedOnTime
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.EditAudit, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.ExecutionPlanning,
      number: 2,
      text: AuditSteps.AssignAuditors,
      // 1 or more auditors are assigned or the audit has been started.
      stage: audit.status !== AuditStatuses.Planned
        || !questions.some(x => !x.auditorEmail)
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditSummary, { auditId: audit.id.toString() }),
    },

    // EXECUTING AUDIT
    {
      bucket: AuditStepBuckets.ExecutingAudit,
      number: 3,
      text: AuditSteps.OpeningMeeting,
      isOptional: true,
      // Check if the audit contains an evidence with -OpeningMeeting- in the name.
      stage: audit.evidence.some(x => x.parent === "Audit"
        && (x.filename || "").indexOf("-OpeningMeeting-") > -1)
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditReport, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.ExecutingAudit,
      number: 4,
      text: AuditSteps.StartAudit,
      // Check that the audit is no longer planned.
      stage: audit.status !== AuditStatuses.Planned
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditSummary, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.ExecutingAudit,
      number: 5,
      text: AuditSteps.AnswerQuestions,
      // Check if all questions have an answer for the current audit status.
      stage: !questions.some(x => getResponseFromQuestion(x, audit.status) === undefined)
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditExecuteAll, { auditId: audit.id.toString() }),
    },

    // CREATE RWP.
    {
      bucket: AuditStepBuckets.CreateRWP,
      number: 6,
      text: AuditSteps.CreateRWP,
      isOptional: !questionsAnsweredNo.length,
      stage: questionsAnsweredNo.length > 0
        // Ensure every "NO" question has at least 1 Corrective or Preventative
        // action item linked to it. 
        && questionsAnsweredNo.filter(q =>
          !findActionItemsByParentItems(actionItems, [{
            linkType: ActionItemLinkTypes.AuditQuestion,
            linkId: q.auditQuestionId,
          }])
            .some(x => x.type?.name === "Corrective"
              || x.type?.name === "Preventative")).length === 0
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditResultNonConformance, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.CreateRWP,
      number: 7,
      text: AuditSteps.ClosingMeeting,
      isOptional: true,
      // Check if the audit contains an evidence with -ClosingMeeting- in the name.
      stage: audit.evidence.some(x => x.parent === "Audit"
        && (x.filename || "").indexOf("-ClosingMeeting-") > -1)
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditReport, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.CreateRWP,
      number: 8,
      text: AuditSteps.CompleteAudit,
      // Check if the audit has been completed.
      stage: audit.status === AuditStatuses.Completed
        || audit.status === AuditStatuses.Closed
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditSummary, { auditId: audit.id.toString() }),
    },

    // CLOSE AUDIT
    {
      bucket: AuditStepBuckets.CloseAudit,
      number: 9,
      text: AuditSteps.CloseActions,
      isOptional: !questionsAnsweredNo.length
        && !actionItems.length,
      // If there are no action items that are still open, this step is completed.
      stage: actionItems.length > 0
        && !actionItems.some(x => x.status === ActionItemStatuses.Open)
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditRWP, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.CloseAudit,
      number: 10,
      text: AuditSteps.ValidateActions,
      isOptional: false,
      // If there are no Preventative/Corrective action items that are not verified,
      // this step is completed.
      stage: actionItems.length > 0
        && !actionItems.some(x => !x.isValidated
          && (x.type?.name === "Preventative" || x.type?.name === "Corrective"))
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditResultNonConformance, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.CloseAudit,
      number: 11,
      text: AuditSteps.UpdateQuestionsResponses,
      isOptional: false,
      // If there are any questions whose InProgress answer was "NO" and whose new answer is not "YES",
      // this step is not completed.
      stage: getUpdateQuestionsResponseStage(questions, audit, nonConformantAnswerCodes),
      url: formatRoute(UrlRoutes.AuditResultNonConformance, { auditId: audit.id.toString() }),
    }, {
      bucket: AuditStepBuckets.CloseAudit,
      number: 12,
      text: AuditSteps.CloseAudit,
      // Check if the audit is completed.
      stage: audit.status === AuditStatuses.Closed
        ? AuditStepStage.done
        : AuditStepStage.toDo,
      url: formatRoute(UrlRoutes.AuditSummary, { auditId: audit.id.toString() }),
    }];

  let currentStep: IAuditStep | "DONE" = "DONE";

  // First incomplete step that has every mandatory step before it completed.
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].stage === AuditStepStage.toDo) {
      // Check if this step should be the CURRENT STEP.
      const allPrevMandatoryAreComplete = !steps.some((x, ix) => ix < i
        && !x.isOptional
        && x.stage === AuditStepStage.toDo);

      // If any of the previous mandatory steps are not complete, this step cannot
      // be the current step.
      if (!allPrevMandatoryAreComplete) {
        break;
      }

      // If this step has all previous mandatory steps complete,
      // and this step is not optional, then it is the current step.
      if (!steps[i].isOptional) {
        currentStep = steps[i];
        break;
      }

      // Since this step is optional, it can only be the current step if
      // the next mandatory step is not completed.
      const nextMandatoryStep = steps.find((x, ix) => ix > i
        && !x.isOptional);

      if (!nextMandatoryStep
        || nextMandatoryStep.stage === AuditStepStage.toDo) {
        // If there are no mandatory steps after this one so this step
        // must be the current step.
        // Alteratively, if the next mandatory step is incomplete,
        // this step is the current one.
        currentStep = steps[i];
        break;
      }
    }
  }

  if (currentStep !== "DONE") {
    currentStep.stage = AuditStepStage.nextUp;
  }

  return {
    steps: steps,
    currentStep: currentStep || "DONE",
  };
}

function getUpdateQuestionsResponseStage(questions: IAuditQuestion[],
  audit: IAuditInfo,
  nonConformantAnswerCodes: string[]): AuditStepStage {
  // If there are any remaining unanswered questions, this step is still ToDo.
  if (questions.some(x => !getResponseFromQuestion(x, audit.status)?.answer)) {
    return AuditStepStage.toDo;
  }

  // If there are any questions that are currently NonConformant, this step is still ToDo.
  if (questions
    .some(x => nonConformantAnswerCodes
      .some(code => code === getResponseFromQuestion(x, audit.status)?.answer))) {
    return AuditStepStage.toDo;
  }

  return AuditStepStage.done;
}

