import TooltipElement from "shared/components/common/tooltip/TooltipElement";

interface ICircleImgButtonProps {
  onClick?(): void,
  type: "secondary" | "primary",
  icon: string,
  iconSize?: "xlarge" | "large" | "medium" | "small",
  alt: string,
  isDisabled?: boolean,
  tooltip?: string,
}

const CircleImgButton: React.FC<ICircleImgButtonProps> = ({
  onClick,
  type,
  icon,
  iconSize = "small",
  alt,
  isDisabled,
  tooltip,
}) => {
  return (
    <TooltipElement
      tooltipString={tooltip}
    >
      <button
        className={`${type} circle ${isDisabled ? "disabled" : ""}`}
        onClick={!isDisabled ? onClick : undefined}
      >
        <img src={icon} alt={alt} className={`icon-${iconSize}`} />
      </button>
    </TooltipElement>
  );
};

export default CircleImgButton;