import UrlRoutes from "components/routing/UrlRoutes";
import config from "config";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import DataGrid from "shared/components/layout/grid/DataGrid";
import DataGridExportButton from "shared/components/layout/grid/export-button/DataGridExportButton";
import Page from "shared/components/layout/page/Page";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { useAppSelector } from "store/store";
import { setValue, startLoadProfile } from "store/user-management/UserManagementSlice";
import ProfileManagerModal from "./profile-manager/ProfileManagerModal";

const UM_GRID_COL_WIDTHS = {
  SMALL: 175,
  MEDIUM: 200,
  LARGE: 250,
  XLARGE: 350,
};

const UserManagementPage: React.FC = () => {
  const dispatch = useDispatch();
  const isManagerOpen = useAppSelector(store => store.userManagement.isManagerOpen);
  const loadOp = useAppSelector(store => store.userManagement.loadOp);
  const deleteOp = useAppSelector(store => store.userManagement.deleteOp);
  const saveOp = useAppSelector(store => store.userManagement.saveOp);

  const openManager = (profileId?: number, user?: IAzureADUser) => {
    dispatch(setValue({
      isManagerOpen: true,
      managerValues: {
        profileId,
        user,
        module: "Auditing",
      },
    }));
  };

  const onRowDoubleClick = useCallback((rowData: any) => {
    if (rowData.role === "DefaultUser") {
      dispatch(showErrorToast("Default User profiles cannot be edited."));
      return;
    }

    dispatch(startLoadProfile({
      module: rowData.module,
      email: rowData.email,
      profileId: rowData.id,
    }));
  }, [dispatch]);

  return (
    <Page
      title="User Management"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={[
        {
          element:
            <DataGridExportButton
              gridId="UserManagement"
            />,
          key: "EXPORT",
        },
        {
          element: (
            <Button
              buttonType="primary"
              onClick={() => openManager()}
            >
              Assign New Profile
            </Button>
          ),
          key: "NEW PROFILE",
        },
      ]}
    >

      <DataGrid
        id="UserManagement"
        dataSource="OData"
        dataUrl={`${config.endpoints.baseUrl}/odata/profileGridViews`}
        showColumnChooser={true}
        allowExcelExport={true}
        filterSettings={{
          columns: [{
            uid: "deleted",
            field: "deleted",
            value: false,
          },],
        }}
        sortSettings={{
          columns: [{
            field: "name",
            direction: "Ascending",
          }],
        }}
        gridColumns={[
          {
            uid: "id",
            field: "id",
            headerText: "Id",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
            visible: false,
          },
          {
            uid: "userId",
            field: "userId",
            headerText: "UserId",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
            visible: false,
          },
          {
            uid: "name",
            field: "name",
            headerText: "Name",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "gin",
            field: "gin",
            headerText: "GIN",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "alias",
            field: "alias",
            headerText: "Alias",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "email",
            field: "email",
            headerText: "Email",
            type: "string",
            width: UM_GRID_COL_WIDTHS.MEDIUM,
          },
          {
            uid: "jobTitle",
            field: "jobTitle",
            headerText: "Job Title",
            type: "string",
            width: UM_GRID_COL_WIDTHS.MEDIUM,
          },
          {
            uid: "module",
            field: "module",
            headerText: "Module",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "role",
            field: "role",
            headerText: "Role",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "deleted",
            field: "deleted",
            headerText: "Deleted",
            type: "boolean",
            width: UM_GRID_COL_WIDTHS.SMALL,
            visible: false,
          },
          {
            uid: "restrictions",
            field: "restrictions",
            headerText: "Restrictions",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },

        ]}
        recordDoubleClick={onRowDoubleClick}
      />

      {isManagerOpen && (
        <ProfileManagerModal />
      )}

      {(loadOp?.isWorking
        || saveOp?.isWorking
        || deleteOp?.isWorking) && (
          <ModalSpinner />
        )}

    </Page>
  );
};

export default UserManagementPage;
