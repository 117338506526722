import UrlRoutes from "components/routing/UrlRoutes";
import React from "react";
import { Link } from "react-router-dom";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Page from "shared/components/layout/page/Page";
import "./AuditAdminPage.scoped.scss";

const AuditAdminPage: React.FC = () => {
  // Put the properties of UrlRoutes into these arrays to make them appear in the lists.
  // If the item isn't a UrlRoute yet, add a custom IRoute instead.
  const systemDataLinks = [
    UrlRoutes.QuestionLibrary,
    UrlRoutes.AdminUserManagement,
    UrlRoutes.AdminTopics,
    UrlRoutes.AdminTopicsRearrange,
    UrlRoutes.AdminTemplates,
    UrlRoutes.AdminAuditPlanning,
  ];

  const masterDataLinks = [
    UrlRoutes.AdminVerificationMethods,
    UrlRoutes.AdminQuestionTypes,
    UrlRoutes.AdminOwnerGroups,
    UrlRoutes.AdminQuestionCategories,
    UrlRoutes.AdminAuditGroups,
    UrlRoutes.AdminAuditTypes,
    UrlRoutes.AdminCausalFactors,
    UrlRoutes.AdminHSEMSSubElements,
    UrlRoutes.AdminActionItemTypes,
    UrlRoutes.AdminActionItemPrioritiesName,
    UrlRoutes.AdminAPIs,
    UrlRoutes.AdminISOs,
  ];

  return (
    <Page
      title="Audit Administration"
      showBreadcrumbs={true}
    >
      <FlexRow>
        <Card
          title="Audit System Data"
        >
          <ul
            className={systemDataLinks.length >= 6 ? "double" : ""}
          >
            {systemDataLinks
              .map(item =>
                <li
                  key={item.label}
                >
                  <Link to={item.urlTemplate}>{item.label}</Link>
                </li>
              )}
          </ul>
        </Card>

        <Card
          title="Audit Master Data"
        >
          <ul
            className={masterDataLinks.length >= 6 ? "double" : ""}
          >
            {masterDataLinks
              .map(item =>
                <li
                  key={item.label}
                >
                  <Link to={item.urlTemplate}>{item.label}</Link>
                </li>
              )}
          </ul>
        </Card>
      </FlexRow>
    </Page>
  );
}

export default AuditAdminPage;
