import { AuditPlanApprovalStatuses, ComplianceResults } from "types/auditPlanningTypes";
import { AuditSources, AuditStatuses } from "types/auditingTypes";
import { IQISchedulerProps } from "./QIScheduler";

/** The type of a QISchedulerItem. */
export type QISchedulerItemType = "Audit" | "Plan";

/** An item to be displayed within a QIScheduler. */
export interface IQISchedulerItem<TItemData> {
  /** The unique identifier for this item. */
  id: string | number,
  /** The start date which determines placement on the scheduler. */
  startDate: Date,
  /** The end date which determines placement on the scheduler. */
  endDate: Date,
  /** The unique identifier of the row this item belongs to. */
  rowId: string | number,
  /** Determines the vertical placement within a row of the scheduler. This will be set automatically by the scheduler. */
  rowSlot?: number,
  /** Optional. Determines if the item can be stretched horizontally to edit its start/end dates. Default = false. */
  isResizable?: boolean,
  /** Optional. Determines if the item can be dragged and dropped to change its start/end dates. Default = false. */
  isDraggable?: boolean,
  /** Determines if this item is selectable in the scheduler. */
  isSelectable: boolean,
  /** Determines if this item is currently selected in the scheduler or not. */
  isSelected: boolean,
  /** Extra data specified by the user that this object represents. */
  itemData?: TItemData,
}

export interface IAuditSchedulerItemData {
  /** The type of item this scheduler item represents. */
  type: QISchedulerItemType,
  /** The id number of the audit or plan's audit type. */
  auditTypeId: number,
  /** The name of the AuditType of the associated audit or plan. */
  auditTypeName: string,
  /** The subgeounit id of the bound plan's parent dimension. */
  subGeoUnitId: number,
  /** The geounit id of the bound plan's parent dimension. */
  geoUnitId: number,
  /** The basin id of the bound plan's parent dimension. */
  basinId: number,
  /** Optional. The compliance result of the audit. */
  auditCompliance?: ComplianceResults | undefined,
  /** The list of names of the requirement dimensions. */
  requirementDimensionTexts: string[],
  /** The id of the requirement dimension of this plan. */
  requirementDimensionId?: number,
  /** The type of the requirement dimension of this plan. */
  requirementDimensionType: string,
  /** The name of the requirement dimension of this plan. */
  requirementDimensionText: string,
  /** Optional. Determines of the source of the bound audit. */
  auditSource?: AuditSources,
  /** Optional. Determines the legacy audit number for display. */
  legacyAuditNumber?: string,
  /** Optional. The id of the bound QI audit. */
  auditId?: number,
  /** Optional. The status of the bound QI audit. */
  auditStatus?: AuditStatuses,
  /** The id of the bound QI plan. */
  planId: number,
  /** Optional. The status of the bound QI plan. */
  planStatus?: AuditPlanApprovalStatuses,
  /** Optional. The assigned week of the year of the bound QI plan. */
  planWeekOfYear?: number,
  /** The year of the bound QI plan. */
  planYear: number,
  /** The parent dimension id of the QI plan. */
  parentDimensionId: number,
  /** The parent dimension type of the QI plan. */
  parentDimensionType: string,
  /** Optional. The sub-type of the parent dimension of the QI plan. */
  parentDimensionSubType?: string,
  /** The display text of the parent dimension of the QI plan. */
  parentDimensionText: string,
  /** The child dimension id of the QI plan. */
  childDimensionId?: number,
  /** The child dimension type of the QI plan. */
  childDimensionType?: string,
  /** The child dimension name of the QI plan. */
  childDimensionText?: string,
  /** The child dimension sub type of the QI plan. */
  childDimensionSubType?: string,
  /** Optional. */
  leadAuditorEmail?: string,
  /** Optional. */
  leadAuditorName?: string,
  /** The list of ALL parent dimension types and texts. */
  parentDimensions: {
    type: string,
    text: string,
  }[],
  /** The start time of the item. */
  startTime: number,
  /** The end time of the item. */
  endTime: number,
}

/** Data related to a QISchedulerItem that is being updated by the user. */
export interface IQISchedulerUpdateItemArgs {
  /** The item that is to be updated. */
  item: IQISchedulerItem<any>,
  /** The new start date of the item. If no change, it will be the same as the item's startDate. */
  startDate: Date,
  /** The new end date of the item. If no change, it will be the same as the item's endDate. */
  endDate: Date,
}

/** A row to be displayed in a QIScheduler. */
export interface IQISchedulerRow {
  /** The unique identifier for this row. */
  id: string | number,
  /** The text to display for this row. */
  text: string,
  /** Optional. The list of children belonging to this row. */
  children?: IQISchedulerRow[],
  /** Optional. The alignment for the displayed text of this row. Default = left. */
  textAlign?: "left" | "right",
  /** Optional. Determines if the children of this row are visible or not. Default = true. */
  isExpanded?: boolean,
  /** Optional. If true, items on this row cannot be edited and other items cannot be dropped onto this row. */
  isDisabled?: boolean,
}

/** The date range to be displayed within a QIScheduler. */
export interface IQISchedulerDateRange {
  /** The start timestamp of the date range. Can be converted to a Date with `new Date(startTimestamp)`. */
  startTimestamp: number,
  /** The end timestamp of the date range. Can be converted to a Date with `new Date(endTimestamp)`. */
  endTimestamp: number,
}

/** A dictionary wherein each key is a `QISchedulerRow`'s id (toString'ed). Contains extra data for each row. */
export interface IQISchedulerRowItemDictionary {
  [rowId: string]: {
    /** The list of items in this row. */
    items: IQISchedulerItem<any>[],
    /** The global index of this row throughout the entire scheduler. */
    rowIndex: number,
    /** The height of this row in the scheduler UI. */
    rowHeight: number,
  } | undefined,
}

/** A date to be displayed in the QIScheduler. */
export interface IQISchedulerDate {
  /** The date. */
  date: Date,
  /** The horizontal index within the scheduler UI. */
  index: number,
}

/** The height of a row in the QIScheduler. */
export const qiSchedulerBaseRowHeight = 50;
/** The width of a column in the QIScheduler. */
export const qiSchedulerBaseColWidth = 75;
/** The height of an item in the QIScheduler. */
export const qiSchedulerBaseItemHeight = 35;

/** Used internally by the QIScheduler to share data between the various parts of the scheduler.
 * Do not render this yourself!
 */
export type IQISchedulerContextValues = IQISchedulerProps & {
  dateRange: IQISchedulerDateRange,
  dates: IQISchedulerDate[],
  rowItemDictionary: IQISchedulerRowItemDictionary,
  today: Date,
  onDateRangeChange: (newDateRange: IQISchedulerDateRange) => void,
  onRowToggle: (row: IQISchedulerRow, isExpanded: boolean) => void,
  resizeData?: IQISchedulerItemResizeData,
};

export interface IQISchedulerItemResizeData {
  itemDiv: HTMLDivElement,
  side: "left" | "right" | "center",
  mouseDownPosition: IVector,
  initialItemSize: IItemSize,
  startDate: Date,
  endDate: Date,
  item: IQISchedulerItem<any>,
}

interface IItemSize {
  left: number,
  width: number,
}

interface IVector {
  x: number,
  y: number,
}