import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import { uniq } from "lodash";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IAzureADUser } from "shared/types/userProfileTypes";
import formatDate from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditQuestion, ITemplate } from "types/auditingTypes";
import { v4 as uuidv4 } from "uuid";
import "./GuidanceCard.scoped.scss";

interface IGuidanceCardProps {
  question: IAuditQuestion,
  auditStatus: AuditStatuses,
  auditTemplate?: ITemplate[],
}

const GuidanceCard: React.FC<IGuidanceCardProps> = ({
  question,
  auditStatus,
}) => {
  const {
    guidance,
    category,
    auditorEmail,
    auditorName,
    topicName,
    subTopics,
    scoringSystem,
  } = question;

  const auditor: IAzureADUser | undefined = auditorEmail
    ? {
      name: auditorName || "",
      email: auditorEmail,
    } : undefined;

  const apiName = question.apis?.[0]?.name;

  const hseElemName = question.hseSubElement?.name;
  const qmsElemName = question.qmsElements?.[0]?.name;
  const response = getResponseFromQuestion(question, auditStatus);
  const priorResponse = auditStatus === AuditStatuses.Completed
    || AuditStatuses.Closed
    ? getResponseFromQuestion(question, AuditStatuses.InProgress)
    : undefined;

  const allClmWhomToCheck = question.clmWhomToCheck.map(x => x.name);
  const clmWhomToCheck = question.clmWhomToCheck?.[0]?.name;
  const clmDocRefs = question.clmDocRefs;
  const clmDocRefDMSs = uniq(clmDocRefs.map(docRef => docRef.dms));
  const clmDocRefGems = uniq(clmDocRefs.map(docRef => docRef.currentRevision));
  const clmAuditPackages = uniq(clmDocRefs.map(docRef => docRef.auditPackage));

  return (
    <Card
      className="guidance-card"
      headerElement={(
        <LabeledControl
          label="Guidance"
          isLight={true}
          className="guidance"
        >
          <FlexCol
            className="guidance-rows"
          >
            {guidance.split('\n').map((line, ix) => (
              <span key={uuidv4()}>
                {!!line.trim() ? line : <>&nbsp;</>}
              </span>
            ))}
          </FlexCol>
        </LabeledControl>
      )}
      showHeader={true}
    >
      <FlexCol
        className="spaced-area"
      >

        {scoringSystem !== AuditScoringSystems.CLM &&
          <FlexRow>
            <LabeledControl
              label={"Audit Topic"}
              isLight={true}
            >
              <span className='guidance-label-content'>{topicName}</span>
            </LabeledControl>
          </FlexRow>
        }
        {scoringSystem === AuditScoringSystems.CLM &&
          <FlexRow>
            <LabeledControl
              label="With Whom to Check"
              isLight={true}
              tooltip={allClmWhomToCheck.toString()}
            >
              <span >{clmWhomToCheck}</span>
            </LabeledControl>
          </FlexRow>
        }
        <FlexRow>


          {scoringSystem === AuditScoringSystems.CLM &&
            <LabeledControl
              label="Audit Package"
              isLight={true}
            >
              {clmAuditPackages.length > 0
                ? clmAuditPackages.map((clmAuditPackage, ix) => ix > 0
                  ? (
                    <React.Fragment
                      key={clmAuditPackage + ix.toString()}
                    >
                      <br />
                      <span className="guidance-label-content">{clmAuditPackage}</span>
                    </React.Fragment>
                  ) : clmAuditPackage
                ) : "-"
              }
            </LabeledControl>
          }
          <LabeledControl
            label={scoringSystem === AuditScoringSystems.CLM
              ? "Audit Procedure"
              : "Audit Sub-Topic"}
            isLight={true}
          >
            <span className="guidance-label-content">
              {subTopics.length > 0
                ? subTopics.map((st, ix) => ix > 0
                  ? (
                    <React.Fragment
                      key={st + ix.toString()}
                    >
                      <br />
                      {st.name}
                    </React.Fragment>
                  ) : st.name
                ) : "-"
              }
            </span>
          </LabeledControl>
          {scoringSystem !== AuditScoringSystems.CLM && <LabeledControl
            label="Auditor"
            isLight={true}
          >
            <span className="guidance-label-content">{userToString(auditor, "-")}</span>
          </LabeledControl>
          }
        </FlexRow>
        {scoringSystem === AuditScoringSystems.CLM && <FlexRow>
          <LabeledControl
            label="Auditor"
            isLight={true}
          >
            <span className="guidance-label-content">{userToString(auditor, "-")}</span>
          </LabeledControl>
          <LabeledControl
            label="CLM Document Reference"
            isLight={true}
          >
            <span className="guidance-label-content">
              {clmDocRefs.length > 0
                ? clmDocRefs.map((clmDocRef, ix) => ix > 0
                  ? (
                    <React.Fragment
                      key={clmDocRef.name + ix.toString()}
                    >
                      <br />
                      {clmDocRef.name}
                    </React.Fragment>
                  ) : clmDocRef.name
                ) : "-"
              }
            </span>
          </LabeledControl>

        </FlexRow>}
        {scoringSystem === AuditScoringSystems.CLM &&
          <FlexRow>
            <LabeledControl
              label="DMS#"
              isLight={true}
            >
              <span className="guidance-label-content">
                {clmDocRefDMSs.length > 0
                  ? clmDocRefDMSs.map((clmDocRefDMS, ix) => ix > 0
                    ? (
                      <React.Fragment
                        key={clmDocRefDMS + ix.toString()}
                      >
                        <br />
                        {clmDocRefDMS}
                      </React.Fragment>
                    ) : clmDocRefDMS
                  ) : "-"
                }
              </span>
            </LabeledControl>

            <LabeledControl
              label="GeMs revision used in this CAT"
              isLight={true}
            >
              <span className="guidance-label-content">
                {clmDocRefGems.length > 0
                  ? clmDocRefGems.map((clmDocRefGem, ix) => ix > 0
                    ? (
                      <React.Fragment
                        key={clmDocRefGem + ix.toString()}
                      >
                        <br />
                        {clmDocRefGem}
                      </React.Fragment>
                    ) : clmDocRefGem
                  ) : "-"
                }
              </span>
            </LabeledControl>
          </FlexRow>}
        <FlexRow>
          {scoringSystem === AuditScoringSystems.CLM && <LabeledControl
            label="Clause"
            isLight={true}
          >
            <span className="guidance-label-content">
              {question.clause || "-"}
            </span>
          </LabeledControl>
          }
          <LabeledControl
            label="Category"
            isLight={true}
          >
            <span className="guidance-label-content">{category}</span>
          </LabeledControl>
          {scoringSystem !== AuditScoringSystems.CLM &&
            <LabeledControl
              label="Date Answered"
              isLight={true}
            >
              <span className="guidance-label-content">
                {priorResponse?.modifiedOnTimestamp
                  ? formatDate(new Date(priorResponse.modifiedOnTimestamp), true)
                  : (response?.modifiedOnTimestamp
                    ? formatDate(new Date(response.modifiedOnTimestamp), true)
                    : "--")
                }
              </span>
            </LabeledControl>
          }
        </FlexRow>

        <FlexRow>
          {scoringSystem === AuditScoringSystems.CLM && <LabeledControl
            label="Date Answered"
            isLight={true}
          >
            <span className="guidance-label-content">
              {priorResponse?.modifiedOnTimestamp
                ? formatDate(new Date(priorResponse.modifiedOnTimestamp), true)
                : (response?.modifiedOnTimestamp
                  ? formatDate(new Date(response.modifiedOnTimestamp), true)
                  : "--")

              }
            </span>
          </LabeledControl>
          }

          {scoringSystem === AuditScoringSystems.HSE &&
            <LabeledControl
              label={"HSE Management System Sub Element"}
              isLight={true}
            >
              <span className="guidance-label-content">{hseElemName || "-"}</span>
            </LabeledControl>
          }
          {(scoringSystem === AuditScoringSystems.QMS || scoringSystem === AuditScoringSystems.CLM) &&
            <LabeledControl
              label={"QMS Sub Element"}
              isLight={true}
            >
              <span className="guidance-label-content">{qmsElemName || "-"}</span>
            </LabeledControl>
          }
          {scoringSystem !== AuditScoringSystems.CLM &&
            <LabeledControl
              label={"API"}
              isLight={true}
            >
              <span className="guidance-label-content">{apiName || "-"}</span>
            </LabeledControl>
          }
        </FlexRow>
        <FlexRow>
          {scoringSystem === AuditScoringSystems.CLM && <LabeledControl
            label={"API"}
            isLight={true}
          >
            <span className="guidance-label-content">{apiName || "-"}</span>
          </LabeledControl>
          }
          <LabeledControl
            label={"LTO"}
            isLight={true}
          >
            <span className="guidance-label-content">
              {question.licenseToOperate
                ? "Y"
                : "-"
              }
            </span>
          </LabeledControl>
        </FlexRow>
      </FlexCol>
    </Card >
  );
};

export default GuidanceCard;
