import { IAzureADUser } from "shared/types/userProfileTypes";

export interface IAuditType {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IAuditGroup {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IAuditorType {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IAuditTopic {
  id: number,
  auditGroupId: number,
  auditGroup: string,
  level: number,
  parentId?: number,
  name: string,
  groupName?: string,
  isSelectable?: boolean,
  sortOrder: number,
  isDeleted: boolean,
  isPlannable: boolean,
  children?: IAuditTopic[],
  questions?: number,
  auditor?: IAzureADUser[],
  auditee?: IAzureADUser[],
  dates?: Date,
  ownerGroupId?: number,
  ownerGroup: string,
  scoringSystem: string,
}

export enum AuditTopicSearchTypes {
  OwnerGroups = "OwnerGroups",
  Template = "Template",
  AuditGroup = "AuditGroup",
  Ids = "Ids",
  OnlySubTopicsFromIds = "OnlySubTopicsFromIds",
}

export interface AuditTopicSearch {
  auditTopicSearchType: AuditTopicSearchTypes,
  auditTopicSearchIds: number[]
}


export interface IQuestionCategory {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IVerificationMethod {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface ICausalFactor {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IHelpItem {
  id: number,
  slug: string,
  title: string,
  helpText: string,
}

export interface IApi {
  id: number,
  name: string,
  spec: string,
  sortOrder: number,
  mainSection: string,
  subSection: string,
  isDeleted: boolean,
}

export interface IHSESubElement {
  id: number,
  name: string,
  mss: string,
  sortOrder: number,
  isDeleted: boolean,
}

export interface IQMSElement {
  id: number,
  name: string,
  mainElement: string,
  subElement: string,
  sortOrder: number,
  isDeleted: boolean,
}

export interface IQuestionType {
  id: number,
  name: string,
  isDeleted: boolean,
}

export interface IIso {
  id: number,
  name: string,
  spec: string,
  sortOrder: number,
  isDeleted: boolean,
}

export interface IPerspective {
  id: number,
  name: string,
  deleted: boolean,
  xAxes: IPerspectiveXAxes[],
  metaData: IPerspectiveMetaData[],
}

export interface IPerspectiveXAxes {
  id: number,
  perspectiveId: number,
  auditGroupId: number,
  ownerGroupId: number,
  auditGroup: string,
  ownerGroup: string,
  deleted: boolean,
  plannableMasterDataTypes: IPerspectiveXAxisPlannableType[],
}

export interface IPerspectiveXAxisPlannableType {
  id: number,
  masterDataType: string,
  masterDataSubType?: string,
}

export interface IPerspectiveMetaData {
  id: number,
  perspectiveId: number,
  type: string,
  masterDataId: number,
  deleted: boolean,
}

export interface IAnswer {
  key: string,
  name: string,
  code: string,
  requiresRWP: boolean,
  isNA: boolean,
  canBeUpdated: boolean,
  canBeUpdatedTo: boolean,
  displayColor: string | undefined,
  scoringSystem: string,
  sortOrder: number,
}