import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import config from "config";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import QoFErrorBoundary from "shared/components/common/qof-error-boundary/QoFErrorBoundary";
import Button from "shared/components/controls/buttons/button/Button";
import CircleImgButton from 'shared/components/controls/buttons/circle-img-button/CircleImageButton';
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import DataGrid from "shared/components/layout/grid/DataGrid";
import DataGridExportButton from "shared/components/layout/grid/export-button/DataGridExportButton";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import editIcon from "shared/media/dls/edit-1.svg";
import formatDate from 'shared/utilities/dateFormatters';
import { toTitleCase } from "shared/utilities/stringUtilities";
import { openConfirmCreateManualAuditModal } from "store/audit/AuditSlice";
import { setMyAuditPropValue } from "store/my-audits/MyAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ComplianceResults } from 'types/auditPlanningTypes';
import { AuditRelations, AuditStatuses } from 'types/auditingTypes';
import AuditStatusChip from "../common/audit-status-chip/AuditStatusChip";
import ComplianceResult from '../common/compliance-result/ComplianceResult';
import "./MyAuditsPage.scoped.scss";

const MyAuditsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentAuditRelations = useAppSelector(store => store.myAudits.currentAuditRelation);
  const url = config.endpoints.auditing.myAudits + `?relationFilter=${currentAuditRelations}`;

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: `My Audits - ${currentAuditRelations}`,
    });
  }, [currentAuditRelations, appInsights]);

  return (
    <FittedPage>
      <FlexRow
        className="buttons-header align-items-center"
      >
        <button
          onClick={() => {
            dispatch(openConfirmCreateManualAuditModal());
          }}
          className="primary"
        >
          create new audit
        </button>

        <DataGridExportButton
          gridId="MyAudits"
        />

        <Link
          className="menuDisable"
          to={UrlRoutes.MyAudits.urlTemplate}
        >
          <button>
            my audits
          </button>
        </Link>

        <Link
          className="menuDisable"
          to={UrlRoutes.MyActions.urlTemplate}
        >
          <button>
            my actions
          </button>
        </Link>

        <Link
          className="menuDisable"
          to={UrlRoutes.AuditAdmin.urlTemplate}
        >
          <button>
            audit admin
          </button>
        </Link>
      </FlexRow>

      <Card
        title="My Audits"
        className="my-audits-card"
        headerElement={(
          <TabSelection
            tabs={enumKeys(AuditRelations).map(k => ({
              key: AuditRelations[k],
              node: toTitleCase(AuditRelations[k].toString()),
              isSelected: currentAuditRelations === AuditRelations[k],
            }))}
            onTabClicked={key => {
              let keys = enumKeys(AuditRelations);
              for (const element of keys) {
                if (element === key.toString()) {
                  dispatch(setMyAuditPropValue({
                    currentAuditRelation: AuditRelations[element],
                  }));
                  return;
                }
              }
            }}
          />
        )}
      >
        <QoFErrorBoundary
          isFullPage={false}
        >
          <DataGrid
            id="MyAudits"
            className="finding-button-content"
            dataSource="OData"
            dataUrl={url}
            enablePersistence={true}
            allowExcelExport={true}
            sortSettings=
            {{
              columns: [{
                field: "startDate",
                direction: "Descending",
              }],
            }}
            gridColumns={[{
              uid: "editAudit",
              type: "unbound",
              width: 65,
              template: (row: IMyAuditsGridRowObject) =>
                <CircleImgButton
                  icon={editIcon}
                  alt="Edit"
                  type="primary"
                  onClick={() => navigate(formatRoute(UrlRoutes.EditAudit, { auditId: row.id.toString() }))}
                />,
            }, {
              uid: "id",
              field: "id",
              headerText: "Audit ID",
              width: 150,
              template: (row: IMyAuditsGridRowObject) => row.id.toString(),
              type: "number",
            }, {
              uid: "name",
              field: "name",
              headerText: "Audit Name",
              width: 250,
              template: (row: IMyAuditsGridRowObject) =>
                // This must be a button and not the standard react router <Link /> because Wijmo.
                <Button
                  buttonType="link"
                  onClick={e => {
                    if (e.ctrlKey) {
                      window.open(formatRoute(UrlRoutes.AuditSummary, { auditId: row.id.toString() }));
                    } else {
                      navigate(formatRoute(UrlRoutes.AuditSummary, { auditId: row.id.toString() }));
                    }
                  }}
                >
                  {row.name}
                </Button>,
              type: "string",
            }, {
              uid: "leadAuditor",
              field: "leadAuditor",
              headerText: "Lead Auditor",
              width: 250,
              type: "string",
            }, {
              uid: "facilityNames",
              field: "facilityNames",
              headerText: "Facility",
              width: 250,
              type: "string",
            }, {
              uid: "businessViewNames",
              field: "businessViewNames",
              headerText: "Business View (Divisions)",
              width: 250,
              type: "string",
            }, {
              uid: "auditTopicNames",
              field: "auditTopicNames",
              headerText: "Topic",
              width: 250,
              type: "string",
            }, {
              uid: "auditType",
              field: "auditType",
              headerText: "Audit Type",
              width: 200,
              type: "string",
            }, {
              uid: "startDate",
              field: "startDate",
              headerText: "Start Date",
              format: "MM/dd/yyyy",
              template: (row: IMyAuditsGridRowObject) => formatDate(row.startDate),
              width: 150,
              type: "date",
            }, {
              uid: "status",
              field: "status",
              headerText: "Status",
              template: (row: IMyAuditsGridRowObject) =>
                <AuditStatusChip status={row.status} />,
              width: 150,
              type: "string",
              textAlign: "Left",
            }, {
              uid: "completionPercent",
              field: "completionPercent",
              headerText: "% Completed",
              template: (row: IMyAuditsGridRowObject) =>
                row.completionPercent === undefined
                  || row.completionPercent === null
                  ? "0%"
                  : `${row.completionPercent}%`,
              width: 150,
              textAlign: "Left",
              type: "number",
            }, {
              uid: "completedCompliance",
              field: "completedCompliance",
              headerText: "Completed Compliance",
              width: 200,
              type: "string",
              template: (audit: IMyAuditsGridRowObject) =>
                audit.status === AuditStatuses.Closed
                  || audit.status === AuditStatuses.Completed
                  ? (
                    <ComplianceResult
                      complianceResult={audit.completedCompliance}
                    />
                  ) : '-',
            }, {
              uid: "closedCompliance",
              field: "closedCompliance",
              headerText: "Closed Compliance",
              width: 200,
              type: "string",
              template: (audit: IMyAuditsGridRowObject) =>
                audit.status === AuditStatuses.Closed
                  || audit.status === AuditStatuses.Completed
                  ? (
                    <ComplianceResult
                      complianceResult={audit.closedCompliance}
                    />
                  ) : '-',
            }, {
              uid: "allActionsClosed",
              field: "allActionsClosed",
              headerText: "All Actions Closed",
              width: 200,
              textAlign: "Left",
              type: "boolean",
            }]}
          />
        </QoFErrorBoundary>
      </Card>
    </FittedPage>
  );
};

export default MyAuditsPage;

function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e as any) as (keyof E)[];
}

export interface IMyAuditsGridRowObject {
  id: number,
  name: string,
  fullName: string,
  leadAuditor: string,
  facilityNames: string,
  businessViewNames: string,
  auditTopicNames: string,
  auditType: string,
  startDate: Date,
  status: AuditStatuses,
  completionPercent?: number | null,
  completedCompliance?: ComplianceResults,
  closedCompliance?: ComplianceResults,
  openActionItemCount: number,
  closedActionItemCount: number,
  allActionsClosed: boolean,
  deleted: boolean,
}
