import React from "react";
import { IQISchedulerItem } from "../../qiSchedulerTypes";

interface IQISchedulerItemGripProps {
  /** Determines which side of the scheduler item this grip appears on. */
  side: "left" | "right",
  /** The scheduler item this grip is for. */
  item: IQISchedulerItem<any>,
}

const QISchedulerItemGrip: React.FC<IQISchedulerItemGripProps> = ({
  side,
  item,
}: IQISchedulerItemGripProps) => {
  return (
    <div
      className={`grip grip-${side} ${item.isResizable
        ? "draggable"
        : ""}`
      }
      data-scheduler-item-id={item.id}
    ></div>
  );
};

export default QISchedulerItemGrip;