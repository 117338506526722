import { authDelete, authGetJson, authPostJson } from "auth/authFetches";
import { IPlanProfile, IPlanProfileAttribute, IProfileStandardMetaDataSelection } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import config from "config";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import {
  IApprovePlanRequest, IAuditPlanScore,
  IAuditPlanView,
  ICenterAuditAttribute,
  ICreatePlanRequest,
  ICreatePlanResponse, IPlanFiltersRequest, IPlanGridXAxesData as IPlanGridXAxisData,
  IPlanGridYAxesData as IPlanGridYAxisData, IPlanProfileAttributeInfo, IPlanProfileAttributeInfoFilterRequest, IPlanTemplateHierarchyInfo, IPlanXFiltersRequest, IPlanYFiltersRequest, IProfileParentChildMatrixItem, IRemovePlanResponse
} from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplateChildren } from "types/templateApiTypes";
import {
  formatApprovePlansRequest, formatCreateAuditFromPlansRequest,
  formatCreatePlanResponse,
  formatGetAuditPlanScoresResponse,
  formatGetAuditPlansResponse,
  formatGetCenterAuditAttributes,
  formatGetDefaultProfileStandardMetaData,
  formatGetPlanTemplateHierarchyInfoResponse,
  formatPlanAttribute,
  formatPlanGridXAxesResponse as formatPlanGridXAxisResponse,
  formatPlanGridYAxesResponse as formatPlanGridYAxisResponse,
  formatPlanProfile,
  formatPlanProfileAttributeInfo,
  formatPlanProfileAttributeInfoFilterRequest,
  formatPlanningFiltersQueryString, formatPlanningXFiltersQueryString, formatPlanningYFiltersQueryString,
  formatProfileMetaDataResponse,
  formatProfileMetaDataSelectedItemsRequest,
  formatProfileParentChildMatrixItems,
  formatProfileStandardTemplateSelectedItemsRequest,
  formatRemovePlanResponse
} from "./auditPlanningFormatters";

class AuditPlanningApi {
  public async approvePlans(approvals: IApprovePlanRequest[]): Promise<void> {
    const response = await authPostJson(config.endpoints.auditPlanning.approvePlans,
      formatApprovePlansRequest(approvals));
    await throwIfResponseError(response);
  }

  public async createPlan(plan: ICreatePlanRequest): Promise<ICreatePlanResponse> {
    const response = await authPostJson(config.endpoints.auditPlanning.plans,
      plan);
    await throwIfResponseError(response);
    return formatCreatePlanResponse(await response.json());
  }

  public async removePlan(planId: number, comment: string): Promise<IRemovePlanResponse> {
    const response = await authPostJson(config.endpoints.auditPlanning.plans + `/${planId}/remove`,
      {
        AuditPlanId: planId,
        RemoveReason: comment,
      });
    await throwIfResponseError(response);
    return formatRemovePlanResponse(await response.json());
  }

  public async getPlans(filters: IPlanFiltersRequest): Promise<IAuditPlanView[]> {
    const queryString = formatPlanningFiltersQueryString(filters);
    const response = await authGetJson(`${config.endpoints.auditPlanning.plans}?${queryString}`);
    await throwIfResponseError(response);
    return formatGetAuditPlansResponse(await response.json());
  }

  public async getPlanScores(filters: IPlanFiltersRequest): Promise<IAuditPlanScore[]> {
    const queryString = formatPlanningFiltersQueryString(filters);
    const response = await authGetJson(`${config.endpoints.auditPlanning.planScores}?${queryString}`);
    await throwIfResponseError(response);
    return formatGetAuditPlanScoresResponse(await response.json());
  }

  public async getPlanGridXAxis(filters: IPlanXFiltersRequest): Promise<IPlanGridXAxisData> {
    const queryString = formatPlanningXFiltersQueryString(filters);
    const response = await authGetJson(`${config.endpoints.auditPlanning.gridXAxis}?${queryString}`);
    await throwIfResponseError(response);
    return formatPlanGridXAxisResponse(await response.json());
  }

  public async getPlanGridYAxis(filters: IPlanYFiltersRequest): Promise<IPlanGridYAxisData> {
    const queryString = formatPlanningYFiltersQueryString(filters);
    const response = await authGetJson(`${config.endpoints.auditPlanning.gridYAxis}?${queryString}`);
    await throwIfResponseError(response);
    return formatPlanGridYAxisResponse(await response.json());
  }

  public async createAuditFromPlans(planIds: number[],
    startDate: Date,
    endDate: Date,
    leadAuditor: IAzureADUser): Promise<number> {
    const response = await authPostJson(config.endpoints.auditPlanning.createAuditFromPlans,
      formatCreateAuditFromPlansRequest(planIds,
        startDate,
        endDate,
        leadAuditor));
    await throwIfResponseError(response);
    return Number(await response.json());
  }

  public async getPlanProfiles(filters: Partial<IAuditPlanningFilters> & { profileIds?: number[]; }): Promise<IPlanProfile[]> {
    const qs = [
      ...(filters.businessTeams || []).map(x => `businessTeamIds=${x.id}`),
      ...(filters.facilities || []).map(x => `facilityIds=${x.id}`),
      ...(filters.businessViews || []).map(x => `businessViewIds=${x.id}`),
      ...(filters.profileIds || []).map(x => `profileIds=${x}`),
    ];
    if (filters?.perspectiveXAxes?.length) {
      qs.push(`perspectiveXAxisId=${filters.perspectiveXAxes[0].id}`);
    }
    const response = await authGetJson(config.endpoints.auditPlanning.planProfiles
      + (qs.length > 0 ? `?${qs.join("&")}` : ""));
    await throwIfResponseError(response);
    const json = await response.json();
    return Array.isArray(json)
      ? json.map(formatPlanProfile)
      : [];
  }

  public async getPlanAttributes(filters: Partial<IAuditPlanningFilters>): Promise<IPlanProfileAttribute[]> {
    const xAxes = filters.perspectiveXAxes?.[0];

    const qs = [
      ...(filters.businessTeams || []).map(x => `businessTeamIds=${x.id}`),
      ...(filters.facilities || []).map(x => `facilityIds=${x.id}`),
      ...(filters.businessViews || []).map(x => `businessViewIds=${x.id}`),
      `IncludeDeletedAttributes=${filters.includeDeletedAttributes}`,
      xAxes ? `ownerGroupId=${xAxes.ownerGroupId}` : undefined,
      xAxes ? `auditGroupId=${xAxes.auditGroupId}` : undefined,
    ].filter(isNotUndefined).join("&");

    const response = await authGetJson(config.endpoints.auditPlanning.planAttributes
      + (qs ? `?${qs}` : ""));
    await throwIfResponseError(response);
    const json = await response.json();
    return Array.isArray(json)
      ? json.map(formatPlanAttribute)
      : [];
  }

  public async createProfile(profile: IPlanProfile, subGeoUnitId: number): Promise<IPlanProfile> {
    const response = await authPostJson(`${config.endpoints.auditPlanning.planProfiles}`, {
      ParentProfileId: profile.parentId,
      MasterDataId: profile.masterDataId,
      MasterDataType: profile.masterDataType,
      SubGeoUnitId: subGeoUnitId,
    });

    await throwIfResponseError(response);

    const json = await response.json();

    return formatPlanProfile(json);
  }

  public async createAttribute(attribute: IPlanProfileAttribute): Promise<IPlanProfileAttribute> {
    const response = await authPostJson(`${config.endpoints.auditPlanning.planAttributes}`, {
      ProfileId: attribute.profileId,
      MasterDataId: attribute.masterDataId,
      MasterDataType: attribute.masterDataType,
    });

    await throwIfResponseError(response);

    const json = await response.json();

    return formatPlanAttribute(json);
  }

  public async markProfileAttributesReviewed(attributeIds: number[]): Promise<{
    reviewedAttributes: IPlanProfileAttribute[],
    invalidAttributeIds: number[],
    numPlanRecalculations: number,
    hadPlanCalculationError: boolean,
  }> {
    const response = await authPostJson(`${config.endpoints.auditPlanning.planAttributes}/review`, {
      AttributeIds: attributeIds,
    });

    await throwIfResponseError(response);

    const json = await response.json();

    return {
      reviewedAttributes: Array.isArray(json.reviewedAttributes)
        ? json.reviewedAttributes.map((obj: any) => formatPlanAttribute(obj))
        : [],
      invalidAttributeIds: Array.isArray(json.invalidAttributeIds)
        ? json.invalidAttributeIds.map((obj: any) => Number(obj))
        : [],
      numPlanRecalculations: Number(json.numPlanRecalculations),
      hadPlanCalculationError: Boolean(json.hadPlanCalculationError),
    };
  }

  public async removeAttribute(attributeId: number, comment: string): Promise<{
    deletedAttribute: IPlanProfileAttribute,
    didPlanCalculationsRun: boolean,
    hadPlanCalculationError: boolean,
  }> {
    const response = await authDelete(`${config.endpoints.auditPlanning.planAttributes}/${attributeId}`, {
      comment,
    });

    await throwIfResponseError(response);

    const json = await response.json();

    return {
      deletedAttribute: formatPlanAttribute(json.deletedAttribute),
      didPlanCalculationsRun: Boolean(json.didPlanCalculationsRun),
      hadPlanCalculationError: Boolean(json.hadPlanCalculationError),
    };
  }

  public async removeChildDimension(profileId: number, comment: string): Promise<void> {
    const response = await authDelete(`${config.endpoints.auditPlanning.planProfiles}/${profileId}`, {
      comment,
    });

    await throwIfResponseError(response);
  }

  public async saveProfileStandardTemplateItems(attributeId: number, templateSelectedItems: (IDetailedTemplateChildren)[]): Promise<void> {
    const response = await authPostJson(config.endpoints.auditPlanning.profileStandardTemplateSelection,
      formatProfileStandardTemplateSelectedItemsRequest(attributeId, templateSelectedItems));
    await throwIfResponseError(response);
  }

  public async saveProfileStandardMetaDataItems(attributeId: number, selectedMetaDataItems: IProfileStandardMetaDataSelection[]): Promise<void> {
    const response = await authPostJson(config.endpoints.auditPlanning.profileStandardMetaData,
      formatProfileMetaDataSelectedItemsRequest(attributeId, selectedMetaDataItems));
    await throwIfResponseError(response);
  }

  public async getprofileStandardTemplateSelectedIds(attributeId: number,
    type: MetaDataTypes | undefined = undefined,
    includeDeleted: boolean = false,
    abortSignal?: AbortSignal): Promise<number[]> {
    const filterDeleted = !includeDeleted
      ? "and deleted eq false"
      : "";

    const filterByType = type !== undefined
      ? `and MasterDataType eq '${type}'`
      : "";

    const response = await authGetJson(`${config.endpoints.auditPlanning.profileStandardTemplateSelectionView}?$filter=(profileStandardsId eq ${attributeId} ${filterByType} ${filterDeleted})`, abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .value
      .filter((x: any) => x.isSelected)
      .map((obj: any) => Number(obj.templateChildId));
  }

  public async getPlanProfileAttributeInfo(attributeId: number,
    filters?: IPlanProfileAttributeInfoFilterRequest,
    abortSignal?: AbortSignal): Promise<IPlanProfileAttributeInfo> {
    const queryString = filters
      ? formatPlanProfileAttributeInfoFilterRequest(filters)
      : "";
    const response = await authGetJson(`${config.endpoints.auditPlanning.planAttributes}/${attributeId}/info?${queryString}`,
      abortSignal);

    await throwIfResponseError(response);

    return formatPlanProfileAttributeInfo(await response.json());
  }

  public async getDefaultProfileStandardMetaData(attributeIds: number[], abortSignal?: AbortSignal): Promise<Partial<IPlanProfileAttributeInfo>> {
    const queryString = formatGetDefaultProfileStandardMetaData(attributeIds);

    const response = await authGetJson(`${config.endpoints.auditPlanning.planAttributes}/default?${queryString}`,
      abortSignal);

    await throwIfResponseError(response);

    return formatProfileMetaDataResponse(await response.json());
  }

  public async getPlanTemplateHierarchyInfo(planIds: number[],
    abortSignal?: AbortSignal): Promise<IPlanTemplateHierarchyInfo> {

    let queryString = "?" + planIds.map((id, ix) => `planIds[${ix}]=${id}`).join("&");

    const response = await authGetJson(`${config.endpoints.baseUrl}/planAttributeInfo${queryString}`,
      abortSignal);

    await throwIfResponseError(response);

    return formatGetPlanTemplateHierarchyInfoResponse(await response.json());
  }

  public async getProfileParentChildMatrix(abortSignal?: AbortSignal): Promise<IProfileParentChildMatrixItem[]> {
    const response = await authGetJson(`${config.endpoints.auditPlanning.profileParentChildMatrixItems}`,
      abortSignal);

    await throwIfResponseError(response);

    return formatProfileParentChildMatrixItems(await response.json());
  }

  public async GetCenterAuditAttributes(profileStandardId: number,
    masterDataId: number,
    masterDataType: string): Promise<ICenterAuditAttribute[]> {
    let queryString = "?";
    if (profileStandardId !== undefined) {
      queryString += `profileStandardId=${profileStandardId}&masterDataId=${masterDataId}&masterDataType=${masterDataType}`;
    } else {
      queryString += `masterDataId=${masterDataId}&masterDataType=${masterDataType}`;
    }

    const response = await authGetJson(`${config.endpoints.baseUrl}/clm/attributes${queryString}`);

    await throwIfResponseError(response);

    return formatGetCenterAuditAttributes(await response.json());
  }

  public async SaveCenterAuditAttributes(request: ICenterAuditAttribute[],
    abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(`${config.endpoints.baseUrl}/clm/attributes`,
      {
        Attributes: request
      },
      abortSignal);

    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditPlanningApi();
