import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import React from "react";
import { useMatch } from "react-router-dom";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import { doesScoringSystemAllowFindings } from "shared/utilities/findingUtilities";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import "./AuditNav.scoped.scss";

const auditDetailsText = "Audit Summary";
const auditQuestionsText = "Audit Questions";
const auditResultsText = "Results & NonConformances";
const auditFindingsText = "Findings";
const auditRWPText = "Audit RWP";
const auditEvidenceText = "Audit Evidence";
const auditReportsText = "Audit Reports";

interface IAuditNavProps {
  auditId: string | number,
  pageControls?: IPageHeaderControl[],
  scoringSystem: AuditScoringSystems | string,
}

const AuditNav: React.FC<IAuditNavProps> = ({
  auditId,
  pageControls,
  scoringSystem,
}) => {
  const executionFromAuditExecuteAll = !!useMatch(UrlRoutes.AuditExecuteAll.urlTemplate);
  const executionFromAuditExecuteQuestion = !!useMatch(UrlRoutes.AuditExecuteQuestion.urlTemplate);

  return(
  <div className="audit-nav">
    <TabSelection
      showDividers={false}
      tabs={[{
        key: "summary",
        node: auditDetailsText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditSummary, { auditId: auditId.toString() }) + "?noredirect=true",
        isSelected: !!useMatch(UrlRoutes.AuditSummary.urlTemplate),
      }, {
        key: "execution",
        node: auditQuestionsText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditExecuteAll, { auditId: auditId.toString() }),
        isSelected: executionFromAuditExecuteAll
        || executionFromAuditExecuteQuestion,
      }, {
        key: "resultNonConform",
        node: auditResultsText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditResultNonConformance, { auditId: auditId.toString() }),
        isSelected: !!useMatch(UrlRoutes.AuditResultNonConformance.urlTemplate),
      }, {
        key: "findings",
        node: auditFindingsText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditFindings, { auditId: auditId.toString() }),
        isSelected: !!useMatch(UrlRoutes.AuditFindings.urlTemplate),
      }, {
        key: "auditRWP",
        node: auditRWPText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditRWP, { auditId: auditId.toString() }),
        isSelected: !!useMatch(UrlRoutes.AuditRWP.urlTemplate),
      }, {
        key: "auditEvidence",
        node: auditEvidenceText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditEvidence, { auditId: auditId.toString() }),
        isSelected: !!useMatch(UrlRoutes.AuditEvidence.urlTemplate),
      }, {
        key: "auditReport",
        node: auditReportsText,
        isLink: true,
        linkUrl: formatRoute(UrlRoutes.AuditReport, { auditId: auditId.toString() }),
        isSelected: !!useMatch(UrlRoutes.AuditReport.urlTemplate),
      }].filter(x => x.key !== "findings"
        || doesScoringSystemAllowFindings(scoringSystem))}
    />

    {!!pageControls?.length && (
      <FlexRow
        className="controls"
      >
        <React.Fragment>
          {pageControls.map(control =>
            <div
              key={control.key}
            >
              {control.element}
            </div>
          )}
        </React.Fragment>
      </FlexRow>
    )}
  </div>)};

export default AuditNav;
