import React, { useContext } from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import dragIcon from "shared/media/dls/drag.svg";
import QISchedulerContext from "../QISchedulerContext";
import { IQISchedulerItem, qiSchedulerBaseColWidth, qiSchedulerBaseItemHeight } from "../qiSchedulerTypes";
import QISchedulerItemGrip from "./scheduler-item-grip/QISchedulerItemGrip";

interface IQISchedulerItemProps {
  /** The scheduler item to render. */
  item: IQISchedulerItem<any>,
  /** Determines if this item should be slightly transparent. */
  isTransparent: boolean,
}

const QISchedulerItem: React.FC<IQISchedulerItemProps> = ({
  item,
  isTransparent,
}: IQISchedulerItemProps) => {
  const ctx = useContext(QISchedulerContext);
  const rowData = ctx?.rowItemDictionary[item.rowId];

  if (!ctx
    || !rowData) {
    return null;
  }

  // Sum the heights of all previous rows to determine the Y-offset of this row.
  const rowYPosition = Object.entries(ctx.rowItemDictionary)
    .filter(x => (x[1]?.rowIndex ?? 0) < rowData.rowIndex)
    .map(x => x[1]?.rowHeight ?? 0)
    .reduce((partialSum, x) => partialSum + x, 0);
  const itemStart = new Date(item.startDate);
  itemStart.setHours(0, 0, 0, 0);

  const startDateItem = ctx.dates.find(x => x.date.getTime() === itemStart.getTime());

  const yPosition = (rowYPosition) + ((item.rowSlot ?? 0) * qiSchedulerBaseItemHeight) + ((item.rowSlot ?? 0) * 2);
  let xPosition = (startDateItem?.index ?? 0) * qiSchedulerBaseColWidth;
  let width = qiSchedulerBaseColWidth;

  const startsBeforeDateRange = item.startDate.getTime() < ctx.dates[0].date.getTime();
  const endsAfterDateRange = item.endDate.getTime() > ctx.dates[ctx.dates.length - 1].date.getTime();

  if (!startsBeforeDateRange
    && !endsAfterDateRange) {
    width = getNumDays(item.startDate, item.endDate) * qiSchedulerBaseColWidth;
  } else if (startsBeforeDateRange
    && endsAfterDateRange) {
    width = getNumDays(ctx.dates[0].date, ctx.dates[ctx.dates.length - 1].date) * qiSchedulerBaseColWidth + qiSchedulerBaseColWidth;
  } else if (startsBeforeDateRange) {
    width = getNumDays(ctx.dates[0].date, item.endDate) * qiSchedulerBaseColWidth;
  } else if (endsAfterDateRange) {
    width = getNumDays(item.startDate, ctx.dates[ctx.dates.length - 1].date) * qiSchedulerBaseColWidth + qiSchedulerBaseColWidth;
  }

  const itemContent = (
    <div
      className="qi-scheduler-item-content"
      onClick={e => ctx.onItemClick(item, e)}
    >
      {ctx.renderSchedulerItemContent(item)}
    </div>
  );

  return (
    <div
      className={`qi-scheduler-item ${ctx.itemClassNameMapper?.(item)} no-text-select ${isTransparent ? "transparent" : ""} ${!item.isSelectable && isTransparent ? "unselectable" : ""}`}
      style={{
        width: width,
        top: yPosition,
        left: xPosition,
      }}
    >
      {!startsBeforeDateRange &&
        <QISchedulerItemGrip
          side="left"
          item={item}
        />
      }

      {ctx.renderSchedulerItemTooltip
        ? (
          <TooltipElement
            tooltipContent={ctx.renderSchedulerItemTooltip(item)}
          >
            {itemContent}
          </TooltipElement>
        ) : itemContent}

      {item.isDraggable &&
        <div
          className="grip-top draggable"
          data-scheduler-item-id={item.id}
        >
          <img
            src={dragIcon}
            alt=""
          />
        </div>
      }

      {!endsAfterDateRange &&
        <QISchedulerItemGrip
          side="right"
          item={item}
        />
      }
    </div>
  );
};

export default QISchedulerItem;

function getNumDays(startDate: Date, endDate: Date) {
  // Return number of ms between start/end dates divided by the number of ms in a day.
  return (endDate.getTime() - startDate.getTime()) / 86400000;
}
