import React from "react";
import MultilineText from "shared/components/common/multiline-text/MultilineText";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import pluralize from "shared/utilities/pluralize";
import { IAuditQuestion } from "types/auditingTypes";

interface IViewQuestionModalProps {
  questions: IAuditQuestion[],
  onClose(): void,
}

const ViewQuestionModal: React.FC<IViewQuestionModalProps> = ({
  questions,
  onClose,
}) => {
  const modalHeader = questions.length > 1
    ? `${questions.length} ${pluralize(questions.length, "Question", "Questions")}`
    : `Question #${questions[0].questionNumber}`;

  return (
    <Modal
      header={modalHeader}
      showCloseButton
      onCloseButtonClicked={onClose}
      isOpen={true}
      buttons={[{
        key: "CLOSE",
        text: "Close",
        className: "primary",
        onClick: onClose,
      }]}
    >
      <FlexCol
        style={{ gap: "1em" }}
      >
        {questions.map(question => (
          <Card
            key={question.auditQuestionId}
            title={`Question #${question.questionNumber}`}
          >
            <FlexCol
              style={{ gap: "1em" }}
            >
              <LabeledControl
                label="Question"
              >
                <div>
                  <MultilineText
                    text={question.question}
                  />
                </div>
              </LabeledControl>

              <LabeledControl
                label="Guidance"
              >
                <div>
                  <MultilineText
                    text={question.guidance}
                  />
                </div>
              </LabeledControl>
            </FlexCol>
          </Card>
        ))}
      </FlexCol >
    </Modal >
  );
};

export default ViewQuestionModal;