import config from "config";
import React from "react";
import { IDataGridColumn, IDataGridFilterSettings } from "shared/components/layout/grid/types/dataGridTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { ISupplier } from "types/masterDataTypes";
import ODataPicker from "../odata-picker/ODataPicker";

interface ISupplierPickerProps {
  /** The callback used when the user applies their selection in the picker. */
  onSelectionChange: (items: ISupplier[]) => void,
  /** The list of already-selected items. */
  selectedItems: ISupplier[],
  /** Optional.
   * If true, deleted/inactive projects will be included in the search results.
   * Default = false. */
  includeDeleted?: boolean,
  /** Determines if the picker is disabled. Default = false. */
  isDisabled?: boolean,
  /** Optional. The selection mode (single or multiple). Default = "Single" */
  selectMode?: "Single" | "Multiple",
}

const SupplierGridColumns: IDataGridColumn[] = [
  { uid: "id", field: "id", headerText: "Id", type: "number", width: 130, visible: false, },
  { uid: "aslNumber", field: "aslNumber", headerText: "ASL Number", type: "number", width: 75, },
  { uid: "name", field: "name", headerText: "Name", type: "string", width: 300, },
  { uid: "parentASLNumber", field: "parentASLNumber", headerText: "Parent ASL Number", type: "number", width: 175, },
  { uid: "parentName", field: "parentName", headerText: "Parent Name", type: "string", width: 300, },
  { uid: "countryISO", field: "countryISO", headerText: "Country ISO", type: "string", width: 175, },
  { uid: "city", field: "city", headerText: "City", type: "string", width: 200, },
  { uid: "category", field: "category", headerText: "Category", type: "string", width: 300, },
  { uid: "subCategory", field: "subCategory", headerText: "SubCategory", type: "string", width: 300, },
  { uid: "deleted", field: "deleted", headerText: "Is Deleted", type: "boolean", width: 75, visible: false, },
];

const SupplierPicker: React.FC<ISupplierPickerProps> = (props) => {
  const filterSettings: IDataGridFilterSettings = {
    columns: [
      !props.includeDeleted
        ? {
          uid: "deleted",
          field: "deleted",
          matchCase: false,
          operator: "equal",
          value: false,
        } : undefined
    ].filter(isNotUndefined)
  };

  return (
    <ODataPicker<ISupplier>
      keyMapper={item => item.id}
      oDataUrl={`${config.endpoints.baseUrl}/odata/Suppliers`}
      onSelectionChange={items => props.onSelectionChange(items)}
      selectedItems={props.selectedItems}
      renderSelectedItem={item => `${item.aslNumber} - ${item.name}`}
      selectMode={props.selectMode || "Single"}
      title={"Suppliers"}
      sortSelectedItems={(a, b) => a.aslNumber < b.aslNumber ? -1 : 1}
      sortSettings={{
        columns: [{
          field: "aslNumber",
          direction: "Ascending",
        }]
      }}
      gridColumns={SupplierGridColumns}
      className="Supplier-grid-picker"
      filterSettings={filterSettings}
      isDisabled={props.isDisabled}
      maxSelectedItemsVisible={1}
    />
  );

};

export default SupplierPicker;