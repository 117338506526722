import { useMsal } from "@azure/msal-react";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Page from "shared/components/layout/page/Page";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { askDeleteAudit, loadAudit, resetAudit, saveAudit } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";
import AuditPage from "../common/audit-page/AuditPage";
import "./ManageAuditPage.scoped.scss";
import AssociationsCard from "./associations-card/AssociationsCard";
import ManageAuditDetailsCard from "./audit-details/ManageAuditDetailsCard";
import ConfirmDeleteAuditModal from "./confirmation-modals/ConfirmDeleteAuditModal";
import ConfirmDeleteQuestionsAuditModal from "./confirmation-modals/ConfirmDeleteQuestionsModal";
import IncludeAPICard from "./include-api-card/IncludeAPICard";
import TopicFiltersCard from "./topic-filters-card/TopicFiltersCard";
import YourSelectionsCard from "./your-selections/YourSelectionsCard";

interface IManageAuditPageProps {
  mode: "edit" | "new",
}

export interface IManageAuditEditOptions {
  allowEditOnlyLeadAuditor: boolean,
}

const ManageAuditPage: React.FC<IManageAuditPageProps> = ({ mode }) => {
  const audit = useAppSelector(store => store.manageAudit.audit);
  const originalAudit = useAppSelector(store => store.manageAudit.originalAudit);
  const loadAuditOperation = useAppSelector(store => store.manageAudit.loadAuditOperation);
  const loadDetailedTemplateInfoOperation = useAppSelector(store => store.manageAudit.loadDetailedTemplateInfoOperation);
  const saveAuditOperation = useAppSelector(store => store.manageAudit.saveAuditOperation);
  const deleteAuditOperation = useAppSelector(store => store.manageAudit.deleteAuditOperation);
  const isDirty = useAppSelector(store => store.manageAudit.isDirty);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false;
  const auditId = audit.id;
  const navigate = useNavigate();
  const allowEditOnlyLeadAuditor = audit.status === AuditStatuses.Completed;

  useEffect(() => {
    // Check if an audit has been created but the user
    // is still in NEW mode. If so, redirect the user
    // to the EDIT mode instead.
    if (auditId
      && mode === "new") {
      navigate(formatRoute(UrlRoutes.EditAudit, { auditId: auditId.toString() }), {
        replace: true,
      });
    }
  }, [auditId, mode, navigate]);

  const dispatch = useAppDispatch();
  const {
    auditId: paramsId,
  } = useParams();

  const { accounts } = useMsal();
  const user = accounts[0];

  useEffect(() => {
    if (paramsId !== undefined) {
      dispatch(loadAudit(Number(paramsId)));
    } else {
      let userToSet: IAzureADUser | undefined = undefined;

      if (user
        && user.username
        && (user as any).name) {
        userToSet = {
          email: user.username,
          name: (user as any).name,
        };
      }

      dispatch(resetAudit(userToSet));
    }
  }, [paramsId, user, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAudit());
    };
  }, [dispatch]);

  const id = audit.id;
  const headerButtons: IPageHeaderControl[] = [];

  headerButtons.push({
    key: "CANCEL",
    element: (
      <button
        className="secondary"
        onClick={() => mode === "new"
          ? navigate(UrlRoutes.MyAudits.urlTemplate)
          : navigate(formatRoute(UrlRoutes.AuditSummary, { auditId: id.toString() }))
        }
      >
        Cancel
      </button>
    ),
  });

  if (id
    && hasEditPermission) {
    headerButtons.push({
      key: "DELETE",
      element: (
        <button
          className="red"
          onClick={() => dispatch(askDeleteAudit(id))}
        >
          Delete Audit
        </button>
      ),
    });
  }

  if (hasEditPermission) {
    headerButtons.push({
      key: "SAVE",
      element: (
        <button
          className="primary"
          onClick={() => dispatch(saveAudit({
            allowDeletingAnsweredQuestions: false,
          }))}
          disabled={!isDirty}
          title={tooltipMessage(!isDirty)}
        >
          Save
        </button>
      ),
    });
  }

  const centerNodeAuditLinkInfo =
    <Banner type={BannerType.info}>
      You are creating a manual audit which may not link to a plan based on the dimensions you have chosen.
      If you would like to create an audit linked to a plan, visit the&nbsp;
      <strong><Link className="bannerLink" to={UrlRoutes.PlanVsResults.urlTemplate}>Plan Vs Results page.</Link></strong>
    </Banner>;

  let pageContent: React.ReactNode | undefined = undefined;

  if (loadAuditOperation?.isWorking) {
    pageContent = (
      <Spinner />
    );
  } else if (loadAuditOperation?.errorMessage) {
    pageContent = (
      <Banner
        type={BannerType.error}
      >
        {loadAuditOperation.errorMessage}
      </Banner>
    );
  } else {
    pageContent = (
      <>
        <FlexRow
          className="main-row"
        >
          <FlexCol
            className="filters-col"
          >
            <ManageAuditDetailsCard showStatus={mode !== "new"} allowEditOnlyLeadAuditor={allowEditOnlyLeadAuditor} />
            <TopicFiltersCard showStatus={mode !== "new"} allowEditOnlyLeadAuditor={allowEditOnlyLeadAuditor} />
            <AssociationsCard showStatus={mode !== "new"} allowEditOnlyLeadAuditor={allowEditOnlyLeadAuditor} />

          </FlexCol>
          <FlexCol
            className="topics-col"
          >
            <YourSelectionsCard allowEditOnlyLeadAuditor={allowEditOnlyLeadAuditor} />
            <IncludeAPICard allowEditOnlyLeadAuditor={allowEditOnlyLeadAuditor} />
          </FlexCol>
        </FlexRow>
        {(loadAuditOperation?.isWorking
          || saveAuditOperation?.isWorking
          || deleteAuditOperation?.isWorking
          || loadDetailedTemplateInfoOperation?.isWorking) &&
          <ModalSpinner />
        }
        <ConfirmDeleteAuditModal />
        <ConfirmDeleteQuestionsAuditModal />
        <Prompt
          isDirty={isDirty}
          message="Any unsaved changes will be lost. Are you sure you want to leave?"
        />
      </>
    );
  }

  if (paramsId) {
    return (
      <AuditPage
        title={originalAudit.recap && originalAudit.name
          ? `${originalAudit.recap}-${originalAudit.name}`
          : "Update Audit"
        }
        backButtonRoute={UrlRoutes.MyAudits.urlTemplate}
        pageControls={!loadAuditOperation?.isWorking
          && !loadAuditOperation?.errorMessage
          ? headerButtons
          : undefined
        }
        auditId={paramsId}
        overviewSettings={{
          showComplianceScore: true,
          showQuestionCompletionPercent: true,
          auditStatusOverride: originalAudit.status,
        }}
      >
        {pageContent}
      </AuditPage>
    );
  } else {
    return (
      <Page
        title={mode === "new"
          ? "Create New Audit"
          : "Update Audit"
        }
        backButtonRoute={UrlRoutes.MyAudits.urlTemplate}
        headerControls={!loadAuditOperation?.isWorking
          && !loadAuditOperation?.errorMessage
          ? headerButtons
          : undefined
        }
        centerNode={mode === "new" && centerNodeAuditLinkInfo}
      >
        {pageContent}
      </Page>
    );
  }
};

export default ManageAuditPage;
