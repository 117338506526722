import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditGroupPicker from "shared/components/controls/pickers/AuditGroupPicker";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import OwnerGroupPicker from "shared/components/controls/pickers/OwnerGroupPicker";
import TemplatePicker from "shared/components/controls/pickers/TemplatePicker";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { deleteTemplate, saveTemplate, setDetailedTemplatesChildren, setDetailedTopicsChildren, setOriginalTemplatesTopics, setTemplateProperties, toggleMgmtModal, toggleTemplateMandatorySelection } from "store/audit-templates/AuditTemplatesSlice";
import { useAppSelector } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { IOwnerGroup, ITemplate } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import "./AuditTemplateMgmtModal.scoped.scss";
import AuditTemplateChildSelectionModal from "./template-child-mandatory-selection/AuditTemplateChildMandatorySelectionModal";

const AuditTemplateMgmtModal: React.FC = () => {
  const template = useAppSelector((store) => store.auditTemplates.template);
  const templateName = useAppSelector((store) => store.auditTemplates.originalTemplate?.name);
  const ownerGroups = useAppSelector((store) => store.auditTemplates.masterData.ownerGroups);
  const auditGroups = useAppSelector((store) => store.auditTemplates.masterData.auditGroups);
  const isDirty = useAppSelector((store) => store.auditTemplates.isDirty);
  const isSaveOp = useAppSelector((store) => store.auditTemplates.saveOp);
  const isDeleteOp = useAppSelector((store) => store.auditTemplates.deleteOp);
  const isTemplateChildSelectionModalOpen = useAppSelector(store => store.auditTemplates.isTemplateChildSelectionModalOpen);
  const topics = useAppSelector((store) => store.auditTemplates.auditTopics);
  const selectedTemplates = useAppSelector((store) => store.auditTemplates.selectedTemplates);

  const dispatch = useDispatch();

  const [auditTopicsSelected, setAuditTopicsSelected] = useState<IAuditTopic[]>([]);
  const [auditTemplatesSelected, setAuditTemplatesSelected] = useState<(ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[]>([]);
  const [selectedOwnerGroup, setSelectedOwnerGroup] = useState<IOwnerGroup>();

  let header = templateName ?? "New Template";

  const orgTemplateChildren = template?.children;
  const orgTemplateOwnerGroupId = template?.ownerGroupId;

  useEffect(() => {
    if (orgTemplateChildren) {
      let tempChildrenClone = cloneDeep(orgTemplateChildren);

      let originaltemplates = (tempChildrenClone
        .filter(x => x.masterDataType === MetaDataTypes.AuditTemplate) as IDetailedTemplateChildren[]);
      originaltemplates.forEach(x => x.id = x.masterDataId);
      setAuditTemplatesSelected(originaltemplates);

      const templateTopics = checkChildren(orgTemplateChildren?.filter(x => x.masterDataType === MetaDataTypes.AuditTopic)).map(x => x.masterDataId);
      const originalTopics = topics.filter(x => templateTopics?.includes(x.id));
      setAuditTopicsSelected(originalTopics);

      const ownerGroup = ownerGroups.filter(x => x?.id === orgTemplateOwnerGroupId);
      setSelectedOwnerGroup(ownerGroup[0]);

      dispatch(setOriginalTemplatesTopics({
        templates: originaltemplates as (IDetailedTemplate | IDetailedTemplateChildren)[],
        topics: originalTopics
      }));
    }
  }, [orgTemplateChildren, orgTemplateOwnerGroupId, topics, ownerGroups, dispatch]);

  const onClose = () => dispatch(toggleMgmtModal({ toogleOp: false }));
  const isWorking: boolean = !!((isSaveOp?.isWorking)
    || isDeleteOp?.isWorking);

  const isNextButtonDisabled = !isDirty
    || isWorking
    || !(template?.name
      && template?.ownerGroupId
      && template.auditGroupId
      && (auditTopicsSelected.length > 0
        || auditTemplatesSelected.length > 0));

  let modalButtons = [
    {
      key: "Delete",
      text: "Delete",
      className: "red",
      onClick: () => dispatch(deleteTemplate()),
      disabled: isWorking,
    },
    {
      key: "Cancel",
      text: "Cancel",
      className: "secondary",
      onClick: onClose,
    },
    {
      key: "Next",
      text: (isNextButtonDisabled || selectedTemplates.length > 0)
        ? "Next"
        : "Save",
      className: "primary",
      onClick: () => {
        selectedTemplates.length > 0
          ? dispatch(toggleTemplateMandatorySelection({ toogleOp: true }))
          : dispatch(saveTemplate({
            template,
            selectedTemplates: [],
            selectedTopics: auditTopicsSelected
          }));
      },
      disabled: isNextButtonDisabled,
    }
  ];

  return (
    <Modal
      isOpen={true}
      showCloseButton={true}
      header={header}
      onCloseButtonClicked={onClose}
      minWidth="60%"
      maxWidth="60%"
      buttons={modalButtons.filter(x => template?.id || x.key !== "Delete")}
    >
      <FlexRow className="row">
        <LabeledControl label="Template Name"
          isRequired={true}
          className="row-item">
          <input
            type="text"
            value={template?.name}
            onChange={(e) =>
              dispatch(
                setTemplateProperties({ name: e.currentTarget.value })
              )
            }
          />
        </LabeledControl>

        <LabeledControl label="Audit Group"
          isRequired={true}
          className="row-item">
          <AuditGroupPicker
            selectedItems={auditGroups.filter(x => x.id === template?.auditGroupId)}
            allowMultiselect={false}
            isDisabled={template?.id
              ? true
              : false
            }
            onApply={(items) => {
              dispatch(setTemplateProperties({
                auditGroupId: items[0]?.id,
              }));
            }}
            renderMode="dropdown"
          />
        </LabeledControl>

        <LabeledControl label="Owner Group"
          isRequired={true}
          className="row-item">
          <OwnerGroupPicker
            selectedItems={ownerGroups.filter(x => x.id === template?.ownerGroupId)}
            allowMultiselect={false}
            isDisabled={template?.id ? true : false}
            onApply={(items) => {
              dispatch(
                setTemplateProperties({ ownerGroupId: items[0]?.id })
              );
              setSelectedOwnerGroup(items[0]);

              if (items[0]?.scoringSystem !== selectedOwnerGroup?.scoringSystem) {
                const templatesMatchingScoringSystem = auditTemplatesSelected
                  .filter(x => (x as IDetailedTemplate).scoringSystem === items[0]?.scoringSystem);

                dispatch(setDetailedTemplatesChildren(templatesMatchingScoringSystem as (IDetailedTemplate | IDetailedTemplateChildren)[]));
                setAuditTemplatesSelected(templatesMatchingScoringSystem);

                dispatch(setDetailedTopicsChildren([]));
                setAuditTopicsSelected([]);
              }
            }}
            renderMode="dropdown"
          />
        </LabeledControl>

        <LabeledControl label="Plannable" className="plannable-section">
          <label>
            <input
              type="checkbox"
              checked={template?.isPlannable || false}
              onChange={e => dispatch(setTemplateProperties({ isPlannable: e.currentTarget.checked }))}
            />
            Yes
          </label>
        </LabeledControl>
      </FlexRow>

      <div className="template-picker">
        <TemplatePicker
          isRequired={false}
          renderMode="list"
          allowMultiselect={true}
          selectedItems={auditTemplatesSelected}
          onApply={(items) => {
            dispatch(setDetailedTemplatesChildren(items as (IDetailedTemplate | IDetailedTemplateChildren)[]));
            setAuditTemplatesSelected(items);
          }}
          isItemDisabledMapper={(item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren) => {
            if (item.id === template?.id) {
              return true;
            }

            if ((item as IDetailedTemplate).scoringSystem !== selectedOwnerGroup?.scoringSystem) {
              return true;
            }

            if (template?.scoringSystem === (item as IDetailedTemplate).scoringSystem) {
              return false;
            }
            return false;

          }}
        />
      </div>

      <br></br>
      <AuditTopicPicker
        selectedItems={auditTopicsSelected}
        isRequired={false}
        onApply={(items) => {
          dispatch(setDetailedTopicsChildren(items));
          setAuditTopicsSelected(items);
        }}
        isItemDisabledMapper={(item: IAuditTopic, ancestorPath: IAuditTopic[]) => {

          if (item.scoringSystem === selectedOwnerGroup?.scoringSystem) {
            return false;
          }

          if (item.scoringSystem !== template?.scoringSystem) {
            return true;
          }

          if (item.scoringSystem !== selectedOwnerGroup?.scoringSystem) {
            return true;
          }
          return false;
        }}
        renderMode="list"
        allowMultiselect
      />

      {isTemplateChildSelectionModalOpen
        && selectedTemplates.length > 0
        && <AuditTemplateChildSelectionModal />}
    </Modal>
  );
};

function checkChildren(children: IDetailedTemplateChildren[] | undefined) {
  let childrenToAdd: IDetailedTemplateChildren[] = [];

  children?.forEach(c => {
    if (c.children.length > 0) {
      if (c.id !== 0) {
        childrenToAdd.push(c);
      }

      childrenToAdd.push(...checkChildren(c.children));
    } else {
      childrenToAdd.push(c);
    }
  });

  return childrenToAdd;
}

export default AuditTemplateMgmtModal;