import AuditsApi from "api/auditing/AuditsApi";
import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import AuditTopicApi, { IAuditTopicSearchCriteria } from "api/masterdata/AuditTopicApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import SearchAuditorsApi from "api/users/SearchAuditorsApi";
//import UsersApi from "api/users/UsersApi";
import TemplatesApi from "api/auditing/templates/TemplatesApi";
import { history } from "App";
import UrlRoutes from "components/routing/UrlRoutes";
import { uniq, uniqBy } from "lodash";
import { Action } from "redux";
import { all, call, put, race, select, take, TakeEffect, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import pluralize from "shared/utilities/pluralize";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { loadQuestions, setAudit } from "store/audit/AuditSlice";
import { loadAndSetSuggestedPickerItems } from "store/common/sagas/commonSagas";
import { mapBusinessFunctionToPickerItem, mapBusinessTeamToPickerItem, mapBusinessViewToPickerItem } from "store/common/sagas/pickerSagas";
import { RootState } from "store/store";
import { AuditorSearchTypes, IAudit, ICreateAuditResponse, IOwnerGroup, ITemplate } from "types/auditingTypes";
import { AuditTopicSearchTypes, IAuditGroup, IAuditTopic, IAuditType } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, IFacility, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import { applyAuditTopicSelections, applyTemplateSelections, deleteAudit, finishDeletingAudit, finishLoadingAudit, finishSavingAudit, IManageAuditState, loadAudit, loadPickerItems, loadSuggestedPickerItems, ManageAuditPickerKeys, removeAuditTopic, removeTemplate, resetAudit, saveAudit, setAuditProperties, setDefaultSelectedPickerItems, setDetailedTemplateInfo, setLoadDetailedTemplateInfoOperation, setPickerError, setPickerItems, setRecap, setSelectedPickerItems, setSuggestedPickerItems } from "./ManageAuditSlice";


export default function* manageAuditSagas() {
  yield all([
    loadPickerItemsAsync(),
    loadSuggestedPickerItemsAsync(),
    watchLoadAudit(),
    watchSaveAudit(),
    watchDeleteAudit(),
    watchSetSelectedPickerItems(),
    watchResetAudit(),
    removeAuditTopicAsync(),
    removeTemplateAsync(),
    applyTemplateSelectionsAsync(),
    applyAuditTopicSelectionsAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeEvery(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const {
      pickerKey,
      searchValue,
    } = action.payload;

    try {
      switch (pickerKey) {
        case ManageAuditPickerKeys.auditTypes:
          yield retrieveAndPutPickerData(MasterDataApi.getAuditTypes,
            (item): IPickerItem<IAuditType> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            false);
          break;
        case ManageAuditPickerKeys.auditGroups:
          yield retrieveAndPutPickerData(() => MasterDataApi.getAuditGroups(),
            (item): IPickerItem<IAuditGroup> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            true);
          break;
        case ManageAuditPickerKeys.businessViews:
          yield retrieveAndPutPickerData(MasterDataApi.getBusinessViews,
            (item): IPickerItem<IBusinessView> => mapBusinessViewToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          break;
        case ManageAuditPickerKeys.businessFunctions:
          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessFunctions(),
            (item): IPickerItem<IBusinessFunction> => mapBusinessFunctionToPickerItem(item),
            pickerKey,
            false,
            searchValue);
          break;
        case ManageAuditPickerKeys.businessTeams:
          // Auto expand global ops.
          const globalOpsId = 84;

          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessTeams(),
            (item): IPickerItem<IBusinessTeam> => {
              const pickerItem = mapBusinessTeamToPickerItem(item);
              pickerItem.isExpanded = item.id === globalOpsId;
              return pickerItem;
            },
            pickerKey,
            false,
            searchValue);
          break;
        case ManageAuditPickerKeys.leadAuditors:
          yield retrieveAuditors(pickerKey, searchValue || "");
          break;
        case ManageAuditPickerKeys.countries:
          // Get any selected countries first.
          const selectedFacilities: IFacility[] = yield select((store: RootState) =>
            store
              .manageAudit
              .audit
              .facilities);

          // Extract their ids.
          const selectedFacilityIds = selectedFacilities
            .map(x => x.id)
            .filter(isNotUndefined);

          yield retrieveAndPutPickerData((searchTerm) => MasterDataApi.getCountries(searchTerm, selectedFacilityIds),
            (item): IPickerItem<ICountry> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            false,
            searchValue);
          break;
        case ManageAuditPickerKeys.ownerGroups:
          yield retrieveAndPutPickerData(OwnerGroupsApi.getOwnerGroups,
            (item): IPickerItem<IOwnerGroup> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            false,
            searchValue);
          break;
        case ManageAuditPickerKeys.auditTopics:
          yield retrieveAndPutTopics();
          break;
        default:
          throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
      }
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  pickerKey: string,
  selectFirstIfOnlyOne: boolean,
  searchValue?: string) {
  const rawItems: T[] = yield call(apiMethod, searchValue);
  const items = rawItems.map(itemMapper);
  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  if (items.length === 1
    && selectFirstIfOnlyOne) {
    yield put(setSelectedPickerItems({
      pickerKey,
      selectedItems: items,
    }));

    yield put(setDefaultSelectedPickerItems({
      pickerKey,
      selectedItems: items,
    }));
  }

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* retrieveAndPutTopics() {
  const searchCriteria: IAuditTopicSearchCriteria | undefined = yield getTopicSearchCriteria();

  const items: IAuditTopic[] = yield call(AuditTopicApi.searchAuditTopics, searchCriteria);

  // Take unique owner groups from items
  const uniqueOwnerGroups = items
    .map(auditTopicItem => auditTopicItem.ownerGroup || "")
    .filter((value, index, self) => self.indexOf(value) === index);

  let countKey = 1000;

  // OwnerGroup must be added at the top level.
  uniqueOwnerGroups
    .sort((a, b) => a < b ? -1 : 1);

  let pickerItems: IPickerItem<IAuditTopic>[] = uniqueOwnerGroups
    .map(ownerGroup => {
      const parentTopic: IPickerItem<IAuditTopic> = {
        item: {
          id: countKey,
          auditGroupId: 0,
          auditGroup: "_",
          level: 0,
          parentId: countKey,
          name: ownerGroup,
          groupName: "_",
          isSelectable: false,
          sortOrder: countKey,
          isDeleted: false,
          isPlannable: true,
          ownerGroupId: 1,
          ownerGroup: "_",
          scoringSystem: "",
        },
        key: countKey,
        text: ownerGroup,
        disabled: true,
        children: [],
      };
      countKey += 1;
      return parentTopic;
    });

  if (searchCriteria?.searchType !== AuditTopicSearchTypes.AuditGroup) {
    // For templates and owner groups, some returned subtopics might not have any parents.
    items.filter(x => !!x.parentId
      && !items.some(i => i.id === x.parentId)
      && !x.isDeleted)
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .forEach(subTopic => {
        pickerItems.push({
          item: subTopic,
          key: subTopic.id,
          disabled: false,
          children: [],
        });
      });
  }

  // Build a tree out of the items.
  // Map all the children -  group by owner group
  pickerItems.forEach(topicOwnerGroup => {
    const topicChildren = items
      .filter(x => x.ownerGroup === topicOwnerGroup.item?.name
        && !x.isDeleted
        && x.level === 1)
      .sort((a, b) => a.name < b.name ? -1 : 1)
      .map((itemByOwnerGroup): IPickerItem<IAuditTopic> => ({
        item: itemByOwnerGroup,
        key: itemByOwnerGroup.id,
        disabled: false,
        children: [],
      }));
    topicOwnerGroup.children = topicChildren;
  });

  // Map all other the children - Level 0 from Topics(topicParentLevel)
  pickerItems.forEach(topicOwnerGroup => {
    topicOwnerGroup.children?.forEach(topicParentLevel => {
      const topicChildren = items
        .filter(x => x.parentId === topicParentLevel.key
          && !x.isDeleted)
        .sort((a, b) => a.name < b.name ? -1 : 1)
        .map((topic): IPickerItem<IAuditTopic> => ({
          item: topic,
          key: topic.id,
          disabled: false,
          children: [],
        }));

      if (!topicParentLevel.isSelectable) {
        const auditTopicGroupNameList = new Map();
        topicChildren.forEach((item: IPickerItem<IAuditTopic>) => {
          auditTopicGroupNameList.set(item.item?.groupName, {
            key: item.item?.parentId,
            groupName: item.item?.groupName,
            auditGroup: item.item?.auditGroup,
          });
        });

        let countKey = 9999;
        Array.from(auditTopicGroupNameList.values())
          .forEach(auditTopicGroupName => {
            const parentTopic: IPickerItem<IAuditTopic> | undefined = auditTopicGroupName.groupName !== "" ? {
              item: {
                id: countKey + auditTopicGroupName.key,
                auditGroupId: 0,
                auditGroup: auditTopicGroupName.auditGroup,
                level: 0,
                parentId: countKey,
                name: auditTopicGroupName.groupName,
                groupName: auditTopicGroupName.groupName,
                isSelectable: false,
                sortOrder: countKey,
                isDeleted: false,
                isPlannable: true,
                ownerGroupId: 1,
                ownerGroup: "_",
                scoringSystem: "",
              },
              key: countKey + auditTopicGroupName.key,
              text: auditTopicGroupName.groupName,
              disabled: true,
              children: topicChildren.filter(child => child.item?.groupName === auditTopicGroupName.groupName),
            }
              : undefined;

            if (parentTopic) {
              topicParentLevel.children?.push(parentTopic);
            } else if (topicParentLevel.children) {
              const items = topicChildren.filter(child => child.item?.groupName === auditTopicGroupName.groupName);
              topicParentLevel.children.unshift(...items);
            }
            else {
              topicParentLevel.children = topicChildren;
            }
            countKey++;
          });

        topicParentLevel?.children?.sort((a, b) => (!a.item
          || !b.item)
          ? 0
          : a.item.name > b.item.name
            ? 1 : (b.item.name > a.item.name
              ? -1
              : 0));

      } else {
        topicParentLevel.children = topicChildren;
      }
    });
  });

  yield put(setPickerItems({
    items: pickerItems,
    pickerKey: ManageAuditPickerKeys.auditTopics,
  }));

  if (searchCriteria?.searchType === AuditTopicSearchTypes.Template) {
    // When a template is chosen, it must also select all the topics immediately.
    yield put(setSelectedPickerItems({
      selectedItems: pickerItems,
      pickerKey: ManageAuditPickerKeys.auditTopics,
    }));
  }
}

function* retrieveAuditors(pickerKey: string, searchValue: string) {
  const auditTypes: IPickerState<IAuditType> = yield select((store: RootState) =>
    store.manageAudit.pickerData.auditTypes);

  if (!auditTypes.selectedItems.length) {
    throw new Error("An Audit Type must be selected before searching for a lead auditor.");
  }

  let auditTypeSelected: IPickerItem<IAuditType> = auditTypes.selectedItems[0];

  const rawItems: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
    searchValue,
    Number(auditTypeSelected.key),
    AuditorSearchTypes.LeadAuditor);

  const items = rawItems.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* watchLoadAudit() {
  yield takeLatest(loadAudit, loadAuditAsync);
}

function* loadAuditAsync(action: Action) {
  if (!loadAudit.match(action)) {
    return;
  }

  try {
    const result: {
      audit: IAudit,
      cancelled: TakeEffect,
    } = yield race({
      audit: call(AuditsApi.getAudit, action.payload),
      cancelled: take(resetAudit),
    });

    if (result.cancelled) {
      return;
    }

    if (result.audit.templates.length) {
      yield call(ensureDetailedTemplateInfoIsLoaded, result.audit.templates);
    }

    yield put(finishLoadingAudit({
      isWorking: false,
      data: result.audit,
    }));
  } catch (err) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(finishLoadingAudit({
      isWorking: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* watchSetSelectedPickerItems() {
  yield takeEvery([setSelectedPickerItems, setDefaultSelectedPickerItems], function* (action) {
    yield call(updateRecapAsync, action);

    if (setSelectedPickerItems.match(action)
      && action.payload.pickerKey === ManageAuditPickerKeys.auditTopics) {
      // Also handle the topic change event.
      yield put(applyAuditTopicSelections(action.payload.selectedItems.map(x => x.item as IAuditTopic)));
    }
  });
}

function* watchSaveAudit() {
  yield takeLatest(saveAudit, function* (action: Action) {
    if (!saveAudit.match(action)) {
      return;
    }

    // Put together the huge request using data in redux.
    const audit: IAudit = yield select((store: RootState) => store.manageAudit.audit);

    try {
      if (audit.id) {
        // Update Audit on server.
        yield call(AuditsApi.updateAudit, audit, action.payload.allowDeletingAnsweredQuestions);
        yield put(finishSavingAudit({
          auditId: audit.id,
          showQuestionRemovalOverride: false,
          wasSuccessful: true,
        }));
        yield put(showSuccessToast("Audit updated successfully."));

        // After successfully updating the audit, reload the question information
        // for the AuditPage component to show the Overview correctly since some
        // questions might have been removed or added.
        yield put(loadQuestions(audit.id));

        // Also update the modifiedOn timestamp so the step updates.
        yield put(setAudit({
          audit: {
            modifiedOnTime: new Date().getTime(),
          },
          alsoSetOriginal: true,
        }));
      } else {
        // Create Audit on server.
        const creationInfo: ICreateAuditResponse = yield call(AuditsApi.createAudit, audit);
        yield put(finishSavingAudit({
          auditId: creationInfo.auditId,
          showQuestionRemovalOverride: false,
          wasSuccessful: true,
        }));

        if (creationInfo.numLinkedPlans === undefined) {
          yield put(showSuccessToast("Audit created successfully."));
        } else if (creationInfo.numLinkedPlans === 0) {
          yield put(showSuccessToast("Audit created successfully but NOT Linked to an Audit Plan."));
        } else {
          yield put(showSuccessToast(`Audit created successfully and Linked to ${creationInfo.numLinkedPlans} Audit ${pluralize(creationInfo.numLinkedPlans, "Plan", "Plans")}!`));
        }
      }
    } catch (err) {
      if (getResponseErrorMessage(err) === "Your changes will result in one or more answered questions being removed from the audit. To force this, submit the request again with AllowDeletingAnsweredQuestions set to True.") {
        // Need to show the user a confirmation to allow overriding.
        yield put(finishSavingAudit({
          auditId: audit.id,
          showQuestionRemovalOverride: true,
          wasSuccessful: false,
        }));
        return;
      }

      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSavingAudit({
        auditId: audit.id,
        showQuestionRemovalOverride: false,
        wasSuccessful: false,
      }));
    }
  });
}

function* updateRecapAsync(action: Action) {
  const shouldSetOriginal = setDefaultSelectedPickerItems.match(action);

  const {
    pickerData: {
      auditGroups: {
        selectedItems: selectedAuditGroups,
      },
      auditTypes: {
        selectedItems: selectedAuditTypes,
      },
      businessViews: {
        selectedItems: selectedBusinessViews,
      },
      businessTeams: {
        selectedItems: selectedBusinessTeams,
      },
    },
    audit,
  }: IManageAuditState = yield select((store: RootState) => store.manageAudit);

  const startDate = new Date(audit.startDateTime);

  let auditGroup = selectedAuditGroups.map(x => x.text).join(',');
  let auditType = selectedAuditTypes.map(x => x.text).join(',');
  let division = selectedBusinessViews
    .filter(x => x.item?.type === "Division")
    .map(x => x.item?.code)
    .join(',');
  let bl = selectedBusinessViews
    .filter(x => x.item?.type === "BusinessLine")
    .map(x => x.item?.code)
    .join(',');
  let basin = selectedBusinessTeams
    .filter(x => x.item?.type === "Basin")
    .map(x => x.item?.code)
    .join(',');
  let geoUnit = selectedBusinessTeams
    .filter(x => x.item?.type === "GeoUnit")
    .map(x => x.item?.code)
    .join(',');
  let facility = audit.facilities
    .map(x => x.name)
    .join(',');
  let year = startDate?.getFullYear().toString();

  const recap = [
    auditGroup,
    auditType,
    division,
    bl,
    basin,
    geoUnit,
    facility,
    year,
  ].filter(x => x !== "")
    .join("-");

  yield put(setRecap({
    recap,
    shouldSetOriginal,
  }));
}

function* watchDeleteAudit() {
  yield takeEvery(deleteAudit, deleteAuditAsync);
}

function* deleteAuditAsync(action: Action) {
  if (!deleteAudit.match(action)) {
    return;
  }

  try {
    yield call(AuditsApi.deleteAudit, action.payload);

    yield put(resetAudit());

    // Push user to the My Audits screen.
    history.push(UrlRoutes.MyAudits.urlTemplate);

    yield put(showSuccessToast("Audit deleted successfully."));
  } catch (err) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  } finally {
    yield put(finishDeletingAudit());
  }
}

function* watchResetAudit() {
  yield takeLatest(resetAudit, loadDropdownValuesAsync);
}

function* loadDropdownValuesAsync() {
  yield put(loadPickerItems({
    pickerKey: ManageAuditPickerKeys.auditGroups,
  }));
  yield put(loadPickerItems({
    pickerKey: ManageAuditPickerKeys.auditTypes,
  }));
}

function* loadSuggestedPickerItemsAsync() {
  yield takeEvery(loadSuggestedPickerItems, function* (action: Action) {
    if (!loadSuggestedPickerItems.match(action)) {
      return;
    }

    const {
      pickerKey,
    } = action.payload;

    switch (pickerKey) {
      case ManageAuditPickerKeys.leadAuditors:
        // Get the selected audit type first.
        const auditType: IAuditType | undefined = yield select((store: RootState) =>
          store
            .manageAudit
            .audit
            .auditType);

        if (!auditType) {
          setSuggestedPickerItems({
            pickerKey,
            suggestedItems: [],
          });
          return;
        }

        // Call the helper function to load the suggestions and put them into redux.
        yield call<any>(loadAndSetSuggestedPickerItems,
          () => SearchAuditorsApi.getAuditorSuggestions(auditType.id, AuditorSearchTypes.LeadAuditor),
          (user: IAzureADUser): IPickerItem<IAzureADUser> => ({
            key: user.email,
            item: user,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      case ManageAuditPickerKeys.businessTeams:
        yield call<any>(loadAndSetSuggestedPickerItems,
          () => MasterDataApi.getSuggestedBusinessTeams(),
          (item: IBusinessTeam): IPickerItem<IBusinessTeam> => ({
            key: item.id,
            item: item,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      case ManageAuditPickerKeys.countries:
        // Get any selected countries first.
        const selectedFacilities: IFacility[] = yield select((store: RootState) =>
          store
            .manageAudit
            .audit
            .facilities);

        // Extract their ids.
        const selectedFacilityIds = selectedFacilities
          .map(x => x.id)
          .filter(isNotUndefined);

        yield call<any>(loadAndSetSuggestedPickerItems,
          () => MasterDataApi.getSuggestedCountries(selectedFacilityIds),
          (item: ICountry): IPickerItem<ICountry> => ({
            key: item.id,
            text: item.name,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      case ManageAuditPickerKeys.businessViews:
        yield call<any>(loadAndSetSuggestedPickerItems,
          () => MasterDataApi.getSuggestedBusinessViews(),
          (item: IBusinessView): IPickerItem<IBusinessView> => ({
            key: item.id,
            item: item,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      case ManageAuditPickerKeys.businessFunctions:
        yield call<any>(loadAndSetSuggestedPickerItems,
          () => MasterDataApi.getSuggestedBusinessFunctions(),
          (item: IBusinessFunction): IPickerItem<IBusinessFunction> => ({
            key: item.id,
            item: item,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      case ManageAuditPickerKeys.auditTopics:
        const searchCriteria: IAuditTopicSearchCriteria = yield call(getTopicSearchCriteria);

        yield call<any>(loadAndSetSuggestedPickerItems,
          () => AuditTopicApi.getSuggestedAuditTopics(searchCriteria),
          (item: IBusinessFunction): IPickerItem<IBusinessFunction> => ({
            key: item.id,
            item: item,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      default:
        yield put(showErrorToast(`No handler defined for loadSuggestedItems with key ${action.payload.pickerKey}.`));
    }
  });
}

function* getTopicSearchCriteria() {
  const {
    pickerData: {
      auditGroups,
      ownerGroups,
    },
  }: IManageAuditState = yield select((store: RootState) => store.manageAudit);

  const auditGroupId = auditGroups.selectedItems.length > 0
    ? Number(auditGroups.selectedItems[0].key)
    : undefined;
  const ownerGroupIds = ownerGroups.selectedItems.map(x => Number(x.key));

  if (auditGroupId === null
    && ownerGroupIds.length === 0) {
    return undefined;
  }

  let searchType: AuditTopicSearchTypes | undefined = undefined;
  if (ownerGroupIds.length > 0) {
    searchType = AuditTopicSearchTypes.OwnerGroups;
  } else if (auditGroupId !== undefined) {
    searchType = AuditTopicSearchTypes.AuditGroup;
  }

  return {
    searchType,
    auditGroupId,
    ownerGroupIds,
  };
}

function* ensureDetailedTemplateInfoIsLoaded(templates: ITemplate[]) {
  if (!templates.length) {
    return;
  }

  const loadedDetails: IDetailedTemplate[] = yield select((store: RootState) => store.manageAudit.detailedTemplateInfo);

  const templatesToLoad = templates.filter(x => !loadedDetails.some(d => d.id === x.id));

  if (templatesToLoad.length) {
    yield put(setLoadDetailedTemplateInfoOperation({ isWorking: true }));

    const detailedTemplates: IDetailedTemplate[] = yield call(TemplatesApi.getDetailedTemplates, {
      templateIdFilter: templates,
      includeDeleted: true,
    });

    yield put(setLoadDetailedTemplateInfoOperation({ isWorking: false }));
    yield put(setDetailedTemplateInfo(loadedDetails.concat(detailedTemplates)));
  }
}

/** Handles the `removeAuditTopic` action for when the user removes a single Audit Topic from the selections list. */
function* removeAuditTopicAsync() {
  yield takeLatest(removeAuditTopic, function* (action) {
    const manageAuditState: IManageAuditState = yield select((store: RootState) => store.manageAudit);

    // Create an object that will eventually be used to update the audit state.
    const newAuditProperties: Partial<IAudit> = {
      auditTopics: manageAuditState
        .audit
        .auditTopics
        .filter(existing => existing.id !== action.payload.id),
      templates: manageAuditState
        .audit
        .templates,
    };

    const hadFailure: boolean = yield call(calculateTemplatesToRemove,
      newAuditProperties,
      manageAuditState
        .detailedTemplateInfo
        .filter(x => manageAuditState
          .audit
          .templates
          .some(t => t.id === x.id)));

    if (hadFailure) {
      // An error was encountered and a toast message was shown. Do not update the audit.
      return;
    }

    // Remove the audit topic from the audit.
    yield put(setAuditProperties(newAuditProperties));
  });
}

/** Handles the `removeTemplate` action for when the user removes a single Template from the selections list. */
function* removeTemplateAsync() {
  yield takeLatest(removeTemplate, function* (action) {
    const manageAuditState: IManageAuditState = yield select((store: RootState) => store.manageAudit);

    // Create an object that will be used to update the audit state.
    // It will contain the unique list of the existing audit topics combined with the newly applied ones from the templates
    // and the unique list of the existing templates combined with the newly applied ones.
    const newAuditProperties: Partial<IAudit> = {
      templates: manageAuditState
        .audit
        .templates,
      auditTopics: manageAuditState
        .audit
        .auditTopics,
    };

    calculateChildRequirementsToRemove(
      [action.payload.id],
      newAuditProperties,
      manageAuditState
        .detailedTemplateInfo
        .filter(x => manageAuditState
          .audit
          .templates
          .some(t => t.id === x.id)));

    // Update the audit.
    yield put(setAuditProperties(newAuditProperties));
  });
}

/** Handles the `applyTemplateSelections` action for when the user applies a selection from the Template picker. */
function* applyTemplateSelectionsAsync() {
  yield takeLatest(applyTemplateSelections, function* (action) {
    const manageAuditState: IManageAuditState = yield select((store: RootState) => store.manageAudit);

    if (!manageAuditState) {
      yield put(showErrorToast("No audit data is loaded."));
      return;
    }

    // Load the detailed template hierarchies for all applied templates.
    yield call(ensureDetailedTemplateInfoIsLoaded, action.payload.templates);

    // Create an object that will be used to update the audit state.
    // It will contain the unique list of the existing audit topics combined with the newly applied ones from the templates
    // and the unique list of the existing templates combined with the newly applied ones.
    const newAuditProperties: Partial<IAudit> = {
      auditTopics: uniqBy(manageAuditState
        .audit
        .auditTopics
        .concat(action.payload.auditTopics),
        x => x.id),
      templates: uniqBy(manageAuditState
        .audit
        .templates
        .concat(action.payload.templates),
        x => x.id),
    };

    const templatesRemoved = manageAuditState
      .audit
      .templates
      .filter(oldTemplate => !action.payload.templates.some(payloadTemp => payloadTemp.id === oldTemplate.id));

    if (templatesRemoved.length) {
      calculateChildRequirementsToRemove(
        templatesRemoved.map(x => x.id),
        newAuditProperties,
        manageAuditState
          .detailedTemplateInfo
          .filter(x => (newAuditProperties
            .templates ?? [])
            .some(t => t.id === x.id)));
    }

    // Update the audit.
    yield put(setAuditProperties(newAuditProperties));
  });
}

/** Handles the `applyAuditTopicSelections` action for when the user applies a selection from the Audit Topic picker. */
function* applyAuditTopicSelectionsAsync() {
  yield takeLatest(applyAuditTopicSelections, function* (action) {
    const manageAuditState: IManageAuditState = yield select((store: RootState) => store.manageAudit);

    // Create an object that will eventually be used to update the audit state.
    const newAuditProperties: Partial<IAudit> = {
      auditTopics: action.payload,
      templates: manageAuditState
        .audit
        .templates,
    };

    const hadFailure: boolean = yield call(calculateTemplatesToRemove,
      newAuditProperties,
      manageAuditState
        .detailedTemplateInfo
        .filter(x => manageAuditState
          .audit
          .templates
          .some(t => t.id === x.id)));

    if (hadFailure) {
      // An error was encountered and a toast message was shown. Do not update the audit.
      return;
    }

    // Remove the audit topic from the audit.
    yield put(setAuditProperties(newAuditProperties));
  });
}

/** Determines if the list `auditTopicsInAudit` contains any of the auditTopic children of the specified `detailedTemplate`. */
function doesAuditContainAnyAuditTopicsFromTemplate(detailedTemplate: IDetailedTemplate, auditTopicsInAudit: IAuditTopic[]) {
  let reqAuditTopicIds: number[] = [];

  // Define a recursive function for gathering all of the audit's audit topic children.
  const flattenChildren = (children: IDetailedTemplateChildren[]) => {
    children.forEach(child => {
      if (child.masterDataType === MetaDataTypes.AuditTopic) {
        reqAuditTopicIds.push(child.masterDataId);
      } else if (child.masterDataType === MetaDataTypes.AuditTemplate) {
        flattenChildren(child.children);
      }
    });
  };

  // Call it to get the total list.
  flattenChildren(detailedTemplate.children);

  // Unique the list.
  reqAuditTopicIds = uniq(reqAuditTopicIds);

  // Check if at least one of the required audit topic ids exists within the audit.
  return auditTopicsInAudit
    .some(x => reqAuditTopicIds.includes(x.id));
}

/** Receives a partial audit with the new set of auditTopics/templates that will be in the audit and removes any
 * of the templates therein for which the audit contains none of their requirements.
 */
function* calculateTemplatesToRemove(audit: Partial<IAudit>,
  detailedTemplateInfo: IDetailedTemplate[]) {
  if (!audit.templates) {
    // No templates are in the audit. Skip operation.
    return;
  }

  // For each template, ensure that it has at least one of its audit topics still in the audit.
  for (let i = audit.templates.length - 1; i >= 0; i--) {
    const template: ITemplate = audit.templates[i];
    const details = detailedTemplateInfo.find(x => x.id === template.id);

    if (!details) {
      // If, for some reason, this template's detailed info is not loaded, fail and bail.
      yield put(showErrorToast(`Detailed template info for template ${template.name} not found!`));
      return false;
    }

    if (!doesAuditContainAnyAuditTopicsFromTemplate(details, audit.auditTopics ?? [])) {
      // Since none of this template's child audit topics are still in the audit, remove the template
      // from the audit.
      audit.templates = (audit.templates ?? [])
        .filter(x => x.id !== template.id);
    }
  }
}

/** From a list of template Ids that have been removed by the user, calculates which audit templates/auditTopics
 * need to also be removed.
 */
function calculateChildRequirementsToRemove(templateIdsRemoved: number[],
  newAuditProperties: Partial<IAudit>,
  detailedTemplateInfo: IDetailedTemplate[]
) {
  const allPaths = detailedTemplateInfo
    .flatMap(getTemplateChildPaths);

  (newAuditProperties.auditTopics ?? []).forEach(auditTopic => {
    if (allPaths.some(path => path.path.includes(`|A${auditTopic.id}|`))) {
      // This audit topic exists inside one of the templates.
      return;
    }

    // This audit topic does not exist within any of the templates.
    // Add it as its own path.
    allPaths.push({
      id: auditTopic.id,
      path: `|A${auditTopic.id}|`,
      type: MetaDataTypes.AuditTopic,
    });
  });

  // Find all the OTHER template Ids found within any removed template.
  let templateIdsFoundInsideRemovedTemplates = allPaths
    // Remove from allPaths every item whose path contains a removed template first.
    .filter(x => templateIdsRemoved.some(tId => x.path.includes(`|T${tId}|`)))
    .flatMap(x => {
      const chunks = x.path.split('|');
      const firstRemovedTemplateIx = chunks.findIndex(chunk =>
        templateIdsRemoved.some(tId => Number(chunk.substring(1)) === tId));

      if (firstRemovedTemplateIx > -1) {
        return chunks.filter((chunk, ix) => chunk.startsWith("T")
          && ix > firstRemovedTemplateIx);
      } else {
        return chunks.filter(chunk => chunk.startsWith("T"));
      }
    }).map(x => Number(x.substring(1)))
    .filter(x => !templateIdsRemoved.includes(x))
    // Filter to only the template Ids that don't exist in some other template.
    .filter(templateId => !allPaths.some(path =>
      path.path.includes(`|T${templateId}|`)
      && !path.path.startsWith(`|T${templateId}|`)
      && !templateIdsRemoved.some(tId => path.path.includes(`|T${tId}|`))
    ));

  const allTemplateIdsToRemove = templateIdsRemoved.concat(templateIdsFoundInsideRemovedTemplates);

  const pathsLessRemovedTemplates = allPaths
    // Remove from allPaths every item whose path contains a removed template first.
    .filter(x => !allTemplateIdsToRemove.some(tId => x.path.includes(`|T${tId}|`)));

  const auditTopicIdsToKeep = pathsLessRemovedTemplates
    .filter(x => x.type === MetaDataTypes.AuditTopic)
    .map(x => x.id);

  const templateIdsToKeep = pathsLessRemovedTemplates
    .filter(x => x.type === MetaDataTypes.AuditTemplate)
    .map(x => x.id);

  newAuditProperties.auditTopics = newAuditProperties
    .auditTopics
    ?.filter(x => auditTopicIdsToKeep.includes(x.id));

  newAuditProperties.templates = newAuditProperties
    .templates
    ?.filter(x => templateIdsToKeep.includes(x.id));
}

interface IChildPath {
  type: MetaDataTypes.AuditTemplate | MetaDataTypes.AuditTopic,
  id: number,
  path: string,
}

function getTemplateChildPaths(detailedTemplate: IDetailedTemplate) {
  let paths: IChildPath[] = [];

  paths.push({
    type: MetaDataTypes.AuditTemplate,
    id: detailedTemplate.id,
    path: `|T${detailedTemplate.id.toString()}|`,
  });

  function extractor(node: IDetailedTemplateChildren, pathHere: string) {
    const thisPath = `${pathHere}${node.masterDataType === MetaDataTypes.AuditTemplate ? "T" : "A"}${node.masterDataId.toString()}|`;

    paths.push({
      type: node.masterDataType === MetaDataTypes.AuditTemplate
        ? MetaDataTypes.AuditTemplate
        : MetaDataTypes.AuditTopic,
      id: node.masterDataId,
      path: thisPath,
    });

    node.children.forEach(node => extractor(node, thisPath));
  }

  detailedTemplate.children.forEach(node => extractor(node, `|T${detailedTemplate.id.toString()}|`));

  return paths;
}