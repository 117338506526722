import { Action } from "@reduxjs/toolkit";
import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import TemplatesApi from "api/auditing/templates/TemplatesApi";
import AuditPlanningApi from "api/planning/AuditPlanningApi";
import { formatProfileMetaDataSelectedItems } from "api/planning/auditPlanningFormatters";
import { IPlanProfile, IPlanProfileAttribute } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { isEqual, uniqWith } from "lodash";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { flattenDetailedTemplateChildren, isDetailedTemplateChildren, setToReadOnlyItems, setToSelectedItemsForCreating, setToSelectedItemsForEditing, setTotalSelectedItems } from "shared/utilities/hierarchyUtilities";
import pluralize from "shared/utilities/pluralize";
import { IAuditPlanningState, addAttributes, addFacAttrYAxisItem, addProfiles, applyFilters, removeAttributes, removeChildProfiles, upsertAttributes } from "store/audit-planning-shared/AuditPlanningSlice";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import { RootState } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { ICenterAuditAttribute, IPlanProfileAttributeInfo, IPlanProfileAttributeInfoFilterRequest, IProfileParentChildMatrixItem } from "types/auditPlanningTypes";
import { IOwnerGroup, ITemplate } from "types/auditingTypes";
import { IBusinessFunction, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import { ICreateAttributeModal, IFacilityAttributesState, addChildDimensions, addParentDimensions, clearAddChildDimensionOp, clearAddParentDimensionOp, clearCenterAuditAttributeOp, clearCreateAttributeOp, clearEditAttributeOp, clearMarkAttributesReviewedOp, clearRemoveAttributeOp, clearRemoveChildDimensionOp, createAttribute, editAttribute, finishGetScoringSystem, finishLoadProfileParentChildMatrix, finishSaveCenterAuditAttributes, getScoringSystem, loadProfileParentChildMatrix, markAttributesReviewed, removeAttribute, removeChildDimension, saveCenterAuditAttributes, setAddChildDimensionModal, setAddParentDimensionModal, setAttributeIdForCenterCenterAuditAttributes, setCenterAuditAttributeModal, setCenterAuditAttributes, setConfirmRemoveOrEditAttributeModal, setContextPlanProfileAttributeModal, setCreateAttributeModal, setDefaultCreateTemplateSelection, setDefaultMetaData, setEditAttributeModal, setRemoveAttributeModal, setRemoveChildDimensionModal, setTotalSelectedInformationForAttributeModal } from "./FacilityAttributesSlice";

export default function* facilityAttributesSagas() {
  yield all([
    addParentDimensionsAsync(),
    addChildDimensionsAsync(),
    createAttributeAsync(),
    editAttributeAsync(),
    removeAttributeAsync(),
    setCreateAttributeModalAsync(),
    setEditAttributeModalAsync(),
    removeChildDimensionAsync(),
    setConfirmRemoveOrEditAttributeModalAsync(),
    loadProfileParentChildMatrixAsync(),
    markAttributesReviewedAsync(),
    getScoringSistemAsync(),
    setCenterAuditAttributeModalAsync(),
    saveCenterAuditAttributesAsync(),
  ]);
}

function* addParentDimensionsAsync() {
  yield takeLatest(addParentDimensions, function* (action: Action) {
    if (!addParentDimensions.match(action)) {
      return;
    }

    try {
      const state: IAuditPlanningState = yield select((store: RootState) => store.auditPlanning);
      const appliedFilters = state.appliedFilters;

      if (!appliedFilters) {
        throw new Error("No filters are applied. A parent profile cannot be added.");
      }

      const conflictingParents = action.payload.parentItems.filter(x =>
        state.profiles.find(z => !z.parentId
          && z.masterDataId === x.id
          && z.masterDataType === x.type));

      if (conflictingParents.length) {
        // One or more of the selected parents already exists in this location.
        throw new Error(`The following parent profile(s) already exist(s) at this location: ${conflictingParents
          .map(x => x.text)
          .slice()
          .sort((a, b) => a < b ? -1 : 1)
          .join(", ")}`);
      }
      // Get or create each profile on the server.
      const createOrGetProfilesResults: ICreateOrGetProfileResult[] = yield all(
        action
          .payload
          .parentItems
          .map((x): IPlanProfile => ({
            deleted: false,
            id: 0,
            masterDataId: x.id,
            masterDataType: x.type,
            masterDataSubType: x.subType,
            parentId: undefined,
          }))
          .map(x => call(createOrGetProfile, x, action.payload.parentRowMasterDataId, appliedFilters))
      );

      const alreadyExistingItemTexts = createOrGetProfilesResults
        .filter(x => x.alreadyExists)
        .map(x =>
          // Find the matching requested item.
          action.payload.parentItems
            .find(z => z.id === x.profile.masterDataId
              && z.type === x.profile.masterDataType)?.text ?? "UNKNOWN")
        .sort((a, b) => a < b ? -1 : 1);

      if (alreadyExistingItemTexts.length) {
        yield put(showErrorToast(`The following item(s) already exist(s) at the specified location: ${alreadyExistingItemTexts.join(", ")}.`));
      }

      let createdProfiles = createOrGetProfilesResults.map(x => x.profile);

      // Put the created profiles into the store.
      yield put(addProfiles(createdProfiles));

      const creationPairs = action.payload.parentItems.map(x => ({
        parentItem: x,
        profile: createdProfiles.find(z => z.masterDataId === x.id && z.masterDataType === x.type),
      }));

      // Add new Y-Axis items so they appear in the list.
      yield put(addFacAttrYAxisItem({
        items: creationPairs.map((x): IPlanningItem => ({
          id: x.parentItem.id,
          text: x.parentItem.text,
          type: x.parentItem.type as MetaDataTypes,
          subType: x.profile?.masterDataSubType,
          children: [],
          isPlannable: true,
          isExpanded: true,
          // If the user just created this successfully, then they can also edit it.
          doesUserHavePermission: true,
          isWithinPlanningRange: true,
          subGeoUnitId: action.payload.subGeoUnitId,
        })),
        parentItemId: action.payload.parentRowMasterDataId,
        parentItemType: action.payload.parentRowMasterDataType,
      }));

      // Close the modal.
      yield put(setAddParentDimensionModal({ isOpen: false }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation that is running.
      yield put(clearAddParentDimensionOp());
    }
  });
}

function* addChildDimensionsAsync() {
  yield takeLatest(addChildDimensions, function* (action) {
    try {
      const state: IAuditPlanningState =
        yield select((store: RootState) => store.auditPlanning);

      // Find if a profile already exists for the parent item.
      const profiles = state.profiles;
      const parentProfile = profiles.find(x => x.id === action.payload.parentProfileId);

      let parentProfileId: number = parentProfile?.id || 0;

      if (!parentProfileId) {
        // Create a parent profile first.
        const createdParentProfile: IPlanProfile = yield call(AuditPlanningApi.createProfile, {
          id: 0,
          deleted: false,
          masterDataId: action.payload.parentMasterDataId,
          masterDataType: action.payload.parentMasterDataType,
          masterDataSubType: action.payload.parentMasterDataSubType,
          parentId: undefined,
        }, action.payload.subGeoUnitId);

        // Put the created profile into the store.
        yield put(addProfiles([createdParentProfile]));

        // Add new Y-Axis items so they appear in the list.
        yield put(addFacAttrYAxisItem({
          items: [{
            id: action.payload.parentMasterDataId,
            text: action.payload.parentText,
            type: action.payload.parentMasterDataType,
            subType: action.payload.parentMasterDataSubType,
            children: [],
            isPlannable: true,
            isExpanded: true,
            // If the user just created this successfully, then they can also edit it.
            doesUserHavePermission: true,
            isWithinPlanningRange: true,
            subGeoUnitId: action.payload.subGeoUnitId,
          }],
          parentItemId: action.payload.subGeoUnitId,
          parentItemType: MetaDataTypes.BusinessTeam,
        }));

        // Use the Id of the created profile.
        parentProfileId = createdParentProfile.id;
      } else {
        // Check to ensure none of the selected child items already exist
        // as children of this profile.
        const conflictingChildren = action.payload.childItems.filter(x =>
          profiles.find(z => z.parentId === parentProfileId
            && z.masterDataId === x.id
            && z.masterDataType === x.type));

        if (conflictingChildren.length) {
          // One or more of the selected child dimensions already exists in this profile.
          throw new Error(`The following child dimensions already exist as children: ${conflictingChildren
            .map(x => x.code)
            .slice()
            .sort((a, b) => a < b ? -1 : 1)
            .join(", ")}`);
        }
      }

      if (!parentProfileId) {
        yield put(showErrorToast(`No parent profile was found. The child cannot be added.`));
        return;
      }

      // Get the filters that are currently applied so they can be used during creation.
      const appliedFilters: IAuditPlanningFilters = yield select((store: RootState) => store.auditPlanning.appliedFilters);

      // Map all the selected child items into PlanProfiles for creation
      // then map each profile into a call to the createOrGetProfile saga.
      // Finally, yield all and wait for them all to finish simultaneously.
      const createOrGetResults: ICreateOrGetProfileResult[] = yield all(
        action
          .payload
          .childItems
          .map((x): IPlanProfile => ({
            deleted: false,
            id: 0,
            masterDataId: x.id,
            masterDataType: x.type,
            masterDataSubType: undefined,
            parentId: parentProfileId,
          }))
          .map(x => call(createOrGetProfile, x, action.payload.subGeoUnitId, appliedFilters))
      );

      const createdProfiles = createOrGetResults.map(x => x.profile);

      // Put the created child profiles into the store.
      yield put(addProfiles(createdProfiles));

      const creationPairs = action.payload.childItems.map(x => ({
        childItem: x,
        profile: createdProfiles.find(z => z.id === x.id && z.masterDataType === x.type),
      }));

      // Add new Y-Axis items so they appear in the list.
      yield put(addFacAttrYAxisItem({
        items: creationPairs.map((x): IPlanningItem => ({
          id: x.childItem.id,
          text: x.childItem.code,
          type: x.childItem.type as MetaDataTypes,
          subType: x.profile?.masterDataSubType,
          children: [],
          isPlannable: true,
          isExpanded: true,
          // If the user just created this successfully, then they can also edit it.
          doesUserHavePermission: true,
          isWithinPlanningRange: true,
          subGeoUnitId: action.payload.subGeoUnitId,
        })),
        parentItemId: action.payload.parentMasterDataId,
        parentItemType: action.payload.parentMasterDataType,
      }));

      // Close the modal.
      yield put(setAddChildDimensionModal({ isOpen: false }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation that is running.
      yield put(clearAddChildDimensionOp());
    }
  });
}

function* createAttributeAsync() {
  yield takeLatest(createAttribute, function* (action: Action) {
    if (!createAttribute.match(action)) {
      return;
    }

    try {
      const selectedTemplateItems: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[] = action.payload.profileStandardTemplateSelectedItems;
      const selectedExtraBusinessViews: IBusinessView[] = action.payload.selectedExtraBusinessViews;
      const selectedExtraBusinessFunctions: IBusinessFunction[] = action.payload.selectedExtraBusinessFunctions;
      const selectedExtraSubTopics: IAuditTopic[] = action.payload.selectedExtraSubTopics;

      let templateSelectedFlattenItems: IDetailedTemplateChildren[] = [];
      selectedTemplateItems.forEach(selectedItemFromTemplates => {
        if (isDetailedTemplateChildren(selectedItemFromTemplates)) {
          templateSelectedFlattenItems = [...templateSelectedFlattenItems, selectedItemFromTemplates];
        }
      });

      let profileId: number = action.payload.profileId;

      if (!profileId
        && action.payload.row.type === MetaDataTypes.Facility) {
        // Create a profile for this row first since it isn't in memory.
        const createdProfile: IPlanProfile = yield call(AuditPlanningApi.createProfile, {
          id: 0,
          deleted: false,
          masterDataId: action.payload.row.id,
          masterDataType: action.payload.row.type,
          masterDataSubType: undefined,
          parentId: undefined,
        }, action.payload.subGeoUnitId);

        // Put the created profile into the store.
        yield put(addProfiles([createdProfile]));

        // Use the created profile's Id for this attribute.
        profileId = createdProfile.id;
      } else if (!profileId) {
        throw new Error(`No profile exists for this row and one cannot be created.`);
      }

      // Now create the attribute using the profile's Id.
      const attrToCreate: IPlanProfileAttribute = {
        id: 0,
        profileId,
        masterDataId: action.payload.requirement.id,
        masterDataType: action.payload.requirement.type,
        deleted: false,
        reviewedOn: undefined,
        reviewedByEmail: undefined,
        reviewedByName: undefined,
        deletedOn: undefined,
        deletedByEmail: undefined,
        deletedByName: undefined,
        comment: undefined,
      };

      const createdAttribute: IPlanProfileAttribute = yield call(AuditPlanningApi.createAttribute, attrToCreate);

      //Remove duplications from selected items
      const noDuplicationsItems: IDetailedTemplateChildren[] = uniqWith(templateSelectedFlattenItems
        .filter(item => item.id > 0) // filtered to remove pseudo parent
        .map(item => ({ ...item, children: [], key: 0 })), isEqual);

      // Save the ProfileStandardTemplateSelection selections
      if (templateSelectedFlattenItems.length > 0) {
        yield call(AuditPlanningApi.saveProfileStandardTemplateItems, createdAttribute.id, noDuplicationsItems);
      }

      // Save Extra Metadata if exists selections
      const profileStandardMetaDataSelectedItems = [
        ...formatProfileMetaDataSelectedItems(createdAttribute.id, MetaDataTypes.BusinessView, selectedExtraBusinessViews),
        ...formatProfileMetaDataSelectedItems(createdAttribute.id, MetaDataTypes.BusinessFunction, selectedExtraBusinessFunctions),
        ...formatProfileMetaDataSelectedItems(createdAttribute.id, MetaDataTypes.AuditTopic, selectedExtraSubTopics),
      ];

      if (profileStandardMetaDataSelectedItems.length > 0) {
        yield call(AuditPlanningApi.saveProfileStandardMetaDataItems, createdAttribute.id, profileStandardMetaDataSelectedItems);
      }

      // Add the created attribute to the existing list in the store.
      yield put(addAttributes([createdAttribute]));

      // Close the "Add Attribute" modal.
      yield put(setCreateAttributeModal({ isOpen: false, }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(clearCreateAttributeOp());
    }
  });
}

function* editAttributeAsync() {
  yield takeLatest(editAttribute, function* (action: Action) {
    if (!editAttribute.match(action)) {
      return;
    }

    try {

      const state: IFacilityAttributesState = yield select((store: RootState) => store.facilityAttributes);
      const contextPlanProfileAttributeModal = state.contextPlanProfileAttributeModal;

      const selectedTemplateItems: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[] = action.payload.profileStandardTemplateSelectedItems;
      const selectedExtraBusinessViews: IBusinessView[] = action.payload.selectedExtraBusinessViews;
      const selectedExtraBusinessFunctions: IBusinessFunction[] = action.payload.selectedExtraBusinessFunctions;
      const selectedExtraSubTopics: IAuditTopic[] = action.payload.selectedExtraSubTopics;

      let selectionsToSave: IDetailedTemplateChildren[] =
        uniqWith(
          selectedTemplateItems
            .filter(isDetailedTemplateChildren)
            // Filtered to remove pseudo parent.
            .filter(x => x.id > 0)
            .map(item => ({ ...item, children: [], key: 0 })),
          isEqual);

      let attributeId: number = action.payload.attributeId;

      yield call(AuditPlanningApi.saveProfileStandardTemplateItems, attributeId, selectionsToSave);

      // Save Extra Metadata if exists selections
      const profileStandardMetaDataSelectedItems = [
        ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.BusinessView, selectedExtraBusinessViews),
        ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.BusinessFunction, selectedExtraBusinessFunctions),
        ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.AuditTopic, selectedExtraSubTopics)
      ];

      if (profileStandardMetaDataSelectedItems.length > 0) {
        yield call(AuditPlanningApi.saveProfileStandardMetaDataItems, attributeId, profileStandardMetaDataSelectedItems);
      } else {
        const profileStandardMetaDataToRemove = [
          ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.BusinessView, (contextPlanProfileAttributeModal.planProfileAttributeInfo?.extraBusinessViews || [])
            .map(md => ({ ...md, isDeleted: true }))),
          ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.BusinessFunction, (contextPlanProfileAttributeModal.planProfileAttributeInfo?.extraBusinessFunctions || [])
            .map(md => ({ ...md, isDeleted: true }))),
          ...formatProfileMetaDataSelectedItems(attributeId, MetaDataTypes.AuditTopic, (contextPlanProfileAttributeModal.planProfileAttributeInfo?.extraSubTopics || [])
            .map(md => ({ ...md, isDeleted: true }))),
        ];
        yield call(AuditPlanningApi.saveProfileStandardMetaDataItems, attributeId, profileStandardMetaDataToRemove);
      }
      // Close the "Add Attribute" modal.
      yield put(setEditAttributeModal({ isOpen: false, }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(clearEditAttributeOp());
    }
  });
}

function* setCreateAttributeModalAsync() {
  yield takeLatest(setCreateAttributeModal, function* (action) {
    try {
      const state: ICreateAttributeModal =
        yield select((store: RootState) => store.facilityAttributes.createAttributeModal);

      if (!state.isOpen) {
        return;
      }

      const selectedTemplateItems: IDetailedTemplate[] = [];
      const mandatoryTemplateChildren: IDetailedTemplate[] = [];
      const isCreateAuditTemplate = state.requirement.type === MetaDataTypes.AuditTemplate;

      if (isCreateAuditTemplate) {
        const templateFilter: ITemplate[] = [];

        templateFilter.push({
          id: state.requirement.masterDataItem?.id as number,
          isDeleted: state.requirement.masterDataItem?.isDeleted as boolean,
          name: ((state.requirement.masterDataItem?.name) as string),
          ownerGroupId: state.requirement.masterDataItem?.ownerGroupId
        });

        const detailedTemplates: IDetailedTemplate[] = yield call(TemplatesApi.getDetailedTemplates, {
          ownerGroups: undefined,
          auditTopics: undefined,
          templateIdFilter: templateFilter,
          includeDeleted: false,
        });

        const availableTemplates = detailedTemplates[0]
          .children
          .filter(item => item.masterDataType === MetaDataTypes.AuditTemplate);

        const availableTopics = detailedTemplates[0]
          .children
          .filter(item => item.masterDataType === MetaDataTypes.AuditTopic);

        const pseudoTopics = availableTopics.filter(topic => !topic.isSelectable);

        setToSelectedItemsForCreating(selectedTemplateItems, availableTemplates, [], false, true);
        setToSelectedItemsForCreating(selectedTemplateItems, availableTopics, [], false, false);
        setToReadOnlyItems(mandatoryTemplateChildren, [...availableTemplates, ...pseudoTopics], true);

        yield put(setDefaultCreateTemplateSelection({
          defaultSelectedDetailedTemplateItems: selectedTemplateItems
        }));

        if (detailedTemplates.length > 0) {
          yield put(setContextPlanProfileAttributeModal({
            availableTemplates,
            availableTopics,
          }));
        } else {
          yield put(setContextPlanProfileAttributeModal({ availableTemplates: [] }));
        }

        if (mandatoryTemplateChildren.length > 0) {
          yield put(setContextPlanProfileAttributeModal({ mandatoryItems: mandatoryTemplateChildren }));
        } else {
          yield put(setContextPlanProfileAttributeModal({ mandatoryItems: [] }));
        }
      }

      yield call(getAndSetExtraMetaDataForAttributeModal, action.payload.profileId);
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearCreateAttributeOp());
    }
  });
}

function* setEditAttributeModalAsync() {
  yield takeLatest(setEditAttributeModal, function* (action: Action) {
    if (!setEditAttributeModal.match(action)) {
      return;
    }

    try {
      const state: IFacilityAttributesState = yield select((store: RootState) => store.facilityAttributes);
      const templateFilter: ITemplate[] = [];
      const selectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[] = [];
      const mandatoryTemplateChildren: IDetailedTemplate[] = [];

      if (state.editAttributeModal.isOpen) {
        const attributeId = state.editAttributeModal.attributeId;
        const selectedItems: number[] = yield call(AuditPlanningApi.getprofileStandardTemplateSelectedIds, attributeId);
        const isEditAuditTemplate = state.editAttributeModal.requirement.type === MetaDataTypes.AuditTemplate;

        if (isEditAuditTemplate) {
          templateFilter.push({
            id: state.editAttributeModal.requirement.masterDataItem?.id as number,
            isDeleted: state.editAttributeModal.requirement.masterDataItem?.isDeleted as boolean,
            name: ((state.editAttributeModal.requirement.masterDataItem?.name) as string),
            ownerGroupId: state.editAttributeModal.requirement.masterDataItem?.ownerGroupId
          });

          const detailedTemplates: IDetailedTemplate[] = yield call(TemplatesApi.getDetailedTemplates, {
            ownerGroups: undefined,
            auditTopics: undefined,
            templateIdFilter: templateFilter,
            includeDeleted: false,
          });

          const availableTemplates = detailedTemplates[0]
            .children
            .filter(item => item.masterDataType === MetaDataTypes.AuditTemplate);

          const availableTopics = detailedTemplates[0]
            .children
            .filter(item => item.masterDataType === MetaDataTypes.AuditTopic);

          const pseudoTopics = availableTopics.filter(topic => !topic.isSelectable);

          setToSelectedItemsForEditing(selectedDetailedTemplateItems, availableTemplates, selectedItems, true, false);
          setToSelectedItemsForEditing(selectedDetailedTemplateItems, availableTopics, selectedItems, true, true);
          setToReadOnlyItems(mandatoryTemplateChildren, [...availableTemplates, ...pseudoTopics], true);

          // Send all template to storage
          if (detailedTemplates.length > 0) {
            yield put(setContextPlanProfileAttributeModal({
              availableTemplates,
              availableTopics,
            }));

          } else {
            yield put(setContextPlanProfileAttributeModal({ availableTemplates: [] }));
          }

          yield put(setContextPlanProfileAttributeModal({ mandatoryItems: mandatoryTemplateChildren }));
        }

        const planProfileAttributeInfo: IPlanProfileAttributeInfo = yield call(AuditPlanningApi.getPlanProfileAttributeInfo,
          attributeId,
          {
            includeBusinessFunctions: true,
            includeBusinessViews: true,
            includeSubTopics: true,
          });

        yield put(setContextPlanProfileAttributeModal({
          savedTemplateSelection: selectedDetailedTemplateItems,
          planProfileAttributeInfo,
        }));

      } else {
        setContextPlanProfileAttributeModal({
          savedTemplateSelection: [],
          planProfileAttributeInfo: undefined,
          availableTemplates: [],
          availableTopics: [],
          mandatoryItems: [],
        });
      }
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearEditAttributeOp());
    }
  });
}

function* removeAttributeAsync() {
  yield takeLatest(removeAttribute, function* (action: Action) {
    if (!removeAttribute.match(action)) {
      return;
    }

    try {
      const deleteResponse: {
        deletedAttribute: IPlanProfileAttribute,
        didPlanCalculationsRun: boolean,
        hadPlanCalculationError: boolean,
      } = yield call(AuditPlanningApi.removeAttribute,
        action.payload.attribute.id,
        action.payload.comment);

      // "Remove" the items in the slice.
      yield put(removeAttributes({
        attributes: [deleteResponse.deletedAttribute],
      }));

      let message = `Attribute removed successfully`;

      if (deleteResponse.hadPlanCalculationError) {
        message += ` but a plan calculation error was encountered.`;
      } else if (!deleteResponse.didPlanCalculationsRun) {
        message += ` but plans were not calculated because their GeoUnit is out of the plan approval period.`;
      } else {
        message += ".";
      }

      // Show "Reviewed!" message.
      yield put(showSuccessToast(message));

      // Close the modal.
      yield put(setRemoveAttributeModal({ isOpen: false }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearRemoveAttributeOp());
    }
  });
}

function* removeChildDimensionAsync() {
  yield takeLatest(removeChildDimension, function* (action: Action) {
    if (!removeChildDimension.match(action)) {
      return;
    }

    try {
      yield call(AuditPlanningApi.removeChildDimension,
        action.payload.profileId,
        action.payload.comment);

      // "Remove" the items in the slice.
      yield put(removeChildProfiles([action.payload.profileId]));

      // Close the modal.
      yield put(setRemoveChildDimensionModal({ isOpen: false }));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearRemoveChildDimensionOp());
    }
  });
}

/**
 * Tries to create a profile on the server. If the profile already exists, it will retrieve it instead.
 * Any attributes in that profile will also be loaded and added to the state.
 */
function* createOrGetProfile(profile: IPlanProfile, subGeoUnitId: number, filters: IAuditPlanningFilters) {
  let existingProfileId: number = 0;

  // Since the profile to be created isn't already in memory, try to create it first.
  try {
    // Create a profile for this row first.
    const createdProfile: IPlanProfile = yield call(AuditPlanningApi.createProfile, profile, subGeoUnitId);
    return {
      profile: createdProfile,
      alreadyExists: false,
    } as ICreateOrGetProfileResult;
  } catch (err: any) {
    const errMsg = getResponseErrorMessage(err);

    let profileIdMatch = errMsg.match(/The specified profile already exists with id ([0-9]+)./);

    if (profileIdMatch?.length === 2) {
      existingProfileId = Number(profileIdMatch[1]);
    } else {
      throw err;
    }
  }

  // The profile already exists, it just wasn't loaded previously (either due to
  // applied filters or someone else having created it simultaneously).

  // Load the profile & its attributes.
  // Create a new filter that includes only includeDeletedAttributes and profileIds.
  let queryFilters = {
    includeDeletedAttributes: filters.includeDeletedAttributes,
    profileIds: [existingProfileId],
    perspectiveXAxes: filters.perspectiveXAxes,
  };

  const [
    profiles,
    attributes,
  ]: [
      IPlanProfile[],
      IPlanProfileAttribute[],
    ] = yield all([
      call(AuditPlanningApi.getPlanProfiles, queryFilters),
      call(AuditPlanningApi.getPlanAttributes, queryFilters),
    ]);

  if (profiles.length === 0) {
    throw new Error(`Cannot create profile. It already exists but was unable to be loaded from the server.`);
  }

  // Put the loaded attributes into the state.
  yield put(addAttributes(attributes));

  // Return the "created" profile.
  return {
    profile: profiles[0],
    alreadyExists: true,
  } as ICreateOrGetProfileResult;
}

interface ICreateOrGetProfileResult {
  profile: IPlanProfile,
  alreadyExists: boolean,
}

function* setConfirmRemoveOrEditAttributeModalAsync() {
  yield takeLatest(setConfirmRemoveOrEditAttributeModal, function* (action) {
    if (!action.payload.isOpen) {
      // If the modal was not just opened, bail.
      return;
    }

    const state: IFacilityAttributesState = yield select((store: RootState) => store.facilityAttributes);
    const attributeId = state.confirmRemoveOrEditAttributeModal.attribute.id;
    const isModalFromAuditTemplate = state.confirmRemoveOrEditAttributeModal.requirement.type === MetaDataTypes.AuditTemplate;

    const selectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[] = [];
    const mandatoryTemplateChildren: IDetailedTemplate[] = [];

    const filter: IPlanProfileAttributeInfoFilterRequest = {
      includeBusinessViews: true,
      includeBusinessFunctions: true,
      includeSubTopics: true,
    };

    const planProfileAttributeInfo: IPlanProfileAttributeInfo = yield call(AuditPlanningApi.getPlanProfileAttributeInfo, attributeId, filter);
    yield put(setContextPlanProfileAttributeModal({ planProfileAttributeInfo }));

    if (isModalFromAuditTemplate) {
      const selectedItems: number[] = yield call(AuditPlanningApi.getprofileStandardTemplateSelectedIds, attributeId);
      const templateFilter: ITemplate[] = [];

      templateFilter.push({
        id: state.confirmRemoveOrEditAttributeModal.requirement.masterDataItem?.id as number,
        isDeleted: state.confirmRemoveOrEditAttributeModal.requirement.masterDataItem?.isDeleted as boolean,
        name: ((state.confirmRemoveOrEditAttributeModal.requirement.masterDataItem?.name) as string),
        ownerGroupId: state.confirmRemoveOrEditAttributeModal.requirement.masterDataItem?.ownerGroupId
      });

      const detailedTemplates: IDetailedTemplate[] = yield call(TemplatesApi.getDetailedTemplates, {
        ownerGroups: undefined,
        auditTopics: undefined,
        templateIdFilter: templateFilter,
        includeDeleted: false,
      });

      // Set total to parent level
      const flattenItems: IDetailedTemplateChildren[] = [];

      flattenDetailedTemplateChildren(detailedTemplates, flattenItems);
      let totalSelectedItems = 0;

      // Set as selected all mandatory child
      detailedTemplates.forEach(templateItem => {
        setToSelectedItemsForEditing(selectedDetailedTemplateItems, templateItem.children, selectedItems, true, false);

        // Get total for first level and set total to all others levels
        totalSelectedItems += setTotalSelectedItems(selectedDetailedTemplateItems, templateItem.children, selectedItems, true, false);

        setToReadOnlyItems(mandatoryTemplateChildren, templateItem.children, true);
      });

      const totalFlattenItems = flattenItems.filter(i => i.isSelectable
        && i.masterDataType !== MetaDataTypes.AuditTemplate).length;

      // Set define total selected items to show up on parent tree item
      yield put(setTotalSelectedInformationForAttributeModal(`(${totalSelectedItems} / ${totalFlattenItems})`));

      // Send all template to storage
      if (detailedTemplates.length > 0) {
        yield put(setContextPlanProfileAttributeModal({ availableTemplates: detailedTemplates[0].children }));
      } else {
        yield put(setContextPlanProfileAttributeModal({ availableTemplates: [] }));
      }

      yield put(setContextPlanProfileAttributeModal({ mandatoryItems: mandatoryTemplateChildren }));

      yield put(setContextPlanProfileAttributeModal({
        savedTemplateSelection: selectedDetailedTemplateItems
      }));
    }

    yield put(setConfirmRemoveOrEditAttributeModal({
      isWorking: false,
    }));
  });
}

function* getAndSetExtraMetaDataForAttributeModal(profileId: number | undefined) {
  if (!profileId) {
    return;
  }

  const state: IAuditPlanningState = yield select((store: RootState) => store.auditPlanning);

  // Find the other attributes on the same row.
  const attributeIds = state
    .profileAttributes
    .filter(a => a.profileId === profileId
      && state.xAxisData.some(x => x.id === a.masterDataId
        && x.type === a.masterDataType))
    .map(x => x.id);

  if (attributeIds.length > 0) {
    const defaultProfileMetaData: Partial<IPlanProfileAttributeInfo> = yield call(AuditPlanningApi.getDefaultProfileStandardMetaData,
      attributeIds);

    yield put(setDefaultMetaData({
      savedExtraBusinessViewItems: defaultProfileMetaData.extraBusinessViews ?? [],
      savedExtraBusinessFunctionItems: defaultProfileMetaData.extraBusinessFunctions ?? [],
      savedExtraSubTopicsItems: defaultProfileMetaData.extraSubTopics ?? [],
    }));
  }
}

function* loadProfileParentChildMatrixAsync() {
  yield takeLatest([loadProfileParentChildMatrix], function* () {
    try {
      const matrix: IProfileParentChildMatrixItem[] = yield call(AuditPlanningApi.getProfileParentChildMatrix);

      yield put(finishLoadProfileParentChildMatrix({
        isWorking: false,
        data: matrix,
      }));
    } catch (err) {
      yield put(finishLoadProfileParentChildMatrix({
        isWorking: false,
      }));
    }
  });
}

/** Handler for markAttributesReviewed action.
 * Contacts API to mark attributes as reviewed then updates the state.
 */
function* markAttributesReviewedAsync() {
  yield takeLatest(markAttributesReviewed, function* (action) {
    try {
      // Call the API to mark the provided attributes as reviewed.
      const reviewResponse: {
        reviewedAttributes: IPlanProfileAttribute[],
        invalidAttributeIds: number[],
        numPlanRecalculations: number,
        hadPlanCalculationError: boolean,
      } = yield call(AuditPlanningApi.markProfileAttributesReviewed, action.payload.map(x => x.id));

      let message = `${reviewResponse.reviewedAttributes.length} ${pluralize(reviewResponse.reviewedAttributes.length, "attribute", "attributes")} reviewed successfully`;

      if (reviewResponse.numPlanRecalculations !== reviewResponse.reviewedAttributes.length) {
        if (reviewResponse.hadPlanCalculationError) {
          const numBad = reviewResponse.reviewedAttributes.length - reviewResponse.numPlanRecalculations;
          message += ` but a plan calculation error was encountered so ${numBad} ${pluralize(numBad, "attribute", "attributes")} did not recalculate plans.`;
        } else {
          const numBad = reviewResponse.reviewedAttributes.length - reviewResponse.numPlanRecalculations;
          message += ` but ${numBad} ${pluralize(numBad, "attribute", "attributes")} did not calculate plans because ${pluralize(numBad, "its", "their")} GeoUnit is out of the plan approval period.`;
        }
      } else {
        message += ".";
      }

      // Show "Reviewed!" message.
      yield put(showSuccessToast(message));

      // If any of the attributes were invalid, also show a warning message about them.
      if (reviewResponse.invalidAttributeIds.length) {
        yield put(showErrorToast(`${reviewResponse.invalidAttributeIds.length} ${pluralize(reviewResponse.invalidAttributeIds.length, "attribute was", "attributes were")} invalid and could not be reviewed.`));
      }

      // Mark all the attributes in the state as having been reviewed just now by the current user.
      if (reviewResponse.reviewedAttributes.length) {
        yield put(upsertAttributes(reviewResponse.reviewedAttributes));
      }
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(clearMarkAttributesReviewedOp());
    }
  });
}

function* getScoringSistemAsync() {
  yield takeLatest(getScoringSystem, function* (action: Action) {
    if (!getScoringSystem.match(action)) {
      return;
    }

    try {
      const ownerGroups: IOwnerGroup[] = yield call(OwnerGroupsApi.getOwnerGroups);
      const ownerGroup = ownerGroups.find(x => x.id === action.payload);

      yield put(finishGetScoringSystem(
        ownerGroup!,
      ));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* setCenterAuditAttributeModalAsync() {
  yield takeLatest(setCenterAuditAttributeModal, function* (action) {
    try {
      const state: IFacilityAttributesState = yield select((store: RootState) => store.facilityAttributes);

      if (!action.payload.isOpen) {
        return;
      }

      const attributes: ICenterAuditAttribute[] = yield call(AuditPlanningApi.GetCenterAuditAttributes,
        state.centerAuditAttributeModal.profileStandardId,
        state.centerAuditAttributeModal.requirement.id,
        state.centerAuditAttributeModal.requirement.type);

      yield put(setCenterAuditAttributes(
        attributes,
      ));
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearCenterAuditAttributeOp());
    }
  });
}

function* saveCenterAuditAttributesAsync() {
  yield takeLatest(saveCenterAuditAttributes, function* (action) {
    try {
      let profileId: number = action.payload.profileId;

      if (!profileId
        && action.payload.row.type === MetaDataTypes.Facility) {
        // Create a profile for this row first since it isn't in memory.
        const createdProfile: IPlanProfile = yield call(AuditPlanningApi.createProfile, {
          id: 0,
          deleted: false,
          masterDataId: action.payload.row.id,
          masterDataType: action.payload.row.type,
          masterDataSubType: undefined,
          parentId: undefined,
        }, action.payload.subGeoUnitId);

        // Put the created profile into the store.
        yield put(addProfiles([createdProfile]));

        // Use the created profile's Id for this attribute.
        profileId = createdProfile.id;
      } else if (!profileId) {
        throw new Error(`No profile exists for this row and one cannot be created.`);
      }

      const profileAttributeSaved: IPlanProfileAttribute[] = yield select((store: RootState) => store.auditPlanning.profileAttributes);

      if (!profileAttributeSaved.find(x => x.profileId === profileId
        && x.masterDataId === action.payload.requirement.id
        && x.masterDataType === action.payload.requirement.type)) {

        // Now create the attribute using the profile's Id.
        const attrToCreate: IPlanProfileAttribute = {
          id: 0,
          profileId,
          masterDataId: action.payload.requirement.id,
          masterDataType: action.payload.requirement.type,
          deleted: false,
          reviewedOn: undefined,
          reviewedByEmail: undefined,
          reviewedByName: undefined,
          deletedOn: undefined,
          deletedByEmail: undefined,
          deletedByName: undefined,
          comment: undefined,
        };

        const createdAttribute: IPlanProfileAttribute = yield call(AuditPlanningApi.createAttribute, attrToCreate);

        // Add the created attribute to the existing list in the store.
        yield put(addAttributes([createdAttribute]));

        yield put(setAttributeIdForCenterCenterAuditAttributes(createdAttribute.id));
      }

      const attributesToSave: ICenterAuditAttribute[] = yield select((store: RootState) => store.facilityAttributes.centerAuditAttributesToSave);

      yield call(AuditPlanningApi.SaveCenterAuditAttributes, attributesToSave);
      yield put(applyFilters());
      yield put(showSuccessToast("Attributes saved successfully."));
      yield put(finishSaveCenterAuditAttributes());
    } catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      // Clear the operation spinner.
      yield put(clearCenterAuditAttributeOp());
    }
  });
}