import AuditsApi from "api/auditing/AuditsApi";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { loadPageAuth } from "store/audit-page-auth/AuditPageAuthSlice";
import { RootState } from "store/store";
import { IAuditPageAuth, PageAuthRequestType } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";
import { confirmAuditStatusChange, confirmRevertAuditStatus, finishUpdateAuditStatus, loadActionItems, loadQuestions, setConfirmAuditRevertStatusModal } from "../AuditSlice";

export function* updateStatusAsync() {
  yield takeLatest(confirmAuditStatusChange, function* (action) {
    if (!confirmAuditStatusChange.match(action)) {
      return;
    }

    try {
      let messageVerb = "status updated";

      if (action.payload.status === AuditStatuses.Closed) {
        messageVerb = "closed";
        yield call(AuditsApi.closeAudit, action.payload.id);
      } else if (action.payload.status === AuditStatuses.Completed) {
        messageVerb = "completed";
        yield call(AuditsApi.completeAudit, action.payload.id);
      } else if (action.payload.status === AuditStatuses.InProgress) {
        messageVerb = "started";
        yield call(AuditsApi.startAudit, action.payload.id);
      }

      // Status has been updated. Need to reload the questions now.
      yield put(loadQuestions(action.payload.id));

      // Finish updating the status.
      yield put(finishUpdateAuditStatus({
        isWorking: false,
        data: {
          newStatus: action.payload.status,
          statusChangeTimestamp: new Date().getTime(),
        },
      }));

      yield put(showSuccessToast(`Audit ${messageVerb} successfully!`));
      yield call(refreshAuditPageAuth, action.payload.id);
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishUpdateAuditStatus({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

export function* revertStatusAsync() {
  yield takeLatest(confirmRevertAuditStatus, function* (action) {
    if (!confirmRevertAuditStatus.match(action)) {
      return;
    }

    try {
      let messageVerb = "status changed";

      switch (action.payload.revertFromStatus) {
        case AuditStatuses.Planned: {
          messageVerb = "changed to Planned";
          break;
        }
        case AuditStatuses.InProgress: {
          messageVerb = "changed to In Progress";
          break;
        }
        case AuditStatuses.Completed: {
          messageVerb = "reopened";
          break;
        }
      }

      yield call(AuditsApi.revertStatus, action.payload.id, action.payload.revertFromStatus);

      // Status has been updated. Need to reload the questions now.
      yield put(loadQuestions(action.payload.id));

      // Also reload the action items.
      yield put(loadActionItems(action.payload.id));

      // Finish updating the status.
      yield put(finishUpdateAuditStatus({
        isWorking: false,
        data: {
          newStatus: action.payload.revertToStatus,
          statusChangeTimestamp: new Date().getTime(),
        },
      }));

      yield put(showSuccessToast(`Audit ${messageVerb} successfully!`));
      yield call(refreshAuditPageAuth, action.payload.id);
      yield put(setConfirmAuditRevertStatusModal({ isOpen: false }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishUpdateAuditStatus({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* refreshAuditPageAuth(auditId: number) {
  const auditPageAuth: IAuditPageAuth = yield select((store: RootState) => store.auditPageRestriction.auditPageAuth);

  yield put(loadPageAuth({
    auditId,
    pageName: auditPageAuth.pageName,
    // pageVisibleWhileLoad is set to true so that the entire page doesn't refresh
    // and cause a new LoadAudit to fire. Since the only thing we need to do is check
    // if the permissions have changed since the status has been updated.
    pageVisibleWhileLoading: true,
    type: PageAuthRequestType.audit,
  }));
}