import { IAzureADUser } from "shared/types/userProfileTypes";
import { IApi, IAuditGroup, IAuditorType, IAuditTopic, IAuditType, ICausalFactor, IHSESubElement, IQMSElement, IVerificationMethod } from "./auditMasterDataTypes";
import { ComplianceResults } from "./auditPlanningTypes";
import { CommentType, ITopicComment } from "./auditReportTypes";
import ICLMDocRef from "./clm/ICLMDocRef";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICLMWhomToCheck, ICountry, IFacility, IIPMProject, IMobileSite, ISupplier, MetaDataTypes } from "./masterDataTypes";

/** This is the audit info returned from the AuditInfo endpoint. */
export interface IAuditInfo {
  id: number,
  name: string,
  recap: string,
  status: AuditStatuses,
  createdOnTime: number,
  modifiedOnTime: number,
  startDateTime: number,
  endDateTime: number,
  completionDateTime?: number,
  closureDateTime?: number,
  auditGroup?: IAuditGroup,
  auditType?: IAuditType,
  ownerGroups: IOwnerGroup[],

  auditTopics: IAuditTopic[],
  businessViews: IBusinessView[],
  businessFunctions: IBusinessFunction[],
  businessTeams: IBusinessTeam[],
  templates: ITemplate[],
  facilities: IFacility[],
  countries: ICountry[],
  mobileSites: IMobileSite[],
  ipmProjects: IIPMProject[],
  suppliers: ISupplier[],

  auditors: IAuditor[],
  auditees: IAuditee[],

  auditTopicAuditees: IAuditTopicAuditee[],

  evidence: IAuditEvidenceItem[],
  links: IAuditEvidenceItem[],
}

export interface ITemplate {
  id: number,
  name: string,
  isDeleted: boolean,
  ownerGroupId: number | undefined,
}

export interface ITemplateChildren {
  id: number,
  templateId: number,
  masterDataId: number,
  masterDataType: string,
  label: string,
  isRequired: boolean,
  children: ITemplateChildren[],
}

export interface IOwnerGroup {
  id: number,
  name: string,
  isDeleted: boolean,
  scoringSystem: string,
}

export interface IMyAudit {
  id: number,
  name: string,
  businessLine: string,
  facility: string,
  topic: string,
  status: string,
  progressCompletion: string,
  startDateString: string,
  compliance: string,
}

export interface IAudit {
  id: number,
  name: string,
  recap: string,
  startDateTime: number,
  endDateTime: number,
  auditGroup?: IAuditGroup,
  templates: ITemplate[],
  auditType?: IAuditType,
  status: AuditStatuses,
  includeAllAPIQ2Questions: boolean,
  leadAuditorEmail?: string,
  leadAuditorName?: string,
  businessViews: IBusinessView[],
  businessFunctions: IBusinessFunction[],
  businessTeams: IBusinessTeam[],
  countries: ICountry[],
  facilities: IFacility[],
  ownerGroups: IOwnerGroup[],
  auditTopics: IAuditTopic[],
  mobileSites: IMobileSite[],
  ipmProjects: IIPMProject[],
  suppliers: ISupplier[],
}

export enum AuditStatuses {
  Planned = "Planned",
  InProgress = "InProgress",
  Completed = "Completed",
  Closed = "Closed",
}

export enum AuditSources {
  Unspecified = "Unspecified",
  QoF = "QoF",
  LegacyQuest = "LegacyQuest",
}

export interface IAuditee {
  name: string,
  email: string,
  isLeader: boolean,
}

export interface IAuditeeRequest {
  auditId: number,
  auditGroup?: string,
}

export interface IAuditReport {
  id: number,
  auditComment: IAuditComments,
  recap: string,
  name: string,
  status: AuditStatuses,
  templates: ITemplate[],
  facilities: IFacility[],
  topics: IAuditTopic[],
  countries: ICountry[],
  businessFunctions: IBusinessFunction[],
  basins: IBusinessTeam[];
  subTopics: IAuditTopic[],
  auditType?: IAuditType,
  businessLines?: IBusinessView[],
  auditorType?: IAuditorType,
  startDateTime: number,
  endDateTime: number,
  auditors: IAuditor[],
  hasOpenActionItems: boolean,
  questions: IAuditQuestion[],
  reportSummaryDtos: IReportTopic[],
  auditTopicComment: ITopicComment[]
}

export interface IAuditCreationItemPlan {
  id: number,
  planYear: number,
  weekOfYear?: number,
  leadAuditorEmail: string | undefined,
  parentDimensionType: string,
  parentDimensionSubType?: string,
  childDimensionType?: string
  childDimensionSubType?: string,
}

export interface IAuditCreationItem {
  plan: IAuditCreationItemPlan,
  requirementName: string | undefined,
  requirementType: string | undefined,
  requirementId: number | undefined,
  parentName: string | undefined,
  childName: string | undefined,
  geoName: string | undefined,
}

export interface IReportTopic {
  auditId: number,
  auditee: IAzureADUser[]
  auditor: IAzureADUser[]
  queCount: number,
  startDate: Date,
  topicId: number,
  topicName: string
}

export interface IAuditor {
  email: string,
  name: string,
  isLeader: boolean,
}

export enum AuditorSearchTypes {
  All = "All",
  Auditor = "Auditor",
  LeadAuditor = "LeadAuditor",
}

export interface IAuditQuestion {
  auditQuestionId: number,
  questionId: number,
  questionNumber: number,
  question: string,
  guidance: string,
  isGlobal: boolean,
  recommendedRWP: string,
  allowNA: boolean,
  auditorEmail?: string,
  auditorName?: string,
  topicId: number,
  topicName: string,
  scoringSystem: string,
  category: string,
  clause: string,
  interviewees: IAzureADUser[],
  evidence: IEvidenceLinkItem[],
  responses: IAuditQuestionResponse[],
  selectedVerificationMethods: IVerificationMethod[],
  licenseToOperate: boolean,

  verificationMethods: IVerificationMethod[],
  subTopics: IAuditTopic[],
  countries: ICountry[],
  businessViews: IBusinessView[],
  businessFunctions: IBusinessFunction[],
  businessTeams: IBusinessTeam[],
  templates: ITemplate[],
  apis: IApi[],
  hseSubElement?: IHSESubElement,
  qmsElements: IQMSElement[],
  clmDocRefs: ICLMDocRef[],
  clmWhomToCheck: ICLMWhomToCheck[],
}

export interface IAuditQuestionResponse {
  id: number,
  auditQuestionId: number,
  auditStatus: AuditStatuses,
  answer: string,
  causalFactor?: ICausalFactor,
  notes: string,
  createdOnTimestamp: number,
  createdBy: string,
  modifiedOnTimestamp: number,
  modifiedBy: string,
  isOutOfScope?: boolean,
}

export interface IQuestionAssignmentAction {
  auditQuestionId: number,
  auditorEmail?: string,
}

export interface IUpdateSummaryRequest {
  id: number,
  addedAuditors: IAuditor[],
  removedAuditors: IAuditor[],
  addedAuditees: IAuditee[],
  removedAuditees: IAuditee[],
  newLeadAuditeeEmail?: string,
  questionAssignments: IQuestionAssignmentAction[],
  assignedAuditTopics: IChangedAuditeeAssignmentAction[],
}

export interface IChangedAuditeeAssignmentAction {
  auditTopicId: number,
  auditeeEmail: string | null,
}

export interface ISaveAuditCommentRequest {
  auditId: number,
  comments: IAuditComments,
}

export interface ISaveTopicCommentRequest {
  id: number,
  auditId: number,
  auditTopicId: number,
  associationId: number | undefined,
  CommentType: CommentType,
  CommentText: string,
  CommentHtmlText: string,
  createdBy: IAzureADUser,
}

export enum ExecutionQueueTypes {
  All,
  Mine,
  AQId,
}

export interface IEvidenceLinkItem {
  id: number,
  description: string,
  info: string,
  auditStatus: AuditStatuses,
  type: "Evidence" | "Link"
}

export interface IAuditComments {
  closingStatement: string,
  lowlights: string,
  highLights: string,
  wayforward: string,
  closingStatementHTML: string,
  lowlightsHTML: string,
  highLightsHTML: string,
  wayforwardHTML: string,
  notes: string
}

export interface IAuditEvidenceItem {
  auditId: number,
  auditStatus: AuditStatuses,
  id: number,
  parent: string,
  description: string,
  filename?: string,
  size?: number,
  uploadedBy?: string,
  uploadDate?: Date,
  url?: string,
  parentId?: number,
  type?: string,
  auditQuestionId?: number,
}

export interface IAuditEvidenceResponse {
  auditId: number,
  auditStatus: AuditStatuses,
  attachments: IAuditEvidenceItem[],
  links: IAuditEvidenceItem[]
}

export interface IAuditReportGeneration {
  reportType: AuditReportTypes,
  outputType: AuditReportOutputTypes,
  includedSections: IAuditReportSection[],
}

export enum AuditReportTypes {
  OpeningMeeting = "Opening Meeting",
  ClosingMeeting = "Closing Meeting",
}


export enum AuditReportSections {
  AuditTeams = "Audit Teams",
  AuditScope = "Audit Scope",
  AuditSchedule = "Audit Schedule",
  LocalKnowledge = "Local Knowledge & Requirements",
  Findings = "Findings",
  FindingsDetails = "Findings Details",
  ResultsSummary = "Results Summary",
  TopicSummary = "Topic Summary",
  Highlights = "Highlights",
  OpportunitiesForImprovement = "Opportunities for Improvement",
  RWPSummary = "RWP Summary",
  ClosingStatements = "Audit Closing Statements",
  WayForward = "Way Forward",
  OppsForImprovement = "Opp. for Improv.",
  SpecificTopicSummary = "Specific Topic Summary",
}

export enum AuditReportOutputTypes {
  Word = "Word",
  PowerPoint = "PowerPoint",
}

export interface IAuditReportSection {
  section: AuditReportSections,
  topicId?: number,
  attachments: IAuditReportSectionAttachment[],
}

export enum AuditReportSectionAttachmentTypes {
  AuditEvidence = "AuditEvidence",
  AuditQuestionEvidence = "AuditQuestionEvidence"
}

export interface IAuditReportSectionAttachment {
  id: number,
  auditId: number,
  auditQuestionId?: number,
  type: AuditReportSectionAttachmentTypes,
  filename: string,
  description: string,
}

export interface IUserSelection {
  auditReportType: number,
  auditReportExtensionType: number,
  selections: ISelection[],
  topicSummary: ITopicSummary[],
}

export interface ISelection {
  name: string,
  pageType: number,
  selected: boolean,
  url: string[],
}

export interface ITopicSummary {
  id?: number,
  selected: boolean,
  url: string[],
}

export interface IAuditComplianceScore {
  id: number,
  auditId: number,
  auditStatus: AuditStatuses,
  scoreTimestamp: number,
  complianceScore: number,
  complianceResult: ComplianceResults,
  parentDimensionType: MetaDataTypes,
  parentDimensionId: number,
  childDimensionType?: MetaDataTypes,
  childDimensionId?: number,
  scoreById?: number,
  scoreByType: MetaDataTypes,
  numQuestionsTotal: number,
  numNonComplianceQuestions: number,
  cat1Score?: number,
  cat2Score?: number,
  includedQuestions: AuditComplianceScoreIncludedQuestions,
  shortName: string,
}

export enum AuditComplianceScoreIncludedQuestions {
  GlobalOnly = "GlobalOnly",
  GlobalPlusChildDimension = "GlobalPlusChildDimension",
  ChildDimensionOnly = "ChildDimensionOnly",
  AllSubtopicQuestions = "AllSubtopicQuestions",
  AllAuditQuestions = "AllAuditQuestions",
  GlobalPlusAllSubtopicQuestionsInTemplate = "GlobalPlusAllSubtopicQuestionsInTemplate",
  GlobalPlusChildPlusAllSubtopicQuestionsInTemplate = "GlobalPlusChildPlusAllSubtopicQuestionsInTemplate",
}

export interface ICreateAuditResponse {
  auditId: number,
  numLinkedPlans?: number,
}

export interface IAuditStep {
  number?: number,
  bucket: AuditStepBuckets,
  text: AuditSteps,
  stage: AuditStepStage,
  isOptional?: boolean,
  segment?: "top" | "bottom",
  url?: string,
}

export enum AuditStepStage {
  done = "done",
  nextUp = "nextUp",
  toDo = "toDo",
}

export enum AuditStepBuckets {
  ExecutionPlanning = "Execution Planning",
  ExecutingAudit = "Executing Audit",
  CreateRWP = "Create RWP",
  CloseAudit = "Close Audit",
}

export enum AuditSteps {
  VerifyAuditDetailsAndSchedule = "Verify Audit Details and Schedule",
  AssignAuditors = "Assign Auditors",
  OpeningMeeting = "Opening Meeting",
  StartAudit = "Start Audit",
  AnswerQuestions = "Answer Questions",
  CreateRWP = "Create RWP",
  ClosingMeeting = "Closing Meeting",
  CompleteAudit = "Complete Audit",
  CloseActions = "Close Actions",
  ValidateActions = "Validate Actions",
  UpdateQuestionsResponses = "Update Questions Responses",
  CloseAudit = "Close Audit",
}

export enum AuditNotificationRecipients {
  Auditors = "Auditors",
  Auditees = "Auditees",
}

export interface IAuditTopicAuditee {
  auditeeEmail: string,
  auditTopicId: number,
}

export enum AuditRelations {
  All = "All",
  AsLeadAuditor = "AsLeadAuditor",
  AsAuditor = "AsAuditor",
  AsAuditee = "AsAuditee",
}
