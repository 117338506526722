import React from "react";
import { Link } from "react-router-dom";
import { IAppInfo } from "shared/types/appInfoTypes";
import logoIcon from "../../../media/icons/logo-icon.svg";
import logoDivider from "../../../media/logo-divider.svg";
import UserBadge from "./UserBadge";
import UserHelp from './UserHelp';
import "./TitleBar.scoped.scss";

interface ITitleBarProps {
  /** The name of the site to show. */
  title: string,
  /** The url to visit when the user clicks the title. */
  titleUrl: string,
  /** Info about the current app version. Specifically uses the env property and compares
   * against (tolowercase) "prod". If not "prod", the environment will be shown between
   * the logo and the site name and a message will be shown with a link to the prod instance
   * between the logo area and the user area.
   */
  appInfo?: IAppInfo,
  /** The url of the prod instance for the "non-prod" message link. */
  prodUrl?: string,
}

const TitleBar: React.FC<ITitleBarProps> = ({
  title,
  titleUrl,
  appInfo,
  prodUrl,
}) => {
  const showNonProd = appInfo?.env
    && appInfo.env.toLowerCase() !== "prod";

  return (
    <div
      className="title-bar no-text-select"
    >
      <div
        className="logo-row"
      >
        <div
          className="qi-logo"
        >
          <a
            href="https://www.slb.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt=""
              src={logoIcon}
              className="logo-icon"
            />
          </a>
          <img
            alt="|"
            src={logoDivider}
            className="logo-divider"
          />
          QI
        </div>

        {showNonProd && (
          <>
            <img
              alt="|"
              src={logoDivider}
              className="logo-divider"
            />

            <span
              className="environment"
            >
              {appInfo?.env?.toUpperCase()}
            </span>
          </>
        )}

        <img
          alt="|"
          src={logoDivider}
          className="logo-divider"
        />

        <Link
          className="site-name"
          to={titleUrl}
        >
          {title}
        </Link>
      </div>

      <span
        className="space"
      >
      </span>

      {showNonProd
        && !!prodUrl
        && (
          <>
            <div
              className="non-prod-warning"
            >
              This is the QI {appInfo.env} site. For production click <a href={prodUrl}>here</a>.
            </div>

            <span
              className="space"
            >
            </span>
          </>
        )}

      <UserBadge />
      <UserHelp />
    </div>
  );
}

export default TitleBar;
