import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchType } from "components/search/SearchPage";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { ActionItemStatuses, IActionItem, IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { IAuditType, ICausalFactor } from "types/auditMasterDataTypes";
import { ICustomSearchListItem } from "types/customSearchTypes";
import { IBusinessView, ICountry, IFacility, IBusinessFunction, IMobileSite, IBusinessTeam } from "types/masterDataTypes";
import { ICustomActionSearch } from "types/searchActionTypes";

export interface ISearchActionsState {
  pickerData: {
    auditTypes: IPickerState<IAuditType>,
    countries: IPickerState<ICountry>,
    businessViews: IPickerState<IBusinessView>,
    businessTeams: IPickerState<IBusinessTeam>,
    businessFunctions: IPickerState<IBusinessFunction>,
    leadAuditors: IPickerState<IAzureADUser>,
    actionItemTypes: IPickerState<IActionItemType>,
    assignedTo: IPickerState<IAzureADUser>,
    createdBy: IPickerState<IAzureADUser>,
    priorities: IPickerState<IActionItemPriority>,
    causalFactors: IPickerState<ICausalFactor>,
  },
  mobileSites: IMobileSite[],
  facilities: IFacility[],

  dueDateStart: number | undefined,
  dueDateEnd: number | undefined,
  creationDateStart: number | undefined,
  creationDateEnd: number | undefined,
  closureDateStart: number | undefined,
  closureDateEnd: number | undefined,

  actionRequired: string,
  statuses: ActionItemStatuses[],
  parentTypes: string[],
  parentId: string,
  parentName: string,
  isValidated: boolean | undefined,

  actionId: string | undefined,
  id: number | undefined,

  loadActionItemOp?: IOperation<IActionItem>,
  inspectedActionItem?: IActionItem,

  isSaveFiltersModalOpen: boolean,
  saveFiltersOperation?: IOperation<number>,
  customSearchList: ICustomSearchListItem[],
  updateFiltersOperation?: IOperation<void>,
  deleteFiltersOperation?: IOperation<void>,
}

export enum SearchActionsPickerKeys {
  auditTypes = "auditTypes",
  countries = "countries",
  businessViews = "businessViews",
  businessTeams = "businessTeams",
  businessFunctions = "businessFunctions",
  leadAuditors = "leadAuditors",
  actionItemTypes = "actionItemTypes",
  assignedTo = "assignedTo",
  createdBy = "createdBy",
  priorities = "priorities",
  causalFactors = "causalFactors",
}

const initialState: ISearchActionsState = {
  pickerData: {
    auditTypes: {
      key: SearchActionsPickerKeys.auditTypes,
      isOpen: false,
      items: [],
      selectedItems: [],
      loadOperation: undefined,
    },
    countries: {
      key: SearchActionsPickerKeys.countries,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessViews: {
      key: SearchActionsPickerKeys.businessViews,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessTeams: {
      key: SearchActionsPickerKeys.businessTeams,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessFunctions: {
      key: SearchActionsPickerKeys.businessFunctions,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    leadAuditors: {
      key: SearchActionsPickerKeys.leadAuditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    actionItemTypes: {
      key: SearchActionsPickerKeys.actionItemTypes,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    assignedTo: {
      key: SearchActionsPickerKeys.assignedTo,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    createdBy: {
      key: SearchActionsPickerKeys.createdBy,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    priorities: {
      key: SearchActionsPickerKeys.priorities,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    causalFactors: {
      key: SearchActionsPickerKeys.causalFactors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
  mobileSites: [],
  facilities: [],
  dueDateStart: undefined,
  dueDateEnd: undefined,
  creationDateStart: undefined,
  creationDateEnd: undefined,
  closureDateStart: undefined,
  closureDateEnd: undefined,

  actionRequired: "",
  statuses: [],
  parentTypes: [],
  parentId: "",
  parentName: "",
  isValidated: undefined,

  actionId: undefined,
  id: undefined,

  loadActionItemOp: undefined,
  inspectedActionItem: undefined,

  isSaveFiltersModalOpen: false,
  saveFiltersOperation: undefined,
  customSearchList: [],
};

export const searchActionsSlice = createSlice({
  name: "search-actions",
  initialState,
  reducers: {
    ...pickerHandlers,
    /** Sets any provided state property. */
    setSearchPropValue: (state, action: PayloadAction<Partial<ISearchActionsState>>) => {
      Object.assign(state, action.payload);
    },
    clearFilters: (state) => {
      Object.assign(state, initialState);
    },
    loadAndInspectActionItem: (state, _: PayloadAction<number>) => {
      state.loadActionItemOp = {
        isWorking: true,
      };
    },
    finishLoadingActionItem: (state, action: PayloadAction<IOperation<IActionItem>>) => {
      if (action.payload.errorMessage) {
        state.loadActionItemOp = action.payload;
        state.inspectedActionItem = undefined;
        return;
      }

      state.loadActionItemOp = undefined;
      state.inspectedActionItem = action.payload.data;
    },
    closeActionItem: state => {
      state.inspectedActionItem = undefined;
    },
    clearLoadError: state => {
      state.loadActionItemOp = undefined;
    },
    saveFilters: (state, _: PayloadAction<{ filtersName: string, searchType: SearchType }>) => {
      state.saveFiltersOperation = {
        isWorking: true,
      };
    },
    finishSavingFilters: (state, action: PayloadAction<IOperation<number>>) => {
      if (action.payload.errorMessage) {
        state.saveFiltersOperation = action.payload;
        return;
      }

      state.id = action.payload.data;

      state.saveFiltersOperation = undefined;
      state.isSaveFiltersModalOpen = false;
    },
    setSaveFiltersModal: (state, action: PayloadAction<boolean>) => {
      state.saveFiltersOperation = undefined;
      state.isSaveFiltersModalOpen = action.payload;
    },
    loadActionFilters: (state, _: PayloadAction<number>) => {
      state.loadActionItemOp = {
        isWorking: true,
      };
    },
    finishLoadActionFilters: (state, action: PayloadAction<IOperation<ICustomActionSearch>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {

        return;
      }

      const filters = action.payload.data;

      state.id = filters.id;
      state.dueDateStart = filters.dueDateStart;
      state.dueDateEnd = filters.dueDateEnd;
      state.actionRequired = filters.actionRequired;
      state.statuses = filters.statuses;
      state.mobileSites = filters.mobileSites;
      state.parentTypes = filters.parentTypes;
      state.parentId = filters.parentId;
      state.parentName = filters.parentName;
      state.facilities = filters.facilities;
      state.isValidated = filters.isValidated;
      state.creationDateStart = filters.creationDateStart;
      state.creationDateEnd = filters.creationDateEnd;
      state.closureDateStart = filters.closureDateStart;
      state.closureDateEnd = filters.closureDateEnd;

      state.saveFiltersOperation = undefined;
    },
    updateFilters: (state) => {
      state.updateFiltersOperation = {
        isWorking: true,
      };
    },
    finishUpdateFilters: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.updateFiltersOperation = action.payload;
        return;
      }

      state.updateFiltersOperation = undefined;
    },
    deleteFilters: (state) => {
      state.deleteFiltersOperation = {
        isWorking: true,
      };
    },
    finishDeleteFilters: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.deleteFiltersOperation = action.payload;
        return;
      }

      state.deleteFiltersOperation = undefined;
    },
  }
});

export const {
  openPicker,
  closePicker,
  expandPickerItem,
  collapsePickerItem,
  loadPickerItems,
  loadSuggestedPickerItems,
  setPickerItems,
  setSelectedPickerItems,
  setPickerError,
  setSearchPropValue,
  clearFilters,
  loadAndInspectActionItem,
  finishLoadingActionItem,
  closeActionItem,
  clearLoadError,
  saveFilters,
  finishSavingFilters,
  setSaveFiltersModal,
  loadActionFilters,
  finishLoadActionFilters,
  updateFilters,
  finishUpdateFilters,
  deleteFilters,
  finishDeleteFilters,
} = searchActionsSlice.actions;