import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { useEffect } from "react";
import 'react-popper-tooltip/dist/styles.css';
import { useParams } from "react-router-dom";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import Card from "shared/components/layout/card/Card";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import deleteIcon from "shared/media/dls/delete.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import { IColumnItem } from "shared/types/columnTypes";
import formatDate from "shared/utilities/dateFormatters";
import { closeAttachmentModal, closeLinkModal, loadAttachment, loadAuditEvidences, openAttachmentModal, openDeleteAttachmentModal, openDeleteLinkModal, openLinkModal } from "store/audit-evidences/AuditEvidencesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditEvidenceItem } from "types/auditingTypes";
import AuditPage from "../common/audit-page/AuditPage";
import AttachmentModal from "./AttachmentModal";
import "./AuditEvidencePage.scoped.scss";
import ConfirmDeleteAttachmentModal from "./ConfirmDeleteAttachmentModal";
import ConfirmDeleteLinkModal from "./ConfirmDeleteLinkModal";
import LinkModal from "./LinkModal";

const AuditEvidencePage: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const attachments = useAppSelector(store => store.auditEvidences.attachments);
  const links = useAppSelector(store => store.auditEvidences.links);
  const loadOperation = useAppSelector(store => store.auditEvidences.loadOperation);
  const isAttachmentsModalVisible = useAppSelector(store => store.auditEvidences.isAttachmentsModalVisible);
  const uploadOp = useAppSelector(store => store.auditEvidences.uploadOp);
  const isLinkModalVisible = useAppSelector(store => store.auditEvidences.isLinkModalVisible);
  const isDeleteLinkModalVisible = useAppSelector(store => store.auditEvidences.isDeleteLinkModalVisible);
  const isDeleteAttachmentModalVisible = useAppSelector(store => store.auditEvidences.isDeleteAttachmentModalVisible);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const userName = useAppSelector(store => store.auth.currentUser.name);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);
  const loadFileData = useAppSelector(store => store.auditEvidences.loadFileData);

  // apply Retriction
  const isAllEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;
  const isOwnEditable = auditPageAuth.editRestriction === EditRestriction.EditOwn;
  const isEditable = isAllEditable || isOwnEditable;

  const dispatch = useAppDispatch();
  const {
    auditId: paramsId,
  } = useParams();

  useEffect(() => {
    dispatch(loadAuditEvidences({ id: Number(paramsId) }));
  }, [paramsId, dispatch]);

  if (!paramsId) {
    return null;
  }

  return (
    <AuditPage
      title="Audit Attachments and Links"
      auditId={paramsId}
      overviewSettings={{
        showComplianceScore: true,
        showQuestionCompletionPercent: true,
      }}
    >
      {loadOperation?.isWorking
        ? <Spinner />
        : (
          <>
            {isAttachmentsModalVisible && (
              <AttachmentModal
                onClose={() => dispatch(closeAttachmentModal())}
                auditId={Number(paramsId)}
                isUploading={!!uploadOp?.isWorking}
                existingEvidenceFilenames={attachments.filter(x => x.parent === "Audit").map(x => x.filename || "")}
              />
            )}
            {isDeleteLinkModalVisible && <ConfirmDeleteLinkModal />}
            {isDeleteAttachmentModalVisible && <ConfirmDeleteAttachmentModal />}

            <Card
              title="Attachments"
              cardStateId="auditEvidenceAttachments"
              defaultCollapsedState={false}
              headerElement={(
                <div
                  className="evidence-header"
                >
                  {isEditable && (
                    <button
                      className="table-button primary"
                      onClick={() => dispatch(openAttachmentModal({}))}
                    >
                      New Attachment
                    </button>
                  )}
                </div>
              )}
            >
              <Table
                data={attachments}
                keyProp={"id"}
                columnRender={getAttachmentColumnRenderer(isOwnEditable,
                  isAllEditable,
                  userName,
                  userEmail,
                  dispatch,
                  paramsId,
                  audit?.status)}
                columns={attachmentsColumns}
                theme={TableThemes.compact}
              />
            </Card>

            {isLinkModalVisible && (
              <LinkModal
                onClose={() => dispatch(closeLinkModal())}
                auditId={paramsId || ''}
                isUploading={!!uploadOp?.isWorking}
              />
            )}

            <Card
              title="Links"
              cardStateId="auditEvidenceLinks"
              defaultCollapsedState={false}
              headerElement={(
                <div
                  className="evidence-header"
                >
                  {isEditable && (
                    <button
                      className="table-button primary"
                      onClick={() => dispatch(openLinkModal({}))}
                    >
                      New Link
                    </button>
                  )}
                </div>
              )}
            >
              <Table
                data={links}
                keyProp={"id"}
                columnRender={getLinkColumnRenderer(isOwnEditable,
                  isAllEditable,
                  userName,
                  userEmail,
                  dispatch,
                  audit?.status)}
                columns={linksColumns}
                theme={TableThemes.compact}
              />
            </Card>
          </>
        )}

      {loadFileData?.loadOp?.isWorking && (
        <ModalSpinner />
      )}
    </AuditPage>
  )
}

export default AuditEvidencePage;


const attachmentsColumns: IColumnItem<IAuditEvidenceItem, keyof IAuditEvidenceItem>[] = [
  {
    key: 'EDITCOLUMN',
    header: '',
  },
  {
    key: 'Id',
    property: 'id',
    header: 'Attachment Id',
    width: 100,
  },
  {
    key: 'PARENT',
    property: 'parent',
    header: 'Parent',
    width: 100,
  },
  {
    key: 'DESCRIPTION',
    property: 'description',
    header: 'Description',
    width: 250,
  },
  {
    key: 'FILENAME',
    property: 'filename',
    header: 'File Name',
    width: 200,
  },
  {
    key: 'SIZE',
    property: 'size',
    header: 'Size',
    width: 100,
  },
  {
    key: 'UPLOADEDBY',
    property: 'uploadedBy',
    header: 'Uploaded By',
    width: 100,
  },
  {
    key: 'DATE',
    property: 'uploadDate',
    header: 'Date',
    width: 100,
  },
  {
    key: 'DELETECOLUMN',
    header: '',
  },
];

const linksColumns: IColumnItem<IAuditEvidenceItem, keyof IAuditEvidenceItem>[] = [
  {
    key: 'EDITCOLUMN',
    header: '',
  },
  {
    key: 'Id',
    property: 'id',
    header: 'Link Id',
    width: 50,
  },
  {
    key: 'PARENT',
    property: 'parent',
    header: 'Parent',
    width: 100,
  },
  {
    key: 'DESCRIPTION',
    property: 'description',
    header: 'Description',
    width: 300,
  },
  {
    key: 'URL',
    property: 'url',
    header: 'URL',
    width: 250,
  },
  {
    key: 'UPLOADEDBY',
    property: 'uploadedBy',
    header: 'Uploaded By',
    width: 100,
  },
  {
    key: 'DATE',
    property: 'uploadDate',
    header: 'Date',
    width: 100,
  },
  {
    key: 'DELETECOLUMN',
    header: '',
  },
];

function getLinkColumnRenderer(isOwnEditable: boolean,
  isAllEditable: boolean,
  userName: string | undefined,
  userEmail: string,
  dispatch: Dispatch<AnyAction>,
  auditStatus: AuditStatuses | undefined) {
  return function renderLinkColumns(columnKey: string,
    boundObj: IAuditEvidenceItem,
    objProperty: string) {
    const ownLinkIsEditable = isOwnEditable
      ? (userName?.toLowerCase() === boundObj?.uploadedBy?.toLowerCase()
        || userEmail.toLowerCase() === boundObj?.uploadedBy?.toLowerCase())
      : false;
    const disabled = !(isAllEditable || ownLinkIsEditable);
    const tooltip = disabled
      ? "you don't have permission to access this."
      : undefined;
    if (columnKey === 'EDITCOLUMN') {
      return auditStatus === boundObj.auditStatus
        ? (
          <td key={`cell-${columnKey}`}>
            <CircleImgButton
              alt='edit-button'
              icon={editIcon}
              type="primary"
              iconSize="small"
              isDisabled={disabled}
              onClick={() => dispatch(openLinkModal({ link: boundObj }))}
              tooltip={tooltip}
            />
          </td>
        ) : <td key={`cell-${columnKey}`}></td>;
    } else if (columnKey === 'DELETECOLUMN') {
      return auditStatus === boundObj.auditStatus
        ? (
          <td key={`cell-${columnKey}`}>
            <CircleImgButton
              alt="delete-button"
              icon={deleteIcon}
              type="secondary"
              iconSize="small"
              isDisabled={disabled}
              onClick={() => dispatch(openDeleteLinkModal({ item: boundObj }))}
              tooltip={tooltip}
            />
          </td>
        ) : <td key={`cell-${columnKey}`}></td>;
    } else if (columnKey === "DATE") {
      const data = String(boundObj.uploadDate || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : ''}
          key={`cell-${columnKey}`}
        >
          {
            data &&
            <span>
              {
                formatDate(new Date(data))
              }
            </span>
          }
        </td>
      );
    } else if (columnKey === "URL") {
      const data = String(boundObj.url || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : ''}
          key={`cell-${columnKey}`}
        >

          {
            data &&
            <a
              rel="noreferrer"
              target="_blank"
              title={truncate
                ? data
                : undefined
              }
              href={validateURL(data)}
            >
              {data}
            </a>
          }
        </td>
      );
    }

    // Otherwise, do the usual.
    const data = String((boundObj as any)[objProperty] || '');
    const truncate = data.length > 100;

    return (
      <td
        className={truncate ? 'truncate' : ''}
        key={`cell-${columnKey}`}
      >
        {
          data &&
          <span
            title={truncate
              ? data
              : undefined
            }
          >
            {data}
          </span>
        }
      </td>
    );
  };
}

const validateURL = (link: string) => {
  if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
    return link;
  } else {
    return "//" + link;
  }
}

function getAttachmentColumnRenderer(isOwnEditable: boolean,
  isAllEditable: boolean,
  userName: string | undefined,
  userEmail: string,
  dispatch: Dispatch<AnyAction>,
  paramsId: string,
  auditStatus: AuditStatuses | undefined) {
  return function renderAttachmentColumns(columnKey: string, boundObj: IAuditEvidenceItem, objProperty: string) {
    const ownAttachmentIsEditable = isOwnEditable
      ? (userName?.toLowerCase() === boundObj?.uploadedBy?.toLowerCase()
        || userEmail.toLowerCase() === boundObj?.uploadedBy?.toLowerCase())
      : false;
    const disabled = !(isAllEditable || ownAttachmentIsEditable);
    const tooltip = disabled
      ? "you don't have permission to access this."
      : undefined;
    if (columnKey === 'EDITCOLUMN') {
      return auditStatus === boundObj.auditStatus
        ? (
          <td key={`cell-${columnKey}`}>
            <CircleImgButton
              alt='edit-button'
              icon={editIcon}
              type="primary"
              iconSize="small"
              isDisabled={disabled}
              onClick={() => dispatch(openAttachmentModal({ attachment: boundObj }))}
              tooltip={tooltip}
            />
          </td>
        ) : <td key={`cell-${columnKey}`}></td>;
    } else if (columnKey === 'DELETECOLUMN') {
      return auditStatus === boundObj.auditStatus
        ? (
          <td key={`cell-${columnKey}`}>
            <CircleImgButton
              alt="delete-button"
              icon={deleteIcon}
              type="secondary"
              iconSize="small"
              isDisabled={disabled}
              onClick={() => dispatch(openDeleteAttachmentModal({ item: boundObj }))}
              tooltip={tooltip}
            />
          </td>
        ) : <td key={`cell-${columnKey}`}></td>;
    } else if (columnKey === "DATE") {
      const data = String(boundObj.uploadDate || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : ''}
          key={`cell-${columnKey}`}
        >
          {
            data &&
            <span>
              {
                formatDate(new Date(data))
              }
            </span>
          }
        </td>
      );
    } else if (columnKey === "SIZE") {
      const data = String(boundObj.size || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : ''}
          key={`cell-${columnKey}`}
        >
          {
            data &&
            <span>
              {
                Number(data) >= 1000000 ?
                  (Number(data) / 1000000).toFixed(2) + 'MB' :
                  (Number(data) / 1000).toFixed(2) + 'KB'
              }
            </span>
          }
        </td>
      );
    } else if (columnKey === "FILENAME") {
      const filename = String(boundObj.filename || '');
      const auditQuestionId = boundObj.parentId
        ? Number(boundObj.parentId)
        : undefined;

      return (
        <td
          key={`cell-${columnKey}`}
        >
          {
            filename &&
            <span
              className="link"
              title={filename}
              onClick={() => dispatch(loadAttachment({
                auditId: Number(paramsId),
                auditQuestionId,
                evidenceId: boundObj.id,
              }))}
            >
              {filename}
            </span>
          }
        </td>
      );
    } else {
      const data = String((boundObj as any)[objProperty] || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : ''}
          key={`cell-${columnKey}`}
        >
          {
            data &&
            <span
              title={truncate
                ? data
                : undefined
              }
            >
              {data}
            </span>
          }
        </td>
      );
    }
  };
}