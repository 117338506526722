import AuditTopicApi from "api/masterdata/AuditTopicApi";
import React, { useCallback } from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import { AuditTopicSearch, AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerList from "./non-redux-list-picker/NonReduxPickerList";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IAuditTopicPickerProps {
  renderMode?: PickerMode,
  /** Flag to determine if the selection is required or not. */
  isRequired?: boolean,
  /** Optional. If provided, only AuditTopics filtered by auditTopicSearchType & auditTopicSearchIds will be show up. */
  auditTopicSearch?: AuditTopicSearch,
}

const AuditTopicPicker: React.FC<IAuditTopicPickerProps & ICommonPickerProps<IAuditTopic>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  showSelectedItems = true,
  hideUnselectableBranches = false,
  hideLabelAboveSearch = false,
  onRenderPicker,
  isDisabled = false,
  userProfileMetaRestrictions,
  renderMode = 'picker',
  isRequired = false,
  isItemDisabledMapper,
  auditTopicSearch,
}) => {
  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.OwnerGroup
      || x.type === MetaDataTypes.AuditTopic) || [];

  const pickerFilterItems = <T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return ((item.item as unknown) as IAuditTopic).name
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  };

  const searchFilterItems = (item: IAuditTopic, search: string) => {
    return item.name
      .toLowerCase()
      .includes(search.toLowerCase());
  };

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal): Promise<IAuditTopic[]> => {
    let filter: any = {};
    switch (auditTopicSearch?.auditTopicSearchType) {
      case AuditTopicSearchTypes.AuditGroup:
        filter = {
          auditGroupId: auditTopicSearch.auditTopicSearchIds
            ? auditTopicSearch.auditTopicSearchIds[0]
            : undefined
        };
        break;
      case AuditTopicSearchTypes.OwnerGroups:
        filter = { ownerGroupIds: auditTopicSearch.auditTopicSearchIds };
        break;
      case AuditTopicSearchTypes.Template:
        filter = { templateIds: [auditTopicSearch.auditTopicSearchIds] };
        break;
      case AuditTopicSearchTypes.Ids:
        filter = { auditTopicIds: [auditTopicSearch.auditTopicSearchIds] };
        break;
      case AuditTopicSearchTypes.OnlySubTopicsFromIds:
        filter = { auditTopicIds: [auditTopicSearch.auditTopicSearchIds] };
        break;
    }

    const allItems: IAuditTopic[] = await AuditTopicApi.searchAuditTopics(auditTopicSearch
      ? {
        ...filter,
        searchType: auditTopicSearch.auditTopicSearchType,
      } : undefined, abortSignal);

    // Take unique owner groups from items
    const uniqueOwnerGroups = allItems
      .map(auditTopicItem => auditTopicItem.ownerGroup || "")
      .filter((value, index, self) => self.indexOf(value) === index);

    let topItems = allItems
      .filter(x => (auditTopicSearch?.auditTopicSearchType === AuditTopicSearchTypes.OnlySubTopicsFromIds
        || !x.parentId)
        && !x.isDeleted)
      .sort((a, b) => a.name < b.name ? -1 : 1);

    topItems.forEach(topicParentLevel => {
      const topicChildren = allItems
        .filter(x => x.parentId === topicParentLevel.id
          && !x.isDeleted)
        .sort((a, b) => a.name < b.name ? -1 : 1);

      if (!topicParentLevel.isSelectable) {
        const auditTopicGroupNameList = new Map();
        topicChildren.forEach(item => {
          auditTopicGroupNameList.set(item.groupName, {
            key: item.parentId,
            groupName: item.groupName,
            auditGroup: item.auditGroup,
          });
        });

        topicParentLevel.children = [];
        let countKey = 1000;
        auditTopicGroupNameList.forEach(auditTopicGroupName => {
          const parentTopic: IAuditTopic | undefined = auditTopicGroupName.groupName !== "" ? {
            id: countKey + auditTopicGroupName.key,
            auditGroupId: 0,
            auditGroup: auditTopicGroupName.auditGroup,
            level: 0,
            parentId: countKey,
            name: auditTopicGroupName.groupName,
            groupName: "",
            isSelectable: false,
            sortOrder: countKey,
            isDeleted: false,
            isPlannable: true,
            ownerGroupId: 1,
            ownerGroup: "_",
            children: topicChildren.filter(child => child?.groupName === auditTopicGroupName.groupName),
            scoringSystem: "",
          } : undefined;

          if (parentTopic) {
            topicParentLevel.children?.push(parentTopic);
          } else if (topicParentLevel.children) {
            const items = topicChildren.filter(child => child.groupName === auditTopicGroupName.groupName);
            topicParentLevel.children.unshift(...items);
          }
          else {
            topicParentLevel.children = topicChildren;
          }
          countKey++;
        });

        topicParentLevel.children.sort((a, b) => (a.name > b.name
          ? 1
          : (b.name > a.name
            ? -1
            : 0)));

      } else {
        topicParentLevel.children = topicChildren;
      }
    }, [auditTopicSearch]);


    let countKey = 9999;
    const topItemsGroupByOwnerGroup = uniqueOwnerGroups.map(ownerGroupName => {
      const parentTopic: IAuditTopic = {
        id: countKey,
        auditGroupId: 0,
        auditGroup: ownerGroupName,
        level: 0,
        parentId: countKey,
        name: ownerGroupName,
        groupName: "",
        isSelectable: false,
        sortOrder: countKey,
        isDeleted: false,
        isPlannable: true,
        ownerGroupId: 1,
        ownerGroup: "_",
        children: topItems.filter(topItem => topItem?.ownerGroup === ownerGroupName),
        scoringSystem: "",
      };

      countKey++;
      return parentTopic;
    });

    const items = (auditTopicSearch?.auditTopicSearchType === AuditTopicSearchTypes.OnlySubTopicsFromIds
      ? topItems
      : topItemsGroupByOwnerGroup)
      .sort((a, b) => (a.name > b.name
        ? 1
        : (b.name > a.name
          ? -1
          : 0)));

    return items;
  }, [auditTopicSearch]);

  const isDisabledMapper = (item: IAuditTopic, ancestorPath: IAuditTopic[]): boolean => {
    if (isItemDisabledMapper
      && isItemDisabledMapper(item, ancestorPath)) {
      return true;
    }

    if (!restrictedToItems.length) {
      return !item.isSelectable
        && item.level === 0;
    } else {
      return !restrictedToItems.some(x => x.id === item.ownerGroupId
        && x.type === MetaDataTypes.OwnerGroup)
        && (!restrictedToItems.some(x => x.id === item.id
          && (x.type === MetaDataTypes.AuditTopic))
          || (!item.isSelectable && item.level === 0));
    }
  };

  if (renderMode === 'picker') {
    return (
      <NonReduxPicker<IAuditTopic>
        itemSorter={(a, b) => a.name < b.name ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={item => item.name}
        selectedItems={selectedItems}
        title={auditTopicSearch?.auditTopicSearchType === AuditTopicSearchTypes.OnlySubTopicsFromIds
          ? "Audit Sub Topics"
          : "Audit Topics"
        }
        allowMultiSelect={allowMultiselect}
        displayMode="tree"
        renderListItem={item => item.name}
        childrenMapper={item => item.children || []}
        onLoadSuggestedItems={async (abortSignal) => {
          return await AuditTopicApi.getSuggestedAuditTopics(undefined, abortSignal);
        }}
        showSuggestedItems={showSuggestions}
        showSelectedItems={showSelectedItems}
        searchOptions={{
          filterItem: searchFilterItems,
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={isDisabledMapper}
        hideUnselectableBranches={hideUnselectableBranches}
      />
    );
  } else if (renderMode === 'list') {
    return (
      <NonReduxPickerList
        selectedItems={selectedItems}
        renderListItem={item => item.name}
        onSelectionChanged={onApply}
        keyMapper={(item) => item.id}
        title="Topics/Sub-Topics"
        isRequired={isRequired}
        onLoadItems={loadItems}
        allowMultiSelect={allowMultiselect}
        tagsProps={[{
          label: "Selected Topics",
          renderItem: (item) => item?.item?.name,
          filterList: (item) => item.level === 1,
        }, {
          label: "Selected Sub-Topics",
          renderItem: (item) => item?.item?.name,
          filterList: (item) => item.level === 2,
        }]}
        filterItems={pickerFilterItems}
        childMapper={(item) => item.children || []}
        isDisabledMapper={isDisabledMapper}
        isDisabled={isDisabled}
        showSuggestedItems={showSuggestions}
        showSelectedItems={showSelectedItems}
        hideUnselectableBranches={hideUnselectableBranches}
        hideLabelAboveSearch={hideLabelAboveSearch}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

export default AuditTopicPicker;
