import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import config from "config";
import React, { useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import ToggleSwitch from "shared/components/controls/toggle-switch/ToggleSwitch";
import DataGrid from "shared/components/layout/grid/DataGrid";
import DataGridExportButton from "shared/components/layout/grid/export-button/DataGridExportButton";
import { IQueryStringValues } from "shared/components/layout/grid/types/dataGridTypes";
import Page from "shared/components/layout/page/Page";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import DownloadIcon from "shared/media/dls/download.svg";
import filterIcon from "shared/media/dls/filter.svg";
import UploadIcon from "shared/media/dls/upload.svg";
import usePageRestriction from "shared/utilities/hooks/usePageRestriction";
import { openBulkUploadQuestionsModal, startBulkQuestionsTemplateDownload } from "store/admin/questions/bulk-upload-questions/BulkUploadQuestionsSlice";
import { clearGridFilters, refreshGrid } from "store/grid/GridSlice";
import { clearAppliedFilterValues, toggleModal } from "store/question-library-filters/QuestionLibraryFiltersSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditPages, PageAuthRequestType, ViewRestriction } from "types/auditPageAuthTypes";
import BulkUploadQuestionsModal from "./bulk-upload-questions/BulkUploadQuestionsModal";
import MoreQuestionLibraryFiltersModal from "./more-filters/MoreQuestionLibraryFiltersModal";

const QL_GRID_COL_WIDTHS = {
  SMALL: 130,
  MEDIUM: 150,
  LARGE: 200,
  XLARGE: 250,
};

const QuestionLibraryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBulkUploadQuestionsModalVisible = useAppSelector(store => store.bulkUploadQuestions.isBulkUploadQuestionsModalVisible);
  const hasMoreFiltersApplied = useAppSelector(store => store.questionLibraryFilters.hasFiltersApplied);
  const appliedMoreFilters = useAppSelector(store => store.questionLibraryFilters.appliedFilters);
  const downloadOp = useAppSelector(store => store.bulkUploadQuestions.downloadOp);
  const [isOnlyEditableChecked, setIsOnlyEditableChecked] = useState<boolean>(false);

  const gridUrl = `${config.endpoints.baseUrl}/odata/questions${isOnlyEditableChecked
    ? "?editableOnly=true"
    : ""}`;

  // Build a query to send the More Filters values along with the grid request.
  const query = useMemo(() => {
    const qsPairs: IQueryStringValues[] = [];

    appliedMoreFilters.auditTopics.forEach((item, ix) => qsPairs.push({ key: `atIds[${ix}]`, value: item.id.toString() }));
    appliedMoreFilters.businessFunctions.forEach((item, ix) => qsPairs.push({ key: `bfIds[${ix}]`, value: item.id.toString() }));
    appliedMoreFilters.businessTeams.forEach((item, ix) => qsPairs.push({ key: `btIds[${ix}]`, value: item.id.toString() }));
    appliedMoreFilters.businessViews.forEach((item, ix) => qsPairs.push({ key: `bvIds[${ix}]`, value: item.id.toString() }));

    return qsPairs;
  }, [appliedMoreFilters]);

  // Get the user's restriction for the "New Question" page to find if the button should appear or not.
  const newQuestionPagePermission = usePageRestriction({ pageName: AuditPages.NewQuestion, type: PageAuthRequestType.nonAudit });

  // Get the user's restriction for the "Bulk Upload" page to find if the related buttons should appear or not.
  const bulkUploadQuestionsPagePermission = usePageRestriction({ pageName: AuditPages.BulkUploadQuestions, type: PageAuthRequestType.nonAudit });

  const hasAnyEditPermission = newQuestionPagePermission
    .data
    ?.viewRestriction !== undefined
    && newQuestionPagePermission
      .data
      .viewRestriction !== ViewRestriction.ViewNone;

  const hasAnyBulkUploadQuestionsPagePermission = bulkUploadQuestionsPagePermission
    .data
    ?.viewRestriction !== undefined
    && bulkUploadQuestionsPagePermission
      .data
      .viewRestriction !== ViewRestriction.ViewNone;

  const clearFilters = () => {
    if (hasMoreFiltersApplied) {
      dispatch(clearAppliedFilterValues());
    }

    dispatch(clearGridFilters({ gridId: "QuestionLibraryGrid" }));
  };

  const reloadGridView = () => {
    dispatch(refreshGrid({ gridId: "QuestionLibraryGrid" }));
  };

  return (
    <Page
      title="Audit Question Library"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={getHeaderControls(hasAnyEditPermission,
        true,
        clearFilters,
        navigate,
        hasAnyBulkUploadQuestionsPagePermission,
        dispatch,
        hasMoreFiltersApplied,
        isOnlyEditableChecked,
        setIsOnlyEditableChecked)
      }
    >
      {downloadOp?.isWorking && (
        <ModalSpinner />
      )}

      {isBulkUploadQuestionsModalVisible && (
        <BulkUploadQuestionsModal
          onComplete={reloadGridView}
        />
      )}

      <DataGrid
        id="QuestionLibraryGrid"
        dataSource="OData"
        dataUrl={gridUrl}
        extraQueryString={query}
        showColumnChooser={true}
        allowResizing={true}
        allowExcelExport={true}
        allowColumnReordering={true}
        enablePersistence={true}
        pageSize={50}
        gridColumns={[
          {
            uid: "number",
            field: "number",
            headerText: "Number",
            type: "number",
            width: QL_GRID_COL_WIDTHS.SMALL,
            showInColumnChooser: false,
            template: row =>
              <button
                onClick={() => navigate(formatRoute(UrlRoutes.EditQuestion, { id: row.id.toString() }))}
                className="link"
              >
                {row.number}
              </button>,
          }, {
            uid: "legacyQId",
            field: "legacyQId",
            headerText: "Legacy ID",
            type: "number",
            width: QL_GRID_COL_WIDTHS.SMALL,
            template: row => row.legacyQId?.toString(),
          }, {
            uid: "quid",
            field: "quid",
            headerText: "Question UID",
            type: "string",
            width: QL_GRID_COL_WIDTHS.SMALL,
          }, {
            uid: "version",
            field: "version",
            headerText: "Version",
            type: "number",
            width: QL_GRID_COL_WIDTHS.SMALL,
          }, {
            uid: "isActive",
            field: "isActive",
            headerText: "Active",
            type: "boolean",
            width: QL_GRID_COL_WIDTHS.SMALL,
            showInColumnChooser: false,
          }, {
            uid: "category",
            field: "category",
            headerText: "Category",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "question",
            field: "question",
            headerText: "Question",
            type: "string",
            width: QL_GRID_COL_WIDTHS.XLARGE,
            minWidth: QL_GRID_COL_WIDTHS.XLARGE,
            showInColumnChooser: false,
          }, {
            uid: "guidance",
            field: "guidance",
            headerText: "Guidance",
            type: "string",
            width: QL_GRID_COL_WIDTHS.XLARGE,
            showInColumnChooser: false,
          }, {
            uid: "recommendedRWP",
            field: "recommendedRWP",
            headerText: "Recommended RWP",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "verificationMethods",
            field: "verificationMethods",
            headerText: "Verification Method",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            template: row => splitList(row.verificationMethods),
          }, {
            uid: "allowNA",
            field: "allowNA",
            headerText: "Allow N/A",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "questionType",
            field: "questionType",
            headerText: "Question Type",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "auditGroup",
            field: "auditGroup",
            headerText: "Audit Group",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "ownerGroup",
            field: "ownerGroup",
            headerText: "Owner Group",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "clause",
            field: "clause",
            headerText: "Clause",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "clmFunction",
            field: "clmFunction",
            headerText: "CLM Function",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "clmWhomToCheck",
            field: "clmWhomToCheck",
            headerText: "With Whom to Check",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "topic",
            field: "topic",
            headerText: "Topic",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            showInColumnChooser: false,
          }, {
            uid: "subTopics",
            field: "subTopics",
            headerText: "Sub-Topic",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            template: row => splitList(row.subTopics),
          }, {
            uid: "hsemsSubElement",
            field: "hsemsSubElement",
            headerText: "HSE MS Sub-Elements",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
          }, {
            uid: "qmsElements",
            field: "qmsElements",
            headerText: "QMS Sub-Elements",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            template: row => splitList(row.qmsElements),
          }, {
            uid: "basins",
            field: "basins",
            headerText: "Basin",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.basins),
          }, {
            uid: "geoUnits",
            field: "geoUnits",
            headerText: "GeoUnit",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.geoUnits),
          }, {
            uid: "subGeoUnits",
            field: "subGeoUnits",
            headerText: "Sub GeoUnit",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.subGeoUnits),
          }, {
            uid: "countries",
            field: "countries",
            headerText: "Country",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.countries),
          }, {
            uid: "divisions",
            field: "divisions",
            headerText: "Division",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.divisions),
          }, {
            uid: "businessLines",
            field: "businessLines",
            headerText: "Business Line",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            template: row => splitList(row.businessLines),
          }, {
            uid: "subBusinessLines",
            field: "subBusinessLines",
            headerText: "Sub Business Line",
            type: "string",
            width: QL_GRID_COL_WIDTHS.LARGE,
            template: row => splitList(row.subBusinessLines),
          }, {
            uid: "functions",
            field: "functions",
            headerText: "Function",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.functions),
          }, {
            uid: "subFunctions",
            field: "subFunctions",
            headerText: "Sub Function",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.subFunctions),
          }, {
            uid: "EpicBrands",
            field: "EpicBrands",
            headerText: "Epic - Brand",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.epicBrands),
          }, {
            uid: "EpicProductFamilies",
            field: "EpicProductFamilies",
            headerText: "Epic - Product Family",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.epicProductFamilies),
          }, {
            uid: "EpicPLTechnologies",
            field: "EpicPLTechnologies",
            headerText: "Epic - P/L Technology",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.epicPLTechnologies),
          }, {
            uid: "EpicEquipmentCodes",
            field: "EpicEquipmentCodes",
            headerText: "Epic - Equipment Code",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
            template: row => splitList(row.epicEquipmentCodes),
          }, {
            uid: "api",
            field: "api",
            headerText: "API",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "iso",
            field: "iso",
            headerText: "ISO",
            type: "string",
            width: QL_GRID_COL_WIDTHS.MEDIUM,
          }, {
            uid: "licenseToOperate",
            field: "licenseToOperate",
            headerText: "LTO",
            type: "boolean",
            width: QL_GRID_COL_WIDTHS.SMALL,
            showInColumnChooser: false,
          }, {
            uid: "isGlobal",
            field: "isGlobal",
            headerText: "Global",
            type: "string",
            width: QL_GRID_COL_WIDTHS.SMALL,
            showInColumnChooser: false,
          },
          // {
          //   uid: "sortOrder",
          //   field: "sortOrder",
          //   headerText: "Sort Order",
          //   type: "number",
          //   width: QL_GRID_COL_WIDTHS.SMALL,
          //   showInColumnChooser: false,
          // },
        ]}
        recordDoubleClick={row => {
          navigate(formatRoute(UrlRoutes.EditQuestion, { id: row.id }));
        }}
      />

      <MoreQuestionLibraryFiltersModal />
    </Page>
  );
};

export default QuestionLibraryPage;

/**
 * Splits an input value by "|", orders the chunks, and recombines using ", ".
 * @param value The input value (will be toString()'ed).
 * @returns A newly combined string (or empty string in input was falseynull or empty).
 */
function splitList(value: any) {
  return (value === undefined
    || value === null
    ? ""
    : value.toString())
    .split("|")
    .sort((a: string, b: string) => a < b ? -1 : 1)
    .join(", ");
}

function getHeaderControls(hasAnyEditPermission: boolean,
  hasFilter: boolean,
  clearFilters: () => void,
  navigate: NavigateFunction,
  hasAnyBulkUploadQuestionsPagePermission: boolean,
  dispatch: Dispatch<AnyAction>,
  hasMoreFiltersApplied: boolean,
  isEditableOnlyChecked: boolean,
  onEditableOnlyChanged: (isChecked: boolean) => void): IPageHeaderControl[] {
  const controls: IPageHeaderControl[] = [];

  hasFilter && controls.push({
    element: (
      <Button
        buttonType="secondary"
        onClick={clearFilters}
      >
        Clear Filters
      </Button>
    ),
    key: 'btnClearFilter'
  });

  if (hasAnyEditPermission) {
    controls.push({
      element: (
        <ToggleSwitch
          isChecked={isEditableOnlyChecked}
          onChange={onEditableOnlyChanged}
          label="Show only editable questions"
        />
      ),
      key: "EDITABLE TOGGLE",
    });

    controls.push({
      element: (
        <Button
          buttonType="primary"
          onClick={() => navigate(UrlRoutes.NewQuestion.urlTemplate)}
        >
          Create New Question
        </Button>
      ),
      key: "NEW QUESTION",
    });

    if (hasAnyBulkUploadQuestionsPagePermission) {
      controls.push({
        element: (
          <Button
            buttonType="red"
            img={UploadIcon}
            onClick={() => dispatch(openBulkUploadQuestionsModal())}
          >
            Bulk Upload Questions
          </Button>
        ),
        key: "BULK QUESTION UPLAOD",
      });

      controls.push({
        element: (
          <Button
            buttonType="secondary"
            img={DownloadIcon}
            onClick={() => dispatch(startBulkQuestionsTemplateDownload())}
          >
            Download Template
          </Button>
        ),
        key: "BULK QUESTION TEMPLATE",
      });
    }
  }

  controls.push(...[{
    element: (
      <DataGridExportButton
        gridId="QuestionLibraryGrid"
      />
    ),
    key: "Export",
  }, {
    element: (
      <Button
        buttonType="secondary"
        img={filterIcon}
        imgPlacement="right"
        onClick={() => dispatch(toggleModal(true))}
      >
        More Filters{hasMoreFiltersApplied && "*"}
      </Button>
    ),
    key: "More Filters",
  }]);

  return controls;
};
