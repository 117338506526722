import ClmPartialPlanApi from "api/planning/clm/ClmPartialPlanApi";
import { cloneDeep, isEqual } from "lodash";
import React, { useState } from "react";
import Divider from "shared/components/common/divider/Divider";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal, { ModalSizes } from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { IApprovalModalItem, setCurrentEditPlanApprovalData, setEditPlanModal } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { updatePlanUserSelfAssessmentType } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch } from "store/store";
import { AuditPlanApprovalStatuses, ClmSelfAssessmentTypes, ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";
import CLMSubTopicRequirements from "./CLMSubTopicRequirements";
import CLMTopicTabs from "./CLMTopicTabs";
import "./EditCLMPartialPlanModal.scoped.scss";

interface IEditCLMPlanModalProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  planData: IApprovalModalItem,
}

const EditCLMPartialPlanModal: React.FC<IEditCLMPlanModalProps> = ({
  isOpen,
  planData,
}: IEditCLMPlanModalProps) => {
  const planId = planData.plan.id;

  const [selfAssessmentType, setSelfAssessmentType] = useState<ClmSelfAssessmentTypes>(planData.plan.userSelectedCLMSelfAssessmentType
    ?? planData.plan.requiredCLMSelfAssessmentType
    ?? ClmSelfAssessmentTypes.Partial);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState<boolean>(true);
  const [requirements, setRequirements] = useState<ICLMPartialRequirementItemDto[]>([]);
  const [originalRequirements, setOriginalRequirements] = useState<ICLMPartialRequirementItemDto[]>([]);
  const [selectedTopicId, setSelectedTopicId] = useState<number>(0);
  const dispatch = useAppDispatch();

  const hasNextScreenAvailable = planData.plan.approvalStatus === AuditPlanApprovalStatuses.FinalApproval;

  useAsyncEffect(async (aborted, abortSignal) => {
    try {
      setIsSpinnerVisible(true);
      const reqs = await ClmPartialPlanApi.getPartialPlanRequirementItems(planId,
        abortSignal);
      if (aborted) {
        return;
      }
      setRequirements(reqs);
      setOriginalRequirements(cloneDeep(reqs));
      setSelectedTopicId(reqs[0].topicId);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      if (aborted) {
        return;
      }
      setIsSpinnerVisible(false);
    }
  }, [setRequirements, setOriginalRequirements, setIsSpinnerVisible, setSelectedTopicId, dispatch, planId]);

  const onSubTopicOptInChanged = (subTopicId: number, isOptedIn: boolean) => {
    const ix = requirements
      .findIndex(x =>
        !x.isInScope
        && x.subTopicId === subTopicId);

    if (ix === -1) {
      return;
    }

    const newReqs = cloneDeep(requirements);
    newReqs[ix].isOptedIn = isOptedIn;

    setRequirements(newReqs);
  };

  const onTopicOptInChanged = (topicId: number, isOptedIn: boolean) => {
    const newReqs = cloneDeep(requirements);
    newReqs
      .filter(x => x.topicId === topicId
        && !x.isInScope)
      .forEach(x => x.isOptedIn = isOptedIn);

    setRequirements(newReqs);
  };

  const onCancel = () => dispatch(setEditPlanModal({ isOpen: false, }));

  const onSave = async () => {
    try {
      let saveReqsPromise: Promise<void> | null = null;
      let setTypePromise: Promise<void> | null = null;

      if (!isEqual(requirements, originalRequirements)) {
        saveReqsPromise = ClmPartialPlanApi.savePartialPlanRequirementItems(planData.plan.id,
          requirements);
      }

      if ((planData.plan.userSelectedCLMSelfAssessmentType
        ?? planData.plan.requiredCLMSelfAssessmentType) !== selfAssessmentType) {
        setTypePromise = ClmPartialPlanApi.setPartialPlanSelfAssessmentType(planData.plan.id,
          selfAssessmentType);
      }

      // Only try to save something to the server if something changed.
      if (saveReqsPromise
        || setTypePromise) {
        setIsSpinnerVisible(true);

        // Wait for either or both of the requests.
        await saveReqsPromise;
        await setTypePromise;

        setIsSpinnerVisible(false);

        if (setTypePromise) {
          // Update the plan data in Redux to the new type (if necessary).
          dispatch(updatePlanUserSelfAssessmentType({
            planId: planData.plan.id,
            userSelectedCLMSelfAssessmentType: selfAssessmentType,
          }));
        }

        dispatch(showSuccessToast("Self Assessment Edited Succesfully"));
      }

      if (hasNextScreenAvailable) {
        // CLM plan data was saved (or skipped).
        // Move to the normal edit modal now.
        dispatch(setCurrentEditPlanApprovalData({
          isEditingCLMPartial: false,
        }));
      } else {
        // Close the modal.
        onCancel();
      }
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      setIsSpinnerVisible(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      header="Change Audit Plan"
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "applyChanges",
        text: hasNextScreenAvailable
          ? "Next"
          : "Apply",
        className: "primary",
        disabled:
          !hasNextScreenAvailable
          && (!requirements.length
            || (isEqual(requirements, originalRequirements)
              && (planData.plan.userSelectedCLMSelfAssessmentType
                ?? planData.plan.requiredCLMSelfAssessmentType) === selfAssessmentType
            )),
        onClick: onSave,
      }]}
      showCloseButton
      onCloseButtonClicked={onCancel}
      size={ModalSizes.maximumHeight}
    >
      <FlexCol>
        <LabeledControl
          label="Self-Assessment Type"
        >
          <FlexRow
            className="self-type-entry"
          >
            <label>
              <input
                type="radio"
                checked={selfAssessmentType === ClmSelfAssessmentTypes.Partial}
                onChange={_ => setSelfAssessmentType(ClmSelfAssessmentTypes.Partial)}
              />
              {ClmSelfAssessmentTypes.Partial} Audit
            </label>

            <label>
              <input
                type="radio"
                checked={selfAssessmentType === ClmSelfAssessmentTypes.Full}
                onChange={_ => setSelfAssessmentType(ClmSelfAssessmentTypes.Full)}
              />
              {ClmSelfAssessmentTypes.Full} Audit
            </label>

            <span
              className="divider"
            >
              |
            </span>

            <span
              className="note"
            >
              {selfAssessmentType === ClmSelfAssessmentTypes.Partial
                ? "Note: Partial Audit will include all non-compliant procedures from previous audit. You can also add-in additional procedures for which self-assessment has to be done."
                : "Note: All packages and procedures identified as implemented in the facility attributes page will included in the Full Audit."
              }
            </span>
          </FlexRow>
        </LabeledControl>

        {selfAssessmentType === ClmSelfAssessmentTypes.Partial && (
          <>
            <Divider />

            <CLMTopicTabs
              requirements={requirements}
              onTopicChange={setSelectedTopicId}
              selectedTopicId={selectedTopicId}
              onTopicOptInChanged={onTopicOptInChanged}
            />

            <CLMSubTopicRequirements
              requirements={requirements}
              selectedTopicId={selectedTopicId}
              onSubTopicOptInChanged={onSubTopicOptInChanged}
            />
          </>
        )}
      </FlexCol>

      {isSpinnerVisible && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default EditCLMPartialPlanModal;