import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import pickerHandlers, { ISetPickerSelectedItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getTodayMidnight } from "shared/utilities/dateFormatters";
import { AuditStatuses, IAudit, IOwnerGroup, ITemplate } from "types/auditingTypes";
import { IAuditGroup, IAuditTopic, IAuditType } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, IFacility, IIPMProject, IMobileSite, ISupplier } from "types/masterDataTypes";
import { IDetailedTemplate } from "types/templateApiTypes";

export interface IManageAuditState {
  pickerData: {
    auditTypes: IPickerState<IAuditType>,
    auditGroups: IPickerState<IAuditGroup>,
    businessViews: IPickerState<IBusinessView>,
    businessFunctions: IPickerState<IBusinessFunction>,
    businessTeams: IPickerState<IBusinessTeam>,
    leadAuditors: IPickerState<IAzureADUser>,
    countries: IPickerState<ICountry>,
    ownerGroups: IPickerState<IOwnerGroup>,
    auditTopics: IPickerState<IAuditTopic>,
  },
  audit: IAudit,
  originalAudit: IAudit,
  loadAuditOperation?: IOperation<IAudit>,
  saveAuditOperation?: IOperation<number>,
  deleteAuditOperation?: IOperation<number>,
  askDeleteAuditId?: number,
  showQuestionRemovalOverride: boolean,
  isDirty: boolean,
  detailedTemplateInfo: IDetailedTemplate[],
  loadDetailedTemplateInfoOperation?: IOperation<void>,
}

export enum ManageAuditPickerKeys {
  auditTypes = "auditTypes",
  auditGroups = "auditGroups",
  businessViews = "businessViews",
  businessFunctions = "businessFunctions",
  businessTeams = "businessTeams",
  leadAuditors = "leadAuditors",
  countries = "countries",
  ownerGroups = "ownerGroups",
  auditTopics = "auditTopics",
}

const initialState: IManageAuditState = {
  pickerData: {
    auditTypes: {
      key: ManageAuditPickerKeys.auditTypes,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditGroups: {
      key: ManageAuditPickerKeys.auditGroups,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessViews: {
      key: ManageAuditPickerKeys.businessViews,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessFunctions: {
      key: ManageAuditPickerKeys.businessFunctions,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessTeams: {
      key: ManageAuditPickerKeys.businessTeams,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    leadAuditors: {
      key: ManageAuditPickerKeys.leadAuditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    countries: {
      key: ManageAuditPickerKeys.countries,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    ownerGroups: {
      key: ManageAuditPickerKeys.ownerGroups,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditTopics: {
      key: ManageAuditPickerKeys.auditTopics,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
  audit: {
    id: 0,
    name: "",
    recap: "",
    startDateTime: getTodayMidnight().getTime(),
    endDateTime: getTodayMidnight().getTime(),
    auditGroup: undefined,
    templates: [],
    auditType: undefined,
    status: AuditStatuses.Planned,
    includeAllAPIQ2Questions: false,
    leadAuditorEmail: undefined,
    leadAuditorName: undefined,
    businessViews: [],
    businessFunctions: [],
    businessTeams: [],
    countries: [],
    facilities: [],
    ownerGroups: [],
    auditTopics: [],
    mobileSites: [],
    ipmProjects: [],
    suppliers: [],
  },
  originalAudit: {
    id: 0,
    name: "",
    recap: "",
    startDateTime: getTodayMidnight().getTime(),
    endDateTime: getTodayMidnight().getTime(),
    auditGroup: undefined,
    templates: [],
    auditType: undefined,
    status: AuditStatuses.Planned,
    includeAllAPIQ2Questions: false,
    leadAuditorEmail: undefined,
    leadAuditorName: undefined,
    businessViews: [],
    businessFunctions: [],
    businessTeams: [],
    countries: [],
    facilities: [],
    ownerGroups: [],
    auditTopics: [],
    mobileSites: [],
    ipmProjects: [],
    suppliers: [],
  },
  loadAuditOperation: undefined,
  saveAuditOperation: undefined,
  deleteAuditOperation: undefined,
  askDeleteAuditId: undefined,
  showQuestionRemovalOverride: false,
  isDirty: false,
  detailedTemplateInfo: [],
  loadDetailedTemplateInfoOperation: undefined,
};

export const manageAuditSlice = createSlice({
  name: "manage-audit",
  initialState,
  reducers: {
    ...pickerHandlers,
    setSelectedPickerItems: (state, action: PayloadAction<ISetPickerSelectedItemsAction<any>>) => {
      if (action.payload.pickerKey === ManageAuditPickerKeys.auditTopics) {
        // Applying audit topics is handled in a saga.
        handleGroupTopicRestriction(state, action.payload.selectedItems.map(x => x.item as IAuditTopic));
        state.isDirty = calculateIsDirty(state);
        return;
      }

      // This is a custom handler for setting picker handlers after the saga has retrieved
      // them from the server.

      // Call the base method too.
      pickerHandlers.setSelectedPickerItems(state, action);

      // Set the selected values into the audit instead of the pickerstate.
      setAuditPickerItems(state.audit,
        action.payload.pickerKey,
        action.payload.selectedItems);

      if ((action.payload.pickerKey === ManageAuditPickerKeys.auditGroups)) {
        handleGroupTopicRestriction(state);
      }

      if (action.payload.pickerKey === ManageAuditPickerKeys.auditGroups) {
        state.audit.templates = [];
        state.audit.auditTopics = [];
        state.pickerData.auditTopics.selectedItems = [];
      }

      state.isDirty = calculateIsDirty(state);
    },
    setDefaultSelectedPickerItems: (state, action: PayloadAction<ISetPickerSelectedItemsAction<any>>) => {
      // Also copy the selected values into the ORIGINAL audit.
      setAuditPickerItems(state.originalAudit,
        action.payload.pickerKey,
        action.payload.selectedItems);

      state.isDirty = calculateIsDirty(state);
    },
    setStartDateTime: (state, action: PayloadAction<number>) => {
      state.audit.startDateTime = action.payload;

      if (action.payload !== undefined
        && state.audit.endDateTime !== undefined
        && state.audit.endDateTime < action.payload) {
        state.audit.endDateTime = action.payload;
      }

      state.isDirty = calculateIsDirty(state);
    },
    setEndDateTime: (state, action: PayloadAction<number>) => {
      if (action.payload !== undefined
        && state.audit.startDateTime !== undefined
        && action.payload < state.audit.startDateTime) {
        return;
      }

      state.audit.endDateTime = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
    setIncludeAPIQ2: (state, action: PayloadAction<boolean>) => {
      state.audit.includeAllAPIQ2Questions = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
    /** Sets partially the audit being created/edited properties. */
    setAuditProperties: (state, action: PayloadAction<Partial<IAudit>>) => {
      if (state.audit) {
        Object.assign(state.audit, action.payload);

        // Update audit topic picker to topics from template
        if ("auditTopics" in action.payload) {
          state.pickerData.auditTopics.selectedItems = state.audit.auditTopics.map(x => ({
            key: x.id,
            disabled: false,
            item: x,
            isSelectable: x.isSelectable,
          }));
        }

        handleGroupTopicRestriction(state);

        state.isDirty = calculateIsDirty(state);
      }
    },
    loadAudit: (state, _: PayloadAction<number>) => {
      state.loadAuditOperation = {
        isWorking: true,
      };
    },
    finishLoadingAudit: (state, action: PayloadAction<IOperation<IAudit>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadAuditOperation = action.payload;
        return;
      }

      const audit = action.payload.data;
      state.audit = cloneDeep(audit);
      state.originalAudit = cloneDeep(audit);
      state.loadAuditOperation = undefined;
      state.isDirty = false;

      // Assign picker data as appropriate.
      if (audit.auditGroup) {
        state.pickerData.auditGroups.selectedItems = [{
          key: audit.auditGroup.id,
          disabled: false,
          text: audit.auditGroup.name,
        }];
      }

      if (audit.auditType) {
        state.pickerData.auditTypes.selectedItems = [{
          key: audit.auditType.id,
          disabled: false,
          text: audit.auditType.name,
        }];
      }

      if (audit.leadAuditorEmail) {
        state.pickerData.leadAuditors.selectedItems = [{
          key: audit.leadAuditorEmail,
          disabled: false,
          item: {
            name: audit.leadAuditorName || '',
            email: audit.leadAuditorEmail,
          },
        }];
      }

      const businessViews = audit.businessViews;

      state.pickerData.businessViews.selectedItems = businessViews.map(x => ({
        key: x.id,
        disabled: false,
        item: x,
      }));

      const businessFunctions = audit.businessFunctions;

      state.pickerData.businessFunctions.selectedItems = businessFunctions.map(x => ({
        key: x.id,
        disabled: false,
        item: x,
      }));

      const businessTeams = audit.businessTeams;

      state.pickerData.businessTeams.selectedItems = businessTeams.map(x => ({
        key: x.id,
        disabled: false,
        item: x,
      }));

      state.pickerData.countries.selectedItems = audit.countries.map(x => ({
        key: x.id,
        disabled: false,
        text: x.name,
      }));

      state.pickerData.ownerGroups.selectedItems = audit.ownerGroups.map(x => ({
        key: x.id,
        disabled: false,
        text: x.name,
      }));

      const topics = audit.auditTopics;

      state.pickerData.auditTopics.selectedItems = topics.map(x => ({
        key: x.id,
        disabled: false,
        item: x,
        isSelectable: x.isSelectable,
      }));
    },
    resetAudit: (state, action: PayloadAction<IAzureADUser | undefined>) => {
      Object.assign(state, cloneDeep(initialState));

      if (action.payload) {
        state.audit.leadAuditorEmail = action.payload.email;
        state.audit.leadAuditorName = action.payload.name;
        state.originalAudit.leadAuditorEmail = action.payload.email;
        state.originalAudit.leadAuditorName = action.payload.name;
      }
    },
    setPlanName: (state, action: PayloadAction<string>) => {
      state.audit.name = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
    setFacilities: (state, action: PayloadAction<IFacility[]>) => {
      state.audit.facilities = action.payload;
      state.isDirty = calculateIsDirty(state);

      state.pickerData.countries.items = [];
    },
    setRecap: (state, action: PayloadAction<{ recap: string, shouldSetOriginal?: boolean; }>) => {
      state.audit.recap = action.payload.recap;
      if (action.payload.shouldSetOriginal) {
        state.originalAudit.recap = action.payload.recap;
      }

      state.isDirty = calculateIsDirty(state);
    },
    saveAudit: (state, _: PayloadAction<{
      allowDeletingAnsweredQuestions: boolean,
    }>) => {
      state.audit.name = state.audit.name.trim();

      state.saveAuditOperation = {
        isWorking: true,
      };
    },
    dismissQuestionRemovalOverride: (state) => {
      state.showQuestionRemovalOverride = false;
    },
    finishSavingAudit: (state, action: PayloadAction<{
      auditId: number | undefined,
      showQuestionRemovalOverride: boolean,
      wasSuccessful: boolean,
    }>) => {
      state.saveAuditOperation = undefined;
      state.audit.id = action.payload.auditId === undefined
        ? 0
        : action.payload.auditId;
      state.showQuestionRemovalOverride = action.payload.showQuestionRemovalOverride;

      if (action.payload.wasSuccessful) {
        state.originalAudit = cloneDeep(state.audit);
      }

      state.isDirty = calculateIsDirty(state);
    },
    askDeleteAudit: (state, action: PayloadAction<number>) => {
      state.askDeleteAuditId = action.payload;
    },
    dismissDeleteConfirmation: (state) => {
      state.askDeleteAuditId = undefined;
    },
    deleteAudit: (state, _: PayloadAction<number>) => {
      state.deleteAuditOperation = {
        isWorking: true,
      };
      state.askDeleteAuditId = undefined;
    },
    finishDeletingAudit: (state) => {
      state.deleteAuditOperation = undefined;
    },
    setSelectedMobileSites: (state, action: PayloadAction<IMobileSite[]>) => {
      state.audit.mobileSites = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
    setSelectedIPMProjects: (state, action: PayloadAction<IIPMProject[]>) => {
      state.audit.ipmProjects = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
    setSelectedSuppliers: (state, action: PayloadAction<ISupplier[]>) => {
      state.audit.suppliers = action.payload;
      state.isDirty = calculateIsDirty(state);
    },

    /** Sets the detailed template info for use when the user adds/removes/changes audit topics or templates in the audit. */
    setDetailedTemplateInfo: (state, action: PayloadAction<IDetailedTemplate[]>) => {
      state.detailedTemplateInfo = action.payload;
    },

    /** Sets the load detailed template info operation. */
    setLoadDetailedTemplateInfoOperation: (state, action: PayloadAction<IOperation<void>>) => {
      state.loadDetailedTemplateInfoOperation = action.payload;
    },
    /** Dispatched when the user XXXXX. Handled by a saga. */
    removeAuditTopic: (_, __: PayloadAction<IAuditTopic>) => { },
    /** Dispatched when the user XXXXX. Handled by a saga. */
    removeTemplate: (_, __: PayloadAction<ITemplate>) => { },
    /** Dispatched when the user XXXXX. Handled by a saga. */
    applyTemplateSelections: (_, __: PayloadAction<{
      templates: ITemplate[],
      auditTopics: IAuditTopic[],
    }>) => { },
    /** Dispatched when the user XXXXX. Handled by a saga. */
    applyAuditTopicSelections: (_, __: PayloadAction<IAuditTopic[]>) => { },
  },
});

function calculateIsDirty(state: IManageAuditState): boolean {
  state.isDirty = !isEqual(state.audit, state.originalAudit);

  return state.isDirty;
}

function setAuditPickerItems(audit: IAudit,
  pickerKey: string,
  selectedItems: IPickerItem<any>[]) {

  if (pickerKey === ManageAuditPickerKeys.auditGroups) {
    if (!selectedItems.length) {
      audit.auditGroup = undefined;
    } else {
      audit.auditGroup = {
        id: Number(selectedItems[0].key),
        name: selectedItems[0].text ?? "",
        isDeleted: false,
      };
    }
  } else if (pickerKey === ManageAuditPickerKeys.auditTypes) {
    if (!selectedItems.length) {
      audit.auditType = undefined;
    } else {
      audit.auditType = {
        id: Number(selectedItems[0].key),
        name: selectedItems[0].text || "",
        isDeleted: false,
      };
    }
  } else if (pickerKey === ManageAuditPickerKeys.ownerGroups) {
    audit.ownerGroups = selectedItems.map((x): IOwnerGroup => ({
      id: Number(x.key),
      name: x.text || "",
      isDeleted: false,
      scoringSystem: x.item.scoringSystem || "",
    }));
  } else if (pickerKey === ManageAuditPickerKeys.leadAuditors) {
    if (!selectedItems.length) {
      audit.leadAuditorEmail = undefined;
      audit.leadAuditorName = undefined;
    } else {
      audit.leadAuditorEmail = selectedItems[0].item.email;
      audit.leadAuditorName = selectedItems[0].item.name;
    }
  } else if (pickerKey === ManageAuditPickerKeys.businessViews) {
    audit.businessViews = mapPickerItems<IBusinessView>(selectedItems);
  } else if (pickerKey === ManageAuditPickerKeys.businessFunctions) {
    audit.businessFunctions = mapPickerItems<IBusinessFunction>(selectedItems);
  } else if (pickerKey === ManageAuditPickerKeys.businessTeams) {
    audit.businessTeams = mapPickerItems<IBusinessTeam>(selectedItems);
  } else if (pickerKey === ManageAuditPickerKeys.countries) {
    audit.countries = selectedItems.map((x): ICountry => ({
      id: Number(x.key),
      name: x.text || "",
    }));
  } else if (pickerKey === ManageAuditPickerKeys.auditTopics) {
    audit.auditTopics = mapPickerItems<IAuditTopic>(selectedItems);
  }
}

function mapPickerItems<T>(selectedItems: IPickerItem<T>[], filter?: (item: T) => boolean): T[] {
  if (!filter) {
    return selectedItems
      .map(x => x.item as T);
  }

  return selectedItems
    .filter(x => x.item
      && filter(x.item))
    .map(x => x.item as T);
}

function handleGroupTopicRestriction(state: IManageAuditState, auditTopicOverride?: IAuditTopic[]) {
  if (state.audit.auditGroup?.name.toLowerCase() === "techcat"
    && (auditTopicOverride
      ?? state.audit.auditTopics).some(topic => topic.ownerGroup.toLowerCase() === "integrated")) {
    state.audit.facilities = [];
  }
}

export const {
  openPicker,
  closePicker,
  loadPickerItems,
  loadSuggestedPickerItems,
  setPickerError,
  setPickerItems,
  setSuggestedPickerItems,
  setSelectedPickerItems,
  expandPickerItem,
  collapsePickerItem,
  setDefaultSelectedPickerItems,
  setAuditProperties,
  removeAuditTopic,
  removeTemplate,
  applyTemplateSelections,
  applyAuditTopicSelections,
  setLoadDetailedTemplateInfoOperation,
  setDetailedTemplateInfo,

  setStartDateTime,
  setEndDateTime,
  setIncludeAPIQ2,
  loadAudit,
  finishLoadingAudit,
  resetAudit,
  setPlanName,
  setFacilities,
  setRecap,
  saveAudit,
  dismissQuestionRemovalOverride,
  finishSavingAudit,
  askDeleteAudit,
  deleteAudit,
  dismissDeleteConfirmation,
  finishDeletingAudit,
  setSelectedMobileSites,
  setSelectedIPMProjects,
  setSelectedSuppliers,
} = manageAuditSlice.actions;
