import config from "config";
import React from "react";
import { useDispatch } from "react-redux";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import DataGrid from "shared/components/layout/grid/DataGrid";
import formatDate from 'shared/utilities/dateFormatters';
import { setOpenedQuestionLogId } from "store/question-details/QuestionDetailsSlice";
import { useAppSelector } from "store/store";
import QuestionIdDisplay from "../question-id-display/QuestionIdDisplay";
import "./QuestionHistoryCard.scoped.scss";

interface IQuestionHistoryCardProps {
  questionNumber: number | undefined,
}

/** The card named "Question Details". */
const QuestionHistoryCard: React.FC<IQuestionHistoryCardProps> = ({ questionNumber }) => {
  const question = useAppSelector(store => store.questionDetails.question);
  const url = config.endpoints.auditing.questionHistory;
  const dispatch = useDispatch();

  const renderQuestionHistoryGridRow = (row: IMyQuestionHistoryGridRowObject) => {
    return (row.hasDetails
      ? (
        <Button
          buttonType="link"
          onClick={() => dispatch(setOpenedQuestionLogId(row.id))}
        >
          View details
        </Button>
      ) : <></>
    );
  };

  if (!question) {
    return null;
  }

  return (
    <Card
      title="Question History"
      cardStateId="manage-question-question-history"
      defaultCollapsedState={true}
      headerElement={
        <QuestionIdDisplay
          question={question}
          invisible={true}
        />
      }
    >
      <DataGrid
        id="QuestionHistory"
        dataSource="OData"
        dataUrl={url}
        filterSettings={questionNumber ? {
          columns: [{
            uid: "number",
            field: "number",
            value: questionNumber,
          }]
        } : undefined}
        sortSettings={{
          columns: [{
            field: "modifiedOn",
            direction: "Descending",
          }],
        }}
        gridColumns={[{
          uid: "number",
          field: "number",
          headerText: "Question Number",
          width: 125,
          visible: false,
          template: x => x.number.toString(),
          type: "number",
        },
        {
          uid: "version",
          field: "version",
          headerText: "Question Version",
          width: 125,
          template: x => x.version.toString(),
          type: "number",
        },
        {
          uid: "action",
          field: "action",
          headerText: "Action",
          width: 150,
          type: "string",
        },
        {
          uid: "fieldsModified",
          field: "fieldsModified",
          headerText: "Fields Modified",
          width: 250,
          type: "string",
        },
        {
          uid: "hasDetails",
          field: "hasDetails",
          headerText: "Details",
          width: 150,
          type: "boolean",
          template: (row: IMyQuestionHistoryGridRowObject) => renderQuestionHistoryGridRow(row)
        },
        {
          uid: "comment",
          field: "comment",
          headerText: "Reason for Modification",
          type: "string",
          width: 300,
        },
        {
          uid: "modifiedOn",
          field: "modifiedOn",
          headerText: "Modified On",
          width: 150,
          type: "date",
          template: (row: IMyQuestionHistoryGridRowObject) => formatDate(row.modifiedOn),
        },
        {
          uid: "modifiedBy",
          field: "modifiedBy",
          headerText: "Modified By",
          width: 250,
          type: "string",
        },
        ]}
      />
    </Card>
  );
};

export default QuestionHistoryCard;

export interface IMyQuestionHistoryGridRowObject {
  id: number,
  questionId: number,
  number: number,
  version: number,
  action: string,
  hasDetails: boolean,
  fieldsModified: string,
  modifiedOn: Date,
  modifiedBy: string,
  modifiedByName: string,
}