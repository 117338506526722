import { authGetJson, authPostJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IReportTopic } from "types/auditingTypes";
import { AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";

class AuditTopicApi {
  public async searchAuditTopics(searchCriteria?: IAuditTopicSearchCriteria, abortSignal?: AbortSignal): Promise<IAuditTopic[]> {
    let url = config.endpoints.auditMasterData.auditTopics;

    if (searchCriteria) {
      let qs = formatSearchQueryString(searchCriteria.searchType,
        searchCriteria.templateIds,
        searchCriteria.auditGroupId,
        searchCriteria.ownerGroupIds,
        searchCriteria.auditTopicIds);

      url += qs;
    }

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAuditTopic(obj));
  }

  public async getSuggestedAuditTopics(searchCriteria?: IAuditTopicSearchCriteria, abortSignal?: AbortSignal): Promise<IAuditTopic[]> {
    let url = config.endpoints.auditMasterData.suggestedAuditTopics;

    if (searchCriteria) {
      let qs = formatSearchQueryString(searchCriteria.searchType,
        searchCriteria.templateIds,
        searchCriteria.auditGroupId,
        searchCriteria.ownerGroupIds);

      url += qs;
    }

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAuditTopic(obj));
  }

  public async reorderAuditTopics(reorders: {
    auditTopicId: number;
    sortOrder: number;
  }[],
    abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(config.endpoints.auditMasterData.reorderAuditTopics,
      reorders,
      abortSignal);

    await throwIfResponseError(response);
  }
}


function formatSearchQueryString(searchType?: AuditTopicSearchTypes,
  templateIds?: number[],
  auditGroupId?: number,
  ownerGroupIds?: number[],
  auditTopicIds?: number[]) {
  let qs = "";

  if (!!searchType) {
    qs += `?searchType=${searchType}`;

    if (searchType === AuditTopicSearchTypes.AuditGroup) {
      qs += `&auditGroupId=${auditGroupId || 0}`;
    } else if (searchType === AuditTopicSearchTypes.OwnerGroups) {
      qs += '&' + (ownerGroupIds || [0])
        .map(x => `ownerGroupIds=${x}`)
        .join('&');
    } else if (searchType === AuditTopicSearchTypes.Template) {
      qs += '&' + (templateIds || [0])
        .map(x => `templateIds=${x}`)
        .join('&');
    } else if (searchType === AuditTopicSearchTypes.Ids
      || searchType === AuditTopicSearchTypes.OnlySubTopicsFromIds) {
      qs += '&' + (auditTopicIds || [0])
        .map(x => `auditTopicIds=${x}`)
        .join('&');
    }
  }

  return qs;
}

export function formatAuditTopic(obj: any): IAuditTopic {
  return {
    id: Number(obj.id),
    auditGroupId: Number(obj.auditGroupId),
    auditGroup: obj.auditGroup?.toString() || "",
    level: Number(obj.level),
    parentId: obj.parentId
      ? Number(obj.parentId)
      : undefined,
    name: obj.name?.toString() || "",
    groupName: obj.groupName?.toString() || "",
    isSelectable: Boolean(obj.isSelectable),
    sortOrder: Number(obj.sortOrder),
    isDeleted: Boolean(obj.deleted),
    isPlannable: Boolean(obj.plannable),
    ownerGroupId: obj.ownerGroupId
      ? Number(obj.ownerGroupId)
      : undefined,
    ownerGroup: obj.ownerGroup?.toString() || "",
    scoringSystem: obj.scoringSystem?.toString() || "",
  };
}

export function formatAuditReportTopic(obj: any): IReportTopic {
  return {
    auditId: obj.auditId,
    auditee: obj.auditee,
    auditor: obj.auditor,
    queCount: obj.queCount,
    startDate: obj.startDate,
    topicId: obj.topicId,
    topicName: obj.topicName
  };
}

export interface IAuditTopicSearchCriteria {
  searchType?: AuditTopicSearchTypes;
  templateIds?: number[];
  auditGroupId?: number;
  ownerGroupIds?: number[];
  auditTopicIds?: number[];
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditTopicApi();
