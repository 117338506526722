import { CollectionView } from "@mescius/wijmo";
import * as wjcGrid from "@mescius/wijmo.grid";
import { FlexGrid as wjFlexGrid } from "@mescius/wijmo.grid";
import { FlexGridXlsxConverter } from "@mescius/wijmo.grid.xlsx";
import { authGetJson } from "auth/authFetches";
import React, { useCallback, useEffect } from "react";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { finishGenerateGridExportFile } from "store/grid/GridSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IWijmoGridExporterProps {
  /** The id of the grid to handle exports for. */
  gridId: string,
}

/**
 * Handles the redux action for exporting grids, checks if the grid is equal to the one passed in as
 * prop, and then exports that grid to XLSX.
 */
const WijmoGridExporter = React.forwardRef<wjFlexGrid, IWijmoGridExporterProps>((props, forwardedRef) => {
  const gridId = props.gridId;
  const gridIdToExport = useAppSelector(store => store.grid.generateExportFileOp?.data);
  const dispatch = useAppDispatch();

  const exportGridToExcel = useCallback(async () => {
    const grid = (forwardedRef as any)?.current;
    if (grid) {
      // Get the current, unpaged odata url from the grid's data source.
      const currOdataUrl = grid.collectionView.getOdataUrl(false);

      // Query the api using the odata url and assign the data to the grid.
      const values = (await (await authGetJson(currOdataUrl)).json()).value;

      // Quick hack to convert all true/false to yes/no for Excel export.
      if (Array.isArray(values)) {
        values.forEach((item: any) => {
          Object.entries(item).forEach(entry => {
            if (entry[1] === true) {
              item[entry[0]] = "Yes";
            } else if (entry[1] === false) {
              item[entry[0]] = "No";
            }
          });
        });
      }

      // Make a cloned grid.
      const clonedGrid = getClonedGrid(grid, values, false);

      // Export to excel.
      return FlexGridXlsxConverter.saveAsync(clonedGrid,
        {
          includeColumnHeaders: true,
          includeStyles: false,
        },
        `${gridId}_Grid_Exported.xlsx`);
    }
  }, [gridId, forwardedRef]);

  useEffect(() => {
    const asyncExport = async () => {
      if (gridIdToExport === gridId) {
        try {
          await exportGridToExcel();
        } catch (err) {
          dispatch(showErrorToast(getResponseErrorMessage(err)));
        } finally {
          dispatch(finishGenerateGridExportFile());
        }
      }
    };

    asyncExport();
  }, [gridIdToExport, gridId, exportGridToExcel, dispatch]);

  // Don't render anything to the screen.
  return null;
});

export default WijmoGridExporter;

function getClonedGrid(grid: any, sourceCollection: any[], cloneFilter: boolean) {
  const collViewSettings = {
    sortDescriptions: grid.collectionView.sortDescriptions,
    groupDescriptions: grid.collectionView.groupDescriptions,
    filter: cloneFilter ? grid.collectionView.filter : undefined,
  };

  if (!cloneFilter) {
    delete collViewSettings.filter;
  }

  var sourceData = new CollectionView(sourceCollection, collViewSettings);

  var clone = new wjcGrid.FlexGrid(document.createElement('div'), {
    itemsSource: sourceData,
    columnLayout: grid.columnLayout
  });

  grid.columns.forEach((col: any) => {
    if (col.dataMap) {
      clone.columns[col.index].dataMap = col.dataMap;
    }
  });

  clone.formatItem.addHandler((s, e) => {
    if (s.cells !== e.panel) {
      return;
    }

    let template = grid.cells.columns[e.col]['$__cellTemplCell'];
    if (template) {
      // compile templates
      let context = {
        row: e.row,
        col: e.col,
        item: e.panel.rows[e.row].dataItem,
        cell: {
          row: e.row,
          col: e.col,
          item: e.panel.rows[e.row].dataItem,
        }
      };
      let insTemp = template._instantiateTemplate(e.cell, context);
      e.cell.innerHTML = "";
      e.cell.appendChild(insTemp.rootElement);
      insTemp.viewRef.detectChanges();
    }
    // if itemFormatter, apply its binding
    if (grid.itemFormatter) {
      grid.itemFormatter(e.panel, e.row, e.col, e.cell);
    }
  });

  clone.invalidate();

  return clone;
}

