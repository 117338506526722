import React from "react";
import { useDispatch } from "react-redux";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import Picker from "shared/components/controls/picker/Picker";
import TemplatePicker from "shared/components/controls/pickers/TemplatePicker";
import Card from "shared/components/layout/card/Card";
import { applyTemplateSelections, closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, loadSuggestedPickerItems, openPicker, setSelectedPickerItems } from "store/manage-audit/ManageAuditSlice";
import { useAppSelector } from "store/store";
import { IAuditGroup, IAuditTopic } from 'types/auditMasterDataTypes';
import { EditRestriction } from "types/auditPageAuthTypes";
import "../ManageAuditPage.scoped.scss";
import "./TopicFiltersCard.scoped.scss";

interface ITopicFiltersCardProps {
  allowEditOnlyLeadAuditor: boolean,
  showStatus: boolean,
}

const TopicFiltersCard: React.FC<ITopicFiltersCardProps> = ({
  allowEditOnlyLeadAuditor,
  showStatus,
}) => {
  const auditGroups = useAppSelector(store => store.manageAudit.pickerData.auditGroups);
  const ownerGroups = useAppSelector(store => store.manageAudit.pickerData.ownerGroups);
  let auditTopics = useAppSelector(store => store.manageAudit.pickerData.auditTopics);
  const audit = useAppSelector(store => store.manageAudit.audit);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = !!(editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn);

  const isDisabled = (auditGroups.selectedItems.length
    + ownerGroups.selectedItems.length) === 0
    || !hasEditPermission
    || allowEditOnlyLeadAuditor;

  const dispatch = useDispatch();

  return (
    <Card
      title="Topic"
      headerElement={showStatus && <>
        <span style={{
          height: "2.4em",
        }}></span></>}
    >
      <div
        className="card-row"
      >
        <LabeledControl
          label="Audit Group"
          className="type"
          isRequired={true}
        >
          <PickerDropdown<IAuditGroup>
            pickerState={{
              ...auditGroups,
              selectedItems: audit.auditGroup
                ? [{
                  text: audit.auditGroup.name,
                  disabled: false,
                  key: audit.auditGroup.id,
                }] : [],
            }}
            setSelectedItemsAction={setSelectedPickerItems}
            loadAction={loadPickerItems}
            isDisabled={!hasEditPermission || allowEditOnlyLeadAuditor}
          />
        </LabeledControl>

        <LabeledControl
          label="Topic / Sub-Topic"
          className="type"
          isRequired={true}
        >
          <Picker<IAuditTopic>
            maxSelectedItemsVisible={0}
            pickerState={auditTopics}
            title="Audit Topic / Sub-Topic"
            renderListItem={(item) => `${item.name}`}
            openAction={openPicker}
            loadAction={loadPickerItems}
            closeAction={closePicker}
            setSelectedItemsAction={setSelectedPickerItems}
            searchOptions={{
              filterItem: (item, searchTerm) => {
                const ix = item.item?.name?.toLowerCase().indexOf(searchTerm);
                if (ix !== undefined
                  && ix > -1) {
                  return true;
                }

                return false;
              },
            }}
            preserveItems={false}
            displayMode="tree"
            allowMultiSelect={true}
            expandItemsAction={expandPickerItem}
            collapseItemsAction={collapsePickerItem}
            isDisabled={isDisabled}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
            showSuggestedItems
            loadSuggestionsAction={loadSuggestedPickerItems}
          />
        </LabeledControl>

        <LabeledControl
          label="Template"
          className="type"
        >
          <TemplatePicker
            allowMultiselect
            auditGroupIdFilter={audit.auditGroup?.id}
            onApplySplit={data => dispatch(applyTemplateSelections({
              templates: data.templates,
              auditTopics: data.auditTopics,
            }))}
            // The standard onApply is unused here. Use onApplySplit instead.
            onApply={_ => { }}
            selectedItems={audit.templates}
            isDisabled={isDisabled}
          />
        </LabeledControl>
      </div>
    </Card>
  );
};

export default TopicFiltersCard;
