export function linkFormatter(link: string): string {
  if (isValidUrl(link) && !link.includes("://")) {
    return `https://${link}`;
  }
  return link;
}

export function isValidUrl(urlString: string) {
  const validateURL = /^(https?:\/\/)?(www\.)?([\w-]+\.){1,}[a-zA-Z]{2,}(?::\d{1,5})?(\/[^/\s]*)*$/g;
  return !!urlString.match(validateURL);
}
