import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PlusButton from "shared/components/controls/buttons/circle-img-button/plus-btn/PlusButton";
import MultiButtons from "shared/components/controls/buttons/multi-buttons/MultiButtons";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditGroupPicker from "shared/components/controls/pickers/AuditGroupPicker";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import ClmFunctionPicker from "shared/components/controls/pickers/ClmFunctionPicker";
import ClmWhomToCheckPicker from "shared/components/controls/pickers/ClmWhomToCheckPicker";
import HSESubElementPicker from "shared/components/controls/pickers/HSESubElementPicker";
import QMSElementPicker from "shared/components/controls/pickers/QMSElementPicker";
import QuestionCategoryPicker from "shared/components/controls/pickers/QuestionCategoryPicker";
import QuestionTypePicker from "shared/components/controls/pickers/QuestionTypePicker";
import Card from "shared/components/layout/card/Card";
import { IRestrictedMetaData } from "shared/types/userProfileTypes";
import formatDate from "shared/utilities/dateFormatters";
import { IQuestionMetaDataAction, replaceQuestionMetaDataOfType, setQuestionActiveModal, setQuestionMetaData, setQuestionProperties } from "store/question-details/QuestionDetailsSlice";
import { useAppSelector } from "store/store";
import { IAuditTopic, IQMSElement, IVerificationMethod } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { ICLMWhomToCheck, MetaDataTypes } from "types/masterDataTypes";
import { IQuestion } from "types/questionTypes";
import QuestionIdDisplay from "../question-id-display/QuestionIdDisplay";
import { getQuestionMetaItems } from "../sharedAssociationHelpers";
import SplitMetaPicker from "../split-meta-picker/SplitMetaPicker";
import "./QuestionDetailsCard.scoped.scss";

const defaultMaxTextLength = 1000;
const defaultLongMaxTextLength = 5000;

// const exceptThisSymbols = ["e", "E", "+", "-", "."];

interface IQuestionDetailsCardProps {
  isDisabled: boolean,
  question: Partial<IQuestion>,
  /** If provided, pickers will be restricted to these items. */
  derivedMetaRestrictions: IRestrictedMetaData[] | undefined,
  editRestriction: EditRestriction,
}

/** The card named "Question Details". */
const QuestionDetailsCard: React.FC<IQuestionDetailsCardProps> = ({
  isDisabled,
  question,
  derivedMetaRestrictions,
  editRestriction,
}) => {
  const dispatch = useDispatch();
  const {
    ownerGroups,
    verificationMethods,
  } = useAppSelector(store => store.questionDetails.masterData);

  const getMaxLengthTextHint = (maxTextLength: number, currentText: string | undefined): string => {
    return (maxTextLength - (currentText ? currentText.length : 0) + `/${maxTextLength} characters remaining`);
  };

  const userHasEditPermission = editRestriction !== EditRestriction.EditNone;
  const ownerGroup = ownerGroups.find(x => x.id === question.auditTopic?.ownerGroupId);
  const ownerGroupLabel = ownerGroup?.name ?? "--";
  const scoringSystem = ownerGroup?.scoringSystem;
  const questionAllowNA = question.allowNA;

  const selectedVerMethods = question
    .metaData
    ?.filter(x => x.masterDataType === MetaDataTypes.VerMethod)
    .map(x => x.masterDataItem as IVerificationMethod) ?? [];

  const selectedAuditTopics: IAuditTopic[] = getQuestionMetaItems<IAuditTopic>(question.metaData,
    MetaDataTypes.AuditTopic,
    false);

  if (question.auditTopic) {
    selectedAuditTopics.push(question.auditTopic);
  }

  const selectedQmsElements: IQMSElement[] = getQuestionMetaItems<IQMSElement>(question.metaData,
    MetaDataTypes.QMSElement,
    false);

  const selectedClmWhomToCheck: ICLMWhomToCheck[] = getQuestionMetaItems<ICLMWhomToCheck>(question.metaData,
    MetaDataTypes.ClmWhomToCheck,
    false);

  const createPlusButton = (openPicker: () => void) => {
    return (<PlusButton onClick={openPicker}></PlusButton>);
  };

  useEffect(() => {
    if (scoringSystem === AuditScoringSystems.HSE) {
      // Auto-updates for the HSE-scored questions.
      dispatch(replaceQuestionMetaDataOfType({
        items: [],
        isAdditionalAssociation: false,
        metaDataType: MetaDataTypes.QMSElement,
      }));

      dispatch(replaceQuestionMetaDataOfType({
        items: [],
        isAdditionalAssociation: false,
        metaDataType: MetaDataTypes.ClmWhomToCheck
      }));

      dispatch(setQuestionProperties({
        clmFunction: undefined,
        clause: "",
      }));
    } else if (scoringSystem === AuditScoringSystems.QMS) {
      // Auto-updates for the QMS-scored questions.
      dispatch(setQuestionProperties({
        hseMgmtSysSubElement: undefined,
      }));

      dispatch(replaceQuestionMetaDataOfType({
        items: [],
        isAdditionalAssociation: false,
        metaDataType: MetaDataTypes.ClmWhomToCheck
      }));

      dispatch(setQuestionProperties({
        clmFunction: undefined,
        clause: "",
      }));
    } else if (scoringSystem === AuditScoringSystems.CLM) {
      // Auto-updates for the CLM-scored questions.
      dispatch(setQuestionProperties({
        hseMgmtSysSubElement: undefined,
      }));
    }
  }, [scoringSystem, questionAllowNA, dispatch]);

  return (
    <Card
      title="Question Details"
      cardStateId="manage-question-question-details"
      headerElement={<QuestionIdDisplay question={question} />}
    >
      <div className="details-grid">
        <div className="question">
          <LabeledControl
            label="Question"
            hint={getMaxLengthTextHint(defaultMaxTextLength, question.questionText)}
            isRequired
          >
            <textarea
              disabled={isDisabled}
              value={question.questionText}
              onChange={e => dispatch(setQuestionProperties({ questionText: e.currentTarget.value }))}
              maxLength={defaultMaxTextLength}
            />
          </LabeledControl>
        </div>

        <div className="owneretc">
          <LabeledControl
            label="Owner Group"
          >
            {ownerGroupLabel}
          </LabeledControl>

          <div className="row-item-quid">
            <LabeledControl label="Question UID">
              <input
                className="input-item-quid"
                disabled={isDisabled}
                type="text"
                value={question.quid}
                maxLength={8}
                onChange={(e) =>
                  dispatch(
                    setQuestionProperties({ quid: e.currentTarget.value })
                  )
                }
              />
            </LabeledControl>
          </div>

          <LabeledControl
            label="Question Type"
            isRequired
          >
            <div>
              <QuestionTypePicker
                onApply={items => {
                  dispatch(setQuestionProperties({
                    questionType: items?.[0],
                  }));
                }}
                selectedItems={question.questionType ? [question.questionType] : []}
                includeDeleted={false}
                isDisabled={isDisabled}
                allowMultiselect={false}
                renderMode="dropdown"
              />
            </div>
          </LabeledControl>

          <LabeledControl
            label="Category"
            isRequired
          >
            <div>
              <QuestionCategoryPicker
                onApply={items => {
                  dispatch(setQuestionProperties({
                    category: items?.[0],
                  }));
                }}
                selectedItems={question.category ? [question.category] : []}
                includeDeleted={false}
                isDisabled={isDisabled}
                allowMultiselect={false}
                renderMode="dropdown"
              />
            </div>
          </LabeledControl>

          {/*<LabeledControl
            label="Question Order"
          >
		  <div>
              <input
                type="number"
                disabled={isDisabled}
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={e => dispatch(setQuestionProperties({
                  sortOrder: e.currentTarget.value
                    ? Number(e.currentTarget.value)
                    : undefined
                }))}
                value={question?.sortOrder}
                min={1}
                max={99999}
              />
            </div>
          </LabeledControl>
		  */}

          <LabeledControl
            label=""
          >
            <label
              className="global-label"
            >
              <input
                type="checkbox"
                disabled={isDisabled}
                onChange={e => dispatch(setQuestionProperties({ isGlobal: e.currentTarget.checked }))}
                checked={!!question?.isGlobal}
              />
              Global
            </label>
          </LabeledControl>

          <LabeledControl
            label=""
          >
            <label
              className="allow-na-label"
            >
              <input
                type="checkbox"
                disabled={isDisabled}
                onChange={e => dispatch(setQuestionProperties({ allowNA: e.currentTarget.checked }))}
                checked={!!question?.allowNA}
              />
              Allow N/A
            </label>
          </LabeledControl>

          {!!question.id && (
            <LabeledControl
              label="Active"
              isRequired
            >
              <div className="active-switch">
                <MultiButtons
                  selectedKey={question.isDeleted ? "NO" : "YES"}
                  buttons={[{
                    disabled: !userHasEditPermission || !question.isLatestVersion,
                    key: "YES",
                    onClick: () => {
                      if (!question.isDeleted) {
                        // Question is already active.
                        return;
                      }

                      dispatch(setQuestionActiveModal({
                        isOpen: true,
                        reason: "",
                        revertOnTimestamp: undefined,
                      }));
                    },
                    text: "YES",
                  }, {
                    disabled: !userHasEditPermission || !question.isLatestVersion,
                    key: "NO",
                    onClick: () => {
                      if (question.isDeleted) {
                        // Question is already inactive.
                        return;
                      }

                      dispatch(setQuestionActiveModal({
                        isOpen: true,
                        reason: "",
                        revertOnTimestamp: undefined,
                      }));
                    },
                    text: "NO",
                  }]}
                />

                {!!question.queuedActionType
                  && !!question.queuedActionTargetTimestamp && (
                    <QueueMessage
                      type={question.queuedActionType}
                      timestamp={question.queuedActionTargetTimestamp}
                    />
                  )}
              </div>
            </LabeledControl>
          )}
        </div>

        <div className="guidance">
          <LabeledControl
            label="Guidance"
            isRequired
            hint={getMaxLengthTextHint(defaultLongMaxTextLength, question.guidance)}
          >
            <textarea
              maxLength={defaultLongMaxTextLength}
              disabled={isDisabled}
              value={question.guidance}
              onChange={e => dispatch(setQuestionProperties({ guidance: e.currentTarget.value }))}
            />
          </LabeledControl>
        </div>
        <div className="guidancetc">
          <div className="details-grid-2-column">
            <div className="area1">
              <LabeledControl
                label="Recommended RWP"
                hint={getMaxLengthTextHint(defaultMaxTextLength, question.recommendedRWP)}
              >
                <textarea
                  disabled={isDisabled}
                  value={question.recommendedRWP}
                  onChange={e => dispatch(setQuestionProperties({ recommendedRWP: e.currentTarget.value }))}
                  maxLength={defaultMaxTextLength}
                />
              </LabeledControl>
            </div>
            <div className="area2">
              <LabeledControl
                label="Verification Method"
                isRequired
              >
                <div className="ver-methods">
                  {verificationMethods
                    // Concat the list of available verification methods with any verification methods 
                    // that are currently assigned to the question that do not appear in the list of
                    // available methods. (This is to account for questions that might be assigned
                    // to verification methods that are deleted.)
                    .concat(selectedVerMethods
                      .filter(sv => !verificationMethods.some(v => v.id === sv.id)))
                    .map(v => {
                      const isSelected = selectedVerMethods.some(sv => sv.id === v.id);

                      return (
                        <label
                          key={v.id}
                          className={isSelected && v.isDeleted ? "deleted-ver-method" : undefined}
                          title={isSelected && v.isDeleted ? "This verification has been deleted. You must remove it before saving." : ""}
                        >
                          <input
                            disabled={isDisabled
                              // Disable it if it's not currently selected & the verification method
                              // is marked as deleted.
                              || (!selectedVerMethods && v.isDeleted)
                            }
                            type="checkbox"
                            checked={isSelected}
                            onChange={e => dispatch(setQuestionMetaData([{
                              action: e.currentTarget.checked ? "add" : "remove",
                              metaData: {
                                masterDataId: v.id,
                                masterDataType: MetaDataTypes.VerMethod,
                                masterDataItem: v,
                                isAdditionalAssociation: false,
                              },
                            }]))}
                          />
                          {v.name}
                        </label>
                      );
                    })}
                </div>
              </LabeledControl>
            </div>
          </div>
        </div>
        {scoringSystem === AuditScoringSystems.CLM && <>
          <div className="clmClause">
            <LabeledControl
              label="Clause"
              hint={getMaxLengthTextHint(50, question.clause)}
            >
              <textarea
                disabled={isDisabled}
                value={question.clause}
                onChange={e => dispatch(setQuestionProperties({ clause: e.currentTarget.value }))}
                maxLength={50}
              />
            </LabeledControl>
          </div>
          <div className="clmFunction">
            <LabeledControl
              label="CLM Function"
              isRequired={scoringSystem === AuditScoringSystems.CLM}
            >
              <div>
                <ClmFunctionPicker
                  onApply={items => {
                    dispatch(setQuestionProperties({
                      clmFunction: items?.[0],
                    }));
                  }}
                  selectedItems={question.clmFunction ? [question.clmFunction] : []}
                  allowMultiselect={false}
                  includeDeleted={false}
                  isDisabled={isDisabled}
                />
              </div>
            </LabeledControl>
          </div>
          <div className="clmWhomToCheck">
            <LabeledControl
              label="With Whom to Check"
              isRequired={scoringSystem === AuditScoringSystems.CLM}
            >
              <div>
                <ClmWhomToCheckPicker
                  onApply={items => dispatch(replaceQuestionMetaDataOfType({
                    items: items.map(x => ({
                      masterDataId: x.id,
                      masterDataType: MetaDataTypes.ClmWhomToCheck,
                      masterDataItem: x,
                      isAdditionalAssociation: false,
                    })),
                    isAdditionalAssociation: false,
                    metaDataType: MetaDataTypes.ClmWhomToCheck
                  }))}

                  selectedItems={scoringSystem === AuditScoringSystems.CLM
                    ? selectedClmWhomToCheck
                    : []}
                  allowMultiselect={true}
                  includeDeleted={false}
                  isDisabled={isDisabled}
                />
              </div>
            </LabeledControl>
          </div>
        </>}

        <div className="topics">
          <SplitMetaPicker<IAuditTopic>
            isDisabled={isDisabled}
            columns={[{
              key: "Topic",
              label: "Topics",
              isRequired: true,
            }, {
              key: "Subtopic",
              label: "Sub-Topics",
            }]}
            customLeftElement={
              <LabeledControl
                label="Audit Group"
                isRequired={true}
              >
                <div>
                  <AuditGroupPicker
                    onApply={items => {
                      dispatch(setQuestionProperties({
                        auditGroup: items?.[0],
                      }));

                      if (items?.length === 0 || (items[0].id !== question.auditGroup?.id)) {
                        // If the Audit Group has changed, remove all selected topics and subtopics.
                        const subTopicMeta = question
                          .metaData
                          ?.filter(x => x.masterDataType === MetaDataTypes.AuditTopic)
                          .map((x): IQuestionMetaDataAction => ({
                            action: "remove",
                            metaData: x,
                          }));

                        if (subTopicMeta?.length) {
                          dispatch(setQuestionMetaData(subTopicMeta));
                        }

                        dispatch(setQuestionProperties({
                          auditTopic: undefined,
                        }));
                      }
                    }}
                    selectedItems={question.auditGroup ? [question.auditGroup] : []}
                    isDisabled={isDisabled}
                    allowMultiselect={false}
                    renderMode="dropdown"
                    userProfileMetaRestrictions={derivedMetaRestrictions}
                  />
                </div>
              </LabeledControl>
            }
            selectedItems={selectedAuditTopics}
            mapItemToColumnKey={item =>
              item.level === 1
                ? "Topic"
                : "Subtopic"
            }
            itemSorter={(item1, item2) => (item1.name || "") < (item2.name || "") ? -1 : 1}
            onItemDeselected={item => {
              if (item.level === 1) {
                dispatch(setQuestionProperties({
                  auditTopic: undefined,
                }));
              } else if (item) {
                dispatch(setQuestionMetaData([{
                  action: "remove",
                  metaData: {
                    masterDataId: item.id,
                    masterDataType: MetaDataTypes.AuditTopic,
                    masterDataItem: item,
                    isAdditionalAssociation: false,
                  },
                }]));
              }
            }}
            renderListItem={item => item.name}
            keyMapper={item => item.id}
            customMetaRestrictionChecker={(item, meta) => {
              if (meta.some(x => x.type === MetaDataTypes.OwnerGroup
                && x.id === item.ownerGroupId)) {
                return true;
              }

              const type = item.level === 1 || item.level === 2 ? MetaDataTypes.AuditTopic : "";

              if (!type) {
                return false;
              }

              return meta.some(x => x.type === type
                && x.id === item.id);
            }}
            userProfileMetaRestrictions={
              derivedMetaRestrictions
                ?.filter(x => x.type === MetaDataTypes.OwnerGroup
                  || x.type === MetaDataTypes.AuditTopic) || []
            }
            picker={
              <AuditTopicPicker
                onApply={items => {
                  const selTopic = items.find(x => x.level === 1);
                  const selSubTopics = items.filter(x => x.level === 2);
                  // Find the sub topics already in the question that are not selected anymore.
                  const removeSubTopics = (question.metaData
                    ?.filter(x => (x.masterDataType === MetaDataTypes.AuditTopic)
                      && (x.masterDataItem as IAuditTopic).level === 2
                      && !selSubTopics.some(sel => sel.id === (x.masterDataItem as IAuditTopic).id)) || [])
                    .map((item): IQuestionMetaDataAction => ({
                      action: "remove",
                      metaData: {
                        masterDataId: item.masterDataId,
                        masterDataType: MetaDataTypes.AuditTopic,
                        masterDataItem: item.masterDataItem,
                        isAdditionalAssociation: false,
                      },
                    }));

                  const removeQmsElements = (question.metaData
                    ?.filter(x => (x.masterDataType === MetaDataTypes.QMSElement)) || [])
                    .map((item): IQuestionMetaDataAction => ({
                      action: "remove",
                      metaData: {
                        masterDataId: item.masterDataId,
                        masterDataType: MetaDataTypes.QMSElement,
                        masterDataItem: item.masterDataItem,
                        isAdditionalAssociation: false,
                      },
                    }));

                  if (selTopic?.scoringSystem !== AuditScoringSystems.QMS) {
                    dispatch(setQuestionMetaData(removeQmsElements));
                  }

                  dispatch(setQuestionProperties({
                    auditTopic: selTopic,
                  }));

                  // Remove any subtopics that are no longer selected.
                  if (removeSubTopics.length) {
                    dispatch(setQuestionMetaData(removeSubTopics));
                  }

                  dispatch(setQuestionMetaData(selSubTopics.map(x => ({
                    action: "add",
                    metaData: {
                      masterDataId: x.id,
                      masterDataType: MetaDataTypes.AuditTopic,
                      masterDataItem: x,
                      isAdditionalAssociation: false,
                    },
                  }))));
                }}
                selectedItems={selectedAuditTopics}
                allowMultiselect={true}
                onRenderPicker={openPicker => createPlusButton(openPicker)}
                showSuggestions={true}
                userProfileMetaRestrictions={derivedMetaRestrictions}
              />
            }
          />
        </div>

        {(question.auditTopic?.scoringSystem === AuditScoringSystems.CLM
          || question.auditTopic?.scoringSystem === AuditScoringSystems.QMS)
          && <div className={`subelement${question.auditTopic?.scoringSystem === AuditScoringSystems.CLM
            ? "-2ry"
            : ""}`}>
            <LabeledControl
              label="Quality Management System Sub-Element"
              isRequired={question.auditTopic?.scoringSystem === AuditScoringSystems.QMS}
            >
              <QMSElementPicker
                onApply={items => dispatch(replaceQuestionMetaDataOfType({
                  items: items.map(x => ({
                    masterDataId: x.id,
                    masterDataType: MetaDataTypes.QMSElement,
                    masterDataItem: x,
                    isAdditionalAssociation: false,
                  })),
                  isAdditionalAssociation: false,
                  metaDataType: MetaDataTypes.QMSElement
                }))}
                selectedItems={selectedQmsElements}
                isDisabled={isDisabled}
                renderMode="dropdown"
                allowMultiselect={true}
              />
            </LabeledControl>
          </div>
        }

        {(question.auditTopic?.scoringSystem === AuditScoringSystems.HSE)
          && <div className="subelement">
            <LabeledControl
              label="HSE MS Sub-Element"
              isRequired={question.auditTopic?.scoringSystem === AuditScoringSystems.HSE}
            >
              <HSESubElementPicker
                onApply={items => dispatch(setQuestionProperties({
                  hseMgmtSysSubElement: items?.[0],
                }))}
                selectedItems={question.hseMgmtSysSubElement
                  ? [question.hseMgmtSysSubElement]
                  : []
                }
                isDisabled={isDisabled}
                renderMode="dropdown"
              />
            </LabeledControl>
          </div>
        }
      </div>
    </Card>
  );
};

export default QuestionDetailsCard;

interface IQueueMessageProps {
  type: string,
  timestamp: number,
}

const QueueMessage: React.FC<IQueueMessageProps> = ({ type, timestamp }) => {
  let verb = "Unknown Action";

  if (type === "Activate") {
    verb = "Reactivates on";
  } else if (type === "Deactivate") {
    verb = "Deactivates on";
  }

  return (
    <b className="queue-message">
      {verb}: {formatDate(new Date(timestamp), false, true, "short")}
    </b>
  );
};
