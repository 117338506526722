import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Banner, { BannerType } from 'shared/components/common/banner/Banner';
import Prompt from 'shared/components/common/prompt/Prompt';
import ModalSpinner from 'shared/components/common/spinner/ModalSpinner';
import Spinner from 'shared/components/common/spinner/Spinner';
import VideoLinkButton from 'shared/components/common/video-link-button/VideoLinkButton';
import CircleImgButton from 'shared/components/controls/buttons/circle-img-button/CircleImageButton';
import LabeledControl from 'shared/components/controls/labeled-control/LabeledControl';
import RichTextEditor from 'shared/components/controls/rich-text-editor/RichTextEditor';
import Card from 'shared/components/layout/card/Card';
import FlexCol from 'shared/components/layout/flex/FlexCol';
import FlexRow from 'shared/components/layout/flex/FlexRow';
import { IPageHeaderControl } from 'shared/components/layout/page/PageBar';
import Table, { TableThemes } from 'shared/components/layout/table/Table';
import CommentIcon from 'shared/media/dls/comments.svg';
import { IColumnItem } from 'shared/types/columnTypes';
import { IAzureADUser } from 'shared/types/userProfileTypes';
import formatDate from 'shared/utilities/dateFormatters';
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { generateAuditReport, loadReportData, openCommentsModal, resetState, saveAuditComments, setAuditComment, toggleAuditCommentSaveButton, toggleReportModal } from 'store/audit-report/AuditReportSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { EditRestriction } from 'types/auditPageAuthTypes';
import { IAuditReport, IReportTopic } from 'types/auditingTypes';
import AuditPage from '../common/audit-page/AuditPage';
import AuditDetailsCard from '../common/details-card/AuditDetailsCard';
import ActionsCard from './actions-card/ActionsCard';
import CommentsModal from './comments-modal/CommentsModal';
import "./AuditReportPage.scoped.scss";

const maxInfoToDisplayByRole: number = 2;
const maxInfoToDisplayByUser: number = 2;

const AuditReport: React.FC = () => {
  const report = useAppSelector(store => store.auditReport.report);
  const loadReportDataOp = useAppSelector(store => store.auditReport.loadReportDataOp);
  const saveOp = useAppSelector(store => store.auditReport.saveOp);
  const generateReportOp = useAppSelector(store => store.auditReport.generateReportOp);
  const isCommentsModalOpen = useAppSelector(store => store.auditReport.isCommentsModalOpen);
  const isSaveAuditCommentButtonEnabled = useAppSelector(store => store.auditReport.isSaveAuditCommentButtonEnabled);
  const currentUser = useAppSelector(store => store.auth.currentUser);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const {
    auditId,
  } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadReportData(Number(auditId)));
    dispatch(toggleAuditCommentSaveButton(false));
  }, [auditId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  let pageControls: IPageHeaderControl[] = [];

  // Apply restrictions to audit comments
  const isEditable = (auditPageAuth.editRestriction === EditRestriction.EditAll);

  if (report && isEditable) {
    pageControls.push({
      key: "SAVECHANGES",
      element: (
        <button
          autoFocus={true}
          disabled={!isSaveAuditCommentButtonEnabled}
          className="primary"
          onClick={() => {
            dispatch(saveAuditComments());
            dispatch(toggleAuditCommentSaveButton(false));
          }}
          title={tooltipMessage(!isSaveAuditCommentButtonEnabled)}
        >
          Save
        </button>
      ),
    });
  }

  if (!auditId) {
    return null;
  }

  pageControls.push({
    key: "VIDEO",
    element: (
      <VideoLinkButton
        links={[{
          title: "To create opening & closing meeting reports",
          url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=8c469cee-bc5c-50f6-383a-a77badc0f707&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=ClassicStream&referrerScenario=ClassicPortalMigration.View&scenario=2",
          description: "This video provides information on how a Lead Auditor can create opening & closing meeting reports",
        }]}
      />
    ),
  });

  return (
    <AuditPage
      title="Audit Report"
      pageControls={pageControls}
      auditId={auditId}
      overviewSettings={{
        showComplianceScore: true,
        showQuestionCompletionPercent: true,
      }}
    >
      {loadReportDataOp?.isWorking
        ? (
          <Spinner />
        ) : !!loadReportDataOp?.errorMessage
          ? (
            <Banner
              type={BannerType.error}
            >
              {loadReportDataOp.errorMessage}
            </Banner>
          ) : !!report ? (
            <>
              <FlexRow
                className="summary-row"
              >
                <AuditDetailsCard
                  cardStateId="reportAuditDetails"
                />
                <ActionsCard
                  auditId={Number(auditId)}
                  topics={report.topics}
                  auditStatus={report.status}
                  onCloseReportModal={() => dispatch(toggleReportModal(false))}
                  onGenerateReport={options => dispatch(generateAuditReport({
                    auditId: Number(auditId),
                    reportData: options,
                  }))}
                  showCreateReportButton={isEditable}
                  showNotifyTeamButton={isEditable}
                />
              </FlexRow>

              <Card
                title="Topic Summary"
              >
                <Table
                  data={report.reportSummaryDtos}
                  keyProp={"topicId"}
                  columns={columns}
                  columnRender={getColumnRenderer(report, currentUser, dispatch)}
                  theme={TableThemes.compact}
                />
              </Card>

              <Card
                title="Audit Summary"
              >
                <div className="comments_business_team">
                  <FlexCol>
                    <LabeledControl
                      label="Closing Statements"
                      labelExtraClass='label-content'
                    >
                      <RichTextEditor
                        htmlValue={report?.auditComment?.closingStatementHTML ?? ""}
                        isDisabled={!isEditable}
                        onChange={(text: string, html: string) => {
                          if (report?.auditComment?.closingStatementHTML !== html) {
                            dispatch(toggleAuditCommentSaveButton(isEditable));
                            dispatch(setAuditComment({
                              closingStatementHTML: html,
                              closingStatement: text,
                            }));
                          }
                        }}
                      />
                    </LabeledControl>

                    <LabeledControl
                      label="Opportunities for Improvement"
                      labelExtraClass='label-content'
                    >
                      <RichTextEditor
                        htmlValue={report.auditComment?.lowlightsHTML || ''}
                        isDisabled={!isEditable}
                        onChange={(text: string, html: string) => {
                          if (html !== report.auditComment?.lowlightsHTML) {
                            dispatch(toggleAuditCommentSaveButton(isEditable));
                            dispatch(setAuditComment({
                              lowlightsHTML: html,
                              lowlights: text,
                            }));
                          }
                        }}
                      />
                    </LabeledControl>

                    <LabeledControl
                      label="Highlights"
                      labelExtraClass='label-content'
                    >
                      <RichTextEditor
                        htmlValue={report.auditComment?.highLightsHTML || ''}
                        isDisabled={!isEditable}
                        onChange={(text: string, html: string) => {
                          if (html !== report.auditComment?.highLightsHTML) {
                            dispatch(toggleAuditCommentSaveButton(isEditable));
                            dispatch(setAuditComment({
                              highLightsHTML: html,
                              highLights: text,
                            }));
                          }
                        }
                        }
                      />
                    </LabeledControl>

                    <LabeledControl
                      label="Way Forward"
                      labelExtraClass='label-content'
                    >
                      <RichTextEditor
                        htmlValue={report.auditComment?.wayforwardHTML || ''}
                        isDisabled={!isEditable}
                        onChange={(text: string, html: string) => {
                          if (html !== report.auditComment?.wayforwardHTML) {
                            dispatch(toggleAuditCommentSaveButton(isEditable));
                            dispatch(setAuditComment({
                              wayforwardHTML: html,
                              wayforward: text,
                            }));
                          }
                        }}
                      />
                    </LabeledControl>

                    <LabeledControl
                      label="Administrative (non-report) Auditor notes"
                      labelExtraClass='label-content'
                    >
                      <RichTextEditor
                        htmlValue={report.auditComment?.notes || ''}
                        isDisabled={!isEditable}
                        onChange={(_: string, html: string) => {
                          if (html !== report.auditComment?.notes) {
                            dispatch(toggleAuditCommentSaveButton(isEditable));
                            dispatch(setAuditComment({
                              notes: html,
                            }));
                          }
                        }}
                      />
                    </LabeledControl>
                  </FlexCol>
                </div>
              </Card>
            </>
          ) : null
      }

      <Prompt
        isDirty={isSaveAuditCommentButtonEnabled}
        message="Any unsaved changes will be lost. Are you sure you want to leave?"
      />

      {(generateReportOp?.isWorking
        || saveOp?.isWorking) && (
          <ModalSpinner />
        )}

      {isCommentsModalOpen &&
        <CommentsModal
          isEditable={isEditable}
        />
      }
    </AuditPage>
  );
};

export default AuditReport;

const columns: IColumnItem<IReportTopic, keyof IReportTopic>[] = [
  {
    key: 'NAME',
    property: 'topicName',
    header: 'Audit Topic',
    width: 200,
  },
  {
    key: 'QUESTIONS',
    property: 'queCount',
    header: '# Questions',
    width: 85,
  },
  {
    key: 'AUDITOR',
    property: 'auditor',
    header: 'Auditor',
    width: 100,
  },
  {
    key: 'AUDITORROLE',
    property: 'auditor',
    header: 'Auditor Role',
    width: 100,
  },
  {
    key: 'AUDITEE',
    property: 'auditee',
    header: 'Auditee',
    width: 100,
  },
  {
    key: 'AUDITEEROLE',
    property: 'auditee',
    header: 'Auditee Role',
    width: 100,
  },
  {
    key: 'DATE',
    property: 'startDate',
    header: 'Dates',
    width: 100,
  },
  {
    key: 'COMMENTS',
    header: 'Comments',
    width: 50,
  },
];

function getColumnRenderer(report: IAuditReport | undefined,
  currentUser: IAzureADUser,
  dispatch: Dispatch<AnyAction>) {
  return function renderColumns(columnKey: string, boundObj: any, objProperty: string) {
    if (columnKey === 'COMMENTS') {

      const topicsComments = report?.auditTopicComment?.filter(item => item.auditTopicId === boundObj.topicId);
      const hasComments = (topicsComments?.length ?? 0) > 0;
      return (
        <td
          key={`cell-${columnKey}-${boundObj.topicId}`}
        >
          <CircleImgButton
            alt='Comments'
            type={hasComments
              ? "primary"
              : "secondary"}
            icon={CommentIcon}
            onClick={() => dispatch(openCommentsModal({ topicId: boundObj.topicId, Email: currentUser.email }))}
          />
        </td>
      );
    } else if (columnKey === 'DATE') {
      const data = String(boundObj[objProperty] || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : undefined}
          key={`cell-${columnKey}-${boundObj.topicId}`}
        >
          {
            data ?
              <span
                title={truncate
                  ? data
                  : undefined
                }
              >
                {
                  formatDate(new Date(data))
                }
              </span>
              : "-"
          }
        </td>
      );
    } else if (columnKey === 'AUDITOR' || columnKey === 'AUDITEE') {
      let data: IAzureADUser[] = boundObj[objProperty].slice(0, maxInfoToDisplayByUser + 1);

      return (
        <td
          key={`cell-${columnKey}-${boundObj.topicId}`}
        >
          {data.length > 0
            ? (
              <>
                {data
                  .map((x, ix) => (
                    <span
                      key={x.email}
                      className="person"
                    >
                      {x.name}{ix !== data.length - 1 ? "," : ""}
                    </span>
                  ))
                }
              </>
            ) : "-"
          }
        </td>
      );
    } else if (columnKey === 'AUDITEEROLE' || columnKey === 'AUDITORROLE') {
      let data: IAzureADUser[] = boundObj[objProperty].slice(0, maxInfoToDisplayByRole + 1);

      return (
        <td
          key={`cell-${columnKey}-${boundObj.topicId}`}
        >
          {data.length > 0
            ? (
              <>
                {data
                  .map((x, ix) => (
                    <span
                      key={x.email}
                      className="role"
                    >
                      {x.jobTitle || "No Role"}{ix !== data.length - 1 ? "," : ""}
                    </span>
                  ))
                }
              </>
            ) : "-"
          }
        </td>
      );
    } else {
      const data = String(boundObj[objProperty] || '');
      const truncate = data.length > 100;

      return (
        <td
          className={truncate ? 'truncate' : undefined}
          key={`cell-${columnKey}-${boundObj.topicId}`}
        >
          {
            data ?
              <span
                title={truncate
                  ? data
                  : undefined
                }
              >
                {data}
              </span>
              : "-"
          }
        </td>
      );
    }
  };
}
